export const KILOGRAM_FACTOR = 1000;
export const POUND_FACTOR = 2204.6226218;
export const TONNES_TO_TONS_FACTOR = 1.10231;
export const POUNDS_TO_TONS_FACTOR = (1/2000);
export const KILOGRAM_TO_TONS_FACTOR = (1/907.185);
export const UNIT_KILOGRAM_SYMBOL = 'kg'
export const UNIT_POUND_SYMBOL = 'lb'
export const UNIT_METRIC_TONNE_SYMBOL = 't'
export const KM_FACTOR = 1;
export const MILES_FACTOR = 0.621371;

export const convertToTonne = (value, fromUnit) => {
    if(!fromUnit) return null;

    const symbol = fromUnit.toLowerCase();
    if (symbol === UNIT_METRIC_TONNE_SYMBOL) return value;

    if (symbol === UNIT_KILOGRAM_SYMBOL) return value / KILOGRAM_FACTOR;
    else if (symbol === UNIT_POUND_SYMBOL) return value / POUND_FACTOR;
}

export const convertToKG = (value, fromUnit) => {
    const symbol = fromUnit.toLowerCase();
    if (symbol === UNIT_KILOGRAM_SYMBOL) return value;

    if (symbol === UNIT_METRIC_TONNE_SYMBOL) return value * KILOGRAM_FACTOR;
    else if (symbol === UNIT_POUND_SYMBOL) return (convertToTonne(value, fromUnit) * KILOGRAM_FACTOR);
}

export const convertToPounds = (value, fromUnit) => {
    const symbol = fromUnit.toLowerCase();
    if (symbol === UNIT_POUND_SYMBOL) return value;

    if (symbol === UNIT_METRIC_TONNE_SYMBOL) return value * POUND_FACTOR;
    else if (symbol === UNIT_KILOGRAM_SYMBOL) return (convertToTonne(value, fromUnit) * POUND_FACTOR);
}

export const convertToImperialTon = (value,fromUnit) => {
    if(!fromUnit) return null;

    const fromSymbol = fromUnit.toLowerCase();
    if (fromSymbol === UNIT_METRIC_TONNE_SYMBOL) return value*TONNES_TO_TONS_FACTOR; //not yet supported
    if (fromSymbol === UNIT_POUND_SYMBOL) return value*POUNDS_TO_TONS_FACTOR; //not yet supported
    if (fromSymbol === UNIT_KILOGRAM_SYMBOL) return value*KILOGRAM_TO_TONS_FACTOR; //not yet supported

    throw "not supported"
}

export const convertToMilesFromKm = (kmValue) => {
    return {
        value: kmValue * MILES_FACTOR,
        unit: 'mile'
    };
}

export const formatDistanceUnit = (unitValue) => {
    if(!unitValue) return '';

    if (unitValue.toLowerCase() === 'miles')
        return 'mi.';
    if (unitValue.toLowerCase() === 'km')
        return 'km';
}

export const formatWeightUnit = (unitValue) => {
    if(!unitValue) return '';

    if (unitValue.toLowerCase() === 'lb' || unitValue.toLowerCase() === 'lbs' || unitValue.toLowerCase()  === 'pounds')
        return 'lb';
    if (unitValue.toLowerCase() === 'kg' || unitValue.toLowerCase() === 'kilograms')
        return 'kg';
}

export const getYearlyCarbonRepresentationTonne = (co2Quantity) => {
    if(co2Quantity?.value){
        const yearlyTonnes = convertToTonne(co2Quantity.value * 12, co2Quantity.unit);
        return yearlyTonnes.toFixed(yearlyTonnes<1?1:0);
    }
    else{
        return '--.---'
    }
};

export const getYearlyCarbonRepresentationKgOrPounds = (co2Quantity) => {
    if (co2Quantity?.unit?.toLowerCase() === 'kg'){
        return convertToKG(co2Quantity.value * 12, co2Quantity.unit).toFixed(0);
    } else if (co2Quantity?.unit?.toLowerCase() === 'lb'){
        return convertToPounds(co2Quantity.value * 12, co2Quantity.unit).toFixed(0);       
    } else {
        return '--.---';
    } 
    
};

export const getYearlyTreesCarbonRepresentation = (co2Quantity) => {
    if(co2Quantity?.value){
        const yearlyTrees = co2Quantity.value * 12 / 20;
        if(yearlyTrees<100){
            return Math.floor(yearlyTrees);
        }
        else{
            return (Math.floor(yearlyTrees / 100) * 100);
        }
    }
    else{
        return '---';
    }

};

export const getUnitTypeFromCountry = (countryCode) => {
    return (countryCode.toLowerCase() === 'us') ?  'lb':'kg'
}