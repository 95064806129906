export const isValidCountry = (countryCode) => {
  return data.countries.find(c => c.code.toLowerCase() === countryCode.toLowerCase())
};

// Some data updates from Kat's spreadsheet. Also see old commit messages for other data source(s)
// https://docs.google.com/spreadsheets/d/1Yrfu0pYojayoG_hgHEavJVumf_9_blQA8v6XQMYPhP0/edit#gid=0
export const data = {
  countries: [
    {
      "name": "New Zealand",
      "code": "NZ",
      "currency": "NZD",
      "footprint": 7.8
    },
    {
      "name": "United States",
      "code": "US",
      "currency": "USD",
      "footprint": 14.24,
      "unitSystem" : "IMPERIAL"
    },
    {
      "name": "Australia",
      "code": "AU",
      "currency": "AUD",
      "footprint": 22.4
    },
    {
      "name": "India",
      "code": "IN",
      "currency": "INR",
      "footprint": 1.9
    },
    {
      "name": "United Kingdom",
      "code": "GB",
      "currency": "GBP",
      "footprint": 7.7 //https://ourworldindata.org/grapher/consumption-co2-per-capita?country=~GBR
    },
    {
      "name": "South Africa",
      "code": "ZA",
      "currency": "ZAR",
      "footprint": 8.0903569558952
    },
    {
      "name": "Afghanistan",
      "code": "AF",
      "currency": "AFN",
      "footprint": 0.92
    },
    {
      "name": "Albania",
      "code": "AL",
      "currency": "ALL",
      "footprint": 3.03
    },
    {
      "name": "Algeria",
      "code": "DZ",
      "currency": "DZD",
      "footprint": 6
    },
    {
      "name": "Andorra",
      "code": "AD",
      "currency": "EUR",
      "footprint": 6.11523777368,
      "eu":true
    },
    {
      "name": "Angola",
      "code": "AO",
      "currency": "AOA",
      "footprint": 2.91
    },
    {
      "name": "Anguilla",
      "code": "AI",
      "currency": "XCD",
      "footprint": 2.21
    },
    {
      "name": "Antigua and Barbuda",
      "code": "AG",
      "currency": "XCD",
      "footprint": 7.39
    },
    {
      "name": "Argentina",
      "code": "AR",
      "currency": "ARS",
      "footprint": 8.87
    },
    {
      "name": "Armenia",
      "code": "AM",
      "currency": "AMD",
      "footprint": 2.94
    },
    {
      "name": "Aruba",
      "code": "AW",
      "currency": "ANG",
      "footprint": 9.57
    },
    {
      "name": "Austria",
      "code": "AT",
      "currency": "EUR",
      "footprint": 9.2,
      "eu":true
    },
    {
      "name": "Azerbaijan",
      "code": "AZ",
      "currency": "AZN",
      "footprint": 5.63
    },
    {
      "name": "Bahamas",
      "code": "BS",
      "currency": "BSD",
      "footprint": 8.32
    },
    {
      "name": "Bahrain",
      "code": "BH",
      "currency": "BHD",
      "footprint": 29.12
    },
    {
      "name": "Bangladesh",
      "code": "BD",
      "currency": "BDT",
      "footprint": 1.37
    },
    {
      "name": "Barbados",
      "code": "BB",
      "currency": "BBD",
      "footprint": 11.77
    },
    {
      "name": "Belarus",
      "code": "BY",
      "currency": "BYR",
      "footprint": 15.24
    },
    {
      "name": "Belgium",
      "code": "BE",
      "currency": "EUR",
      "footprint": 10.8,
      "eu":true
    },
    {
      "name": "Belize",
      "code": "BZ",
      "currency": "BZD",
      "footprint": 2.47
    },
    {
      "name": "Benin",
      "code": "BJ",
      "currency": "XOF",
      "footprint": 1.31
    },
    {
      "name": "Bermuda",
      "code": "BM",
      "currency": "BMD",
      "footprint": 7.49
    },
    {
      "name": "Bhutan",
      "code": "BT",
      "currency": "INR",
      "footprint": 3.06
    },
    {
      "name": "Bolivia",
      "code": "BO",
      "currency": "BOB",
      "footprint": 4.83
    },
    {
      "name": "Bosnia and Herzegovina",
      "code": "BA",
      "currency": "BAM",
      "footprint": 8.72
    },
    {
      "name": "Botswana",
      "code": "BW",
      "currency": "BWP",
      "footprint": 2.9573641966436 //Eurostats 40.41
    },
    {
      "name": "Brazil",
      "code": "BR",
      "currency": "BRL",
      "footprint": 5.97
    },
    {
      "name": "British Virgin Islands",
      "code": "IO",
      "currency": "USD",
      "footprint": 5.51
    },
    {
      "name": "Brunei Darussalam",
      "code": "BN",
      "currency": "BND",
      "footprint": 18.485277284987 //Eurostats 28.83
    },
    {
      "name": "Bulgaria",
      "code": "BG",
      "currency": "BGN",
      "footprint": 8.57,
      "eu":true
    },
    {
      "name": "Burkina Faso",
      "code": "BF",
      "currency": "XOF",
      "footprint": 1.45
    },
    {
      "name": "Burundi",
      "code": "BI",
      "currency": "BIF",
      "footprint": 0.46
    },
    {
      "name": "Cambodia",
      "code": "KH",
      "currency": "KHR",
      "footprint": 2.31
    },
    {
      "name": "Cameroon",
      "code": "CM",
      "currency": "XAF",
      "footprint": 2.12
    },
    {
      "name": "Canada",
      "code": "CA",
      "currency": "CAD",
      "footprint": 21.69
    },
    {
      "name": "Cape Verde",
      "code": "CV",
      "currency": "CVE",
      "footprint": 2.25
    },
    {
      "name": "Cayman Islands",
      "code": "KY",
      "currency": "KYD",
      "footprint": 8.82
    },
    {
      "name": "Central African Republic",
      "code": "CF",
      "currency": "XAF",
      "footprint": 2.21
    },
    {
      "name": "Chad",
      "code": "TD",
      "currency": "XAF",
      "footprint": 1.48
    },
    {
      "name": "Chile",
      "code": "CL",
      "currency": "CLP",
      "footprint": 6.6
    },
    {
      "name": "China",
      "code": "CN",
      "currency": "CNY",
      "footprint": 9.35
    },
    {
      "name": "Colombia",
      "code": "CO",
      "currency": "COP",
      "footprint": 3.49
    },
    {
      "name": "Comoros",
      "code": "KM",
      "currency": "KMF",
      "footprint": 0.76
    },
    {
      "name": "Congo",
      "code": "CG",
      "currency": "KMF",
      "footprint": 3.11
    },
    {
      "name": "Cook Islands",
      "code": "CK",
      "currency": "NZD",
      "footprint": 2.75
    },
    {
      "name": "Costa Rica",
      "code": "CR",
      "currency": "CRC",
      "footprint": 2.65
    },
    {
      "name": "Côte d'Ivoire",
      "code": "CI",
      "currency": "XOF",
      "footprint": 1.14
    },
    {
      "name": "Croatia",
      "code": "HR",
      "currency": "HRK",
      "footprint": 6.1,
      "eu":true
    },
    {
      "name": "Cuba",
      "code": "CU",
      "currency": "CUP",
      "footprint": 4.5
    },
    {
      "name": "Cyprus",
      "code": "CY",
      "currency": "CYP",
      "footprint": 6.78,
      "eu":true
    },
    {
      "name": "Czech Republic",
      "code": "CZ",
      "currency": "CZK",
      "footprint": 12.2,
      "eu":true
    },
    {
      "name": "Democratic Republic of the Congo",
      "code": "CD",
      "currency": "XAF",
      "footprint": 0.53
    },
    {
      "name": "Denmark",
      "code": "DK",
      "currency": "DKK",
      "footprint": 8.9,
      "eu":true
    },
    {
      "name": "Djibouti",
      "code": "DJ",
      "currency": "DJF",
      "footprint": 2.15
    },
    {
      "name": "Dominica",
      "code": "DM",
      "currency": "XCD",
      "footprint": 2.65
    },
    {
      "name": "Dominican Republic",
      "code": "DO",
      "currency": "DOP",
      "footprint": 3.32
    },
    {
      "name": "Ecuador",
      "code": "EC",
      "currency": "USD",
      "footprint": 4.37
    },
    {
      "name": "Egypt",
      "code": "EG",
      "currency": "EGP",
      "footprint": 3.44
    },
    {
      "name": "El Salvador",
      "code": "SV",
      "currency": "SVC",
      "footprint": 1.85
    },
    {
      "name": "Equatorial Guinea",
      "code": "GQ",
      "currency": "XAF",
      "footprint": 6.37
    },
    {
      "name": "Eritrea",
      "code": "ER",
      "currency": "ETB",
      "footprint": 1.24
    },
    {
      "name": "Estonia",
      "code": "EE",
      "currency": "EEK",
      "footprint": 18.55,
      "eu":true
    },
    {
      "name": "Ethiopia",
      "code": "ET",
      "currency": "ETB",
      "footprint": 1.51
    },
    {
      "name": "Faeroe Islands",
      "code": "FO",
      "currency": "DKK",
      "footprint": 13.047817390766 // Eurostats 62.70
    },
    {
      "name": "Falkland Islands (Malvinas)",
      "code": "FK",
      "currency": "FKP"
    },
    {
      "name": "Fiji",
      "code": "FJ",
      "currency": "FJD",
      "footprint": 2.81
    },
    {
      "name": "Finland",
      "code": "FI",
      "currency": "EUR",
      "footprint": 13.89,
      "eu":true
    },
    {
      "name": "France",
      "code": "FR",
      "currency": "EUR",
      "footprint": 6.98,
      "eu":true
    },
    {
      "name": "French Guiana",
      "code": "GF",
      "currency": "EUR",
      "footprint": 3.3,
      "eu":true
    },
    {
      "name": "French Polynesia",
      "code": "PF",
      "currency": "XPF",
      "footprint": 2.62
    },
    {
      "name": "Gabon",
      "code": "GA",
      "currency": "XAF",
      "footprint": 7.94
    },
    {
      "name": "Gambia",
      "code": "GM",
      "currency": "GMD",
      "footprint": 0.26780913418482
    },
    {
      "name": "Georgia",
      "code": "GE",
      "currency": "GEL",
      "footprint": 4.03
    },
    {
      "name": "Germany",
      "code": "DE",
      "currency": "EUR",
      "footprint": 10.7,
      "eu":true
    },
    {
      "name": "Ghana",
      "code": "GH",
      "currency": "GHS",
      "footprint": 1.29
    },
    {
      "name": "Gibraltar",
      "code": "GI",
      "currency": "GIP",
      "footprint": 17.4
    },
    {
      "name": "Greece",
      "code": "GR",
      "currency": "EUR",
      "footprint": 9.0,
      "eu":true
    },
    {
      "name": "Greenland",
      "code": "GL",
      "currency": "DKK",
      "footprint": 9.72,
      "eu":true
    },
    {
      "name": "Grenada",
      "code": "GD",
      "currency": "XCD",
      "footprint": 3.08
    },
    {
      "name": "Guadeloupe",
      "code": "GP",
      "currency": "EUR",
      "footprint": 6.14
    },
    {
      "name": "Guatemala",
      "code": "GT",
      "currency": "GTQ",
      "footprint": 2.04
    },
    {
      "name": "Guinea",
      "code": "GN",
      "currency": "GNF",
      "footprint": 1.66
    },
    {
      "name": "Guinea-Bissau",
      "code": "GW",
      "currency": "XOF",
      "footprint": 1.57
    },
    {
      "name": "Guyana",
      "code": "GY",
      "currency": "GYD",
      "footprint": 6.42
    },
    {
      "name": "Haiti",
      "code": "HT",
      "currency": "HTG",
      "footprint": 1.29
    },
    {
      "name": "Honduras",
      "code": "HN",
      "currency": "HNL",
      "footprint": 2.21
    },
    {
      "name": "Hong Kong",
      "code": "HK",
      "currency": "HKD",
      "footprint": 6.87
    },
    {
      "name": "Hungary",
      "code": "HU",
      "currency": "HUF",
      "footprint": 6.78,
      "eu":true
    },
    {
      "name": "Iceland",
      "code": "IS",
      "currency": "ISK",
      "footprint": 17.5
    },
    {
      "name": "Indonesia",
      "code": "ID",
      "currency": "IDR",
      "footprint": 23.48
    },
    {
      "name": "Iran",
      "code": "IR",
      "currency": "IRR",
      "footprint": 10.28
    },
    {
      "name": "Iraq",
      "code": "IQ",
      "currency": "IQD",
      "footprint": 6.59
    },
    {
      "name": "Ireland",
      "code": "IE",
      "currency": "EUR",
      "footprint": 13.2,
      "eu":true
    },
    {
      "name": "Israel",
      "code": "IL",
      "currency": "ILS",
      "footprint": 10.2
    },
    {
      "name": "Italy",
      "code": "IT",
      "currency": "EUR",
      "footprint": 7.3,
      "eu":true
    },
    {
      "name": "Jamaica",
      "code": "JM",
      "currency": "JMD",
      "footprint": 3.6
    },
    {
      "name": "Japan",
      "code": "JP",
      "currency": "JPY",
      "footprint": 8.46
    },
    {
      "name": "Jordan",
      "code": "JO",
      "currency": "JOD",
      "footprint": 3.61
    },
    {
      "name": "Kazakhstan",
      "code": "KZ",
      "currency": "KZT",
      "footprint": 20.29
    },
    {
      "name": "Kenya",
      "code": "KE",
      "currency": "KES",
      "footprint": 1.81
    },
    {
      "name": "Kiribati",
      "code": "KI",
      "currency": "AUD",
      "footprint": 0.64
    },
    {
      "name": "Kosovo",
      "code": "KR",
      "currency": "EUR",
      "footprint": 4.8159106920284
    },
    {
      "name": "Kuwait",
      "code": "KW",
      "currency": "KWD",
      "footprint": 32.97
    },
    {
      "name": "Kyrgyzstan",
      "code": "KG",
      "currency": "KGS",
      "footprint": 3.35
    },
    {
      "name": "Laos",
      "code": "LA",
      "currency": "LAK",
      "footprint": 2.24
    },
    {
      "name": "Latvia",
      "code": "LV",
      "currency": "LVL",
      "footprint": 6.3,
      "eu":true
    },
    {
      "name": "Lebanon",
      "code": "LB",
      "currency": "LBP",
      "footprint": 5.0
    },
    {
      "name": "Lesotho",
      "code": "LS",
      "currency": "LSL",
      "footprint": 1.31
    },
    {
      "name": "Liberia",
      "code": "LR",
      "currency": "LRD",
      "footprint": 0.84
    },
    {
      "name": "Libya",
      "code": "LY",
      "currency": "LYD",
      "footprint": 10.99
    },
    {
      "name": "Liechtenstein",
      "code": "LI",
      "currency": "CHF",
      "footprint": 4.8
    },
    {
      "name": "Lithuania",
      "code": "LT",
      "currency": "LTL",
      "footprint": 7.4,
      "eu":true
    },
    {
      "name": "Luxembourg",
      "code": "LU",
      "currency": "EUR",
      "footprint": 20.3,
      "eu":true
    },
    {
      "name": "Macao",
      "code": "MO",
      "currency": "MOP",
      "footprint": 2.15
    },
    {
      "name": "Macedonia (Republic of)",
      "code": "MK",
      "currency": "MKD",
      "footprint": 3.4894623364765
    },
    {
      "name": "Madagascar",
      "code": "MG",
      "currency": "MGA",
      "footprint": 1.2
    },
    {
      "name": "Malawi",
      "code": "MW",
      "currency": "MWK",
      "footprint": 0.61
    },
    {
      "name": "Malaysia",
      "code": "MY",
      "currency": "MYR",
      "footprint": 10.37
    },
    {
      "name": "Maldives",
      "code": "MV",
      "currency": "MVR",
      "footprint": 2.19
    },
    {
      "name": "Mali",
      "code": "ML",
      "currency": "XOF",
      "footprint": 1.72
    },
    {
      "name": "Malta",
      "code": "MT",
      "currency": "MTL",
      "footprint": 5.5,
      "eu":true
    },
    {
      "name": "Marshall Islands",
      "code": "MH",
      "currency": "USD",
      "footprint": 2.5807611319398
    },
    {
      "name": "Martinique",
      "code": "MQ",
      "currency": "EUR",
      "footprint": 6.75
    },
    {
      "name": "Mauritania",
      "code": "MR",
      "currency": "MRO",
      "footprint": 2.87
    },
    {
      "name": "Mauritius",
      "code": "MU",
      "currency": "MUR",
      "footprint": 3.7
    },
    {
      "name": "Mexico",
      "code": "MX",
      "currency": "MXN",
      "footprint": 6.12
    },
    {
      "name": "Micronesia (Federated States of)",
      "code": "FM",
      "currency": "USD",
      "footprint": 1.3383345170455
    },
    {
      "name": "Moldova",
      "code": "MD",
      "currency": "MDL",
      "footprint": 3.22
    },
    {
      "name": "Mongolia",
      "code": "MN",
      "currency": "MNT",
      "footprint": 15.25
    },
    {
      "name": "Montenegro",
      "code": "ME",
      "currency": "EUR",
      "footprint": 3.1990621351398
    },
    {
      "name": "Montserrat",
      "code": "MS",
      "currency": "XCD",
      "footprint": 5.9645503705187
    },
    {
      "name": "Morocco",
      "code": "MA",
      "currency": "MAD",
      "footprint": 2.42
    },
    {
      "name": "Mozambique",
      "code": "MZ",
      "currency": "MZN",
      "footprint": 0.93
    },
    {
      "name": "Myanmar",
      "code": "MM",
      "currency": "MMK",
      "footprint": 2.48
    },
    {
      "name": "Namibia",
      "code": "NA",
      "currency": "NAD",
      "footprint": 4.69
    },
    {
      "name": "Nauru",
      "code": "NR",
      "currency": "AUD",
      "footprint": 4.7093720712277
    },
    {
      "name": "Nepal",
      "code": "NP",
      "currency": "NPR",
      "footprint": 1.46
    },
    {
      "name": "Netherlands",
      "code": "NL",
      "currency": "EUR",
      "footprint": 11.6,
      "eu":true
    },
    {
      "name": "New Caledonia",
      "code": "NC",
      "currency": "XPF",
      "footprint": 21.64
    },
    {
      "name": "Nicaragua",
      "code": "NI",
      "currency": "NIO",
      "footprint": 3.07
    },
    {
      "name": "Niger",
      "code": "NE",
      "currency": "XOF",
      "footprint": 1.61
    },
    {
      "name": "Nigeria",
      "code": "NG",
      "currency": "NGN",
      "footprint": 1.71
    },
    {
      "name": "Niue",
      "code": "NU",
      "currency": "NZD",
      "footprint": 4.772037037037
    },
    {
      "name": "North Korea",
      "code": "NF",
      "currency": "AUD",
      "footprint": 2.09
    },
    {
      "name": "Norway",
      "code": "NO",
      "currency": "NOK",
      "footprint": 10.1
    },
    {
      "name": "Occupied Palestinian Territory",
      "code": "PS",
      "currency": "JOD",
      "footprint": 0.65998639928324
    },
    {
      "name": "Oman",
      "code": "OM",
      "currency": "OMR",
      "footprint": 24.88
    },
    {
      "name": "Pakistan",
      "code": "PK",
      "currency": "PKR",
      "footprint": 2.14
    },
    {
      "name": "Palau",
      "code": "PW",
      "currency": "USD",
      "footprint": 13.167476405875
    },
    {
      "name": "Panama",
      "code": "PA",
      "currency": "PAB",
      "footprint": 4.21
    },
    {
      "name": "Papua New Guinea",
      "code": "PG",
      "currency": "PGK",
      "footprint": 0.94
    },
    {
      "name": "Paraguay",
      "code": "PY",
      "currency": "PYG",
      "footprint": 6.03
    },
    {
      "name": "Peru",
      "code": "PE",
      "currency": "PEN",
      "footprint": 2.8
    },
    {
      "name": "Philippines",
      "code": "PH",
      "currency": "PHP",
      "footprint": 2.05
    },
    {
      "name": "Poland",
      "code": "PL",
      "currency": "PLN",
      "footprint": 11.0,
      "eu":true
    },
    {
      "name": "Portugal",
      "code": "PT",
      "currency": "EUR",
      "footprint": 7.0,
      "eu":true
    },
    {
      "name": "Qatar",
      "code": "QA",
      "currency": "QAR",
      "footprint": 37.970875175523
    },
    {
      "name": "Republic of South Sudan",
      "code": "SS",
      "currency": "QAR",
      "footprint": 0.17040929663617
    },
    {
      "name": "Réunion",
      "code": "RE",
      "currency": "EUR",
      "footprint": 3.88
    },
    {
      "name": "Romania",
      "code": "RO",
      "currency": "RON",
      "footprint": 3.8,
      "eu":true
    },
    {
      "name": "Russian Federation",
      "code": "RU",
      "currency": "RUB",
      "footprint": 15.53
    },
    {
      "name": "Rwanda",
      "code": "RW",
      "currency": "RWF",
      "footprint": 0.55
    },
    {
      "name": "Saint Helena",
      "code": "SH",
      "currency": "GBP",
      "footprint": 4.36
    },
    {
      "name": "Saint Kitts and Nevis",
      "code": "KN",
      "currency": "XCD",
      "footprint": 4.88
    },
    {
      "name": "Saint Lucia",
      "code": "LC",
      "currency": "XCD",
      "footprint": 2.71
    },
    {
      "name": "Saint Pierre and Miquelon",
      "code": "PM",
      "currency": "EUR",
      "footprint": 13.15
    },
    {
      "name": "Saint Vincent and the Grenadines",
      "code": "VC",
      "currency": "XCD",
      "footprint": 2.22
    },
    {
      "name": "Samoa",
      "code": "WS",
      "currency": "EUR",
      "footprint": 1.85
    },
    {
      "name": "Sao Tome and Principe",
      "code": "ST",
      "currency": "STD",
      "footprint": 1.23
    },
    {
      "name": "Saudi Arabia",
      "code": "SA",
      "currency": "SAR",
      "footprint": 19
    },
    {
      "name": "Senegal",
      "code": "SN",
      "currency": "XOF",
      "footprint": 1.5
    },
    {
      "name": "Serbia",
      "code": "RS",
      "currency": "RSD",
      "footprint": 8.1
    },
    {
      "name": "Seychelles",
      "code": "SC",
      "currency": "SCR",
      "footprint": 10.43
    },
    {
      "name": "Sierra Leone",
      "code": "SL",
      "currency": "SLL",
      "footprint": 0.85
    },
    {
      "name": "Singapore",
      "code": "SG",
      "currency": "SGD",
      "footprint": 10.54
    },
    {
      "name": "Slovakia",
      "code": "SK",
      "currency": "SKK",
      "footprint": 8.0,
      "eu":true
    },
    {
      "name": "Slovenia",
      "code": "SI",
      "currency": "EUR",
      "footprint": 8.5,
      "eu":true
    },
    {
      "name": "Solomon Islands",
      "code": "SB",
      "currency": "SBD",
      "footprint": 0.51
    },
    {
      "name": "Somalia",
      "code": "SO",
      "currency": "SOS",
      "footprint": 1.99
    },
    {
      "name": "South Korea",
      "code": "GS",
      "currency": "GBP",
      "footprint": 13.92
    },
    {
      "name": "Spain",
      "code": "ES",
      "currency": "EUR",
      "footprint": 7.5,
      "eu":true
    },
    {
      "name": "Sri Lanka",
      "code": "LK",
      "currency": "LKR",
      "footprint": 1.71
    },
    {
      "name": "Sudan",
      "code": "SD",
      "currency": "SDG",
      "footprint": 2.37
    },
    {
      "name": "Suriname",
      "code": "SR",
      "currency": "SRD",
      "footprint": 5.43
    },
    {
      "name": "Swaziland",
      "code": "SZ",
      "currency": "SZL",
      "footprint": 1.054932714707
    },
    {
      "name": "Sweden",
      "code": "SE",
      "currency": "SEK",
      "footprint": 5.4,
      "eu":true
    },
    {
      "name": "Switzerland",
      "code": "CH",
      "currency": "CHF",
      "footprint": 6.1
    },
    {
      "name": "Syria",
      "code": "SY",
      "currency": "CHF",
      "footprint": 2.41
    },
    {
      "name": "Taiwan",
      "code": "TW",
      "currency": "TWD",
      "footprint": 12.96
    },
    {
      "name": "Tajikistan",
      "code": "TJ",
      "currency": "TJS",
      "footprint": 1.80
    },
    {
      "name": "Tanzania",
      "code": "TZ",
      "currency": "TZS",
      "footprint": 1.43
    },
    {
      "name": "Thailand",
      "code": "TH",
      "currency": "THB",
      "footprint": 5.78
    },
    {
      "name": "Timor-Leste",
      "code": "TP",
      "currency": "IDR",
      "footprint": 1.08
    },
    {
      "name": "Togo",
      "code": "TG",
      "currency": "XOF",
      "footprint": 1.1
    },
    {
      "name": "Tonga",
      "code": "TO",
      "currency": "TOP",
      "footprint": 1.95
    },
    {
      "name": "Trinidad and Tobago",
      "code": "TT",
      "currency": "TTD",
      "footprint": 37.38
    },
    {
      "name": "Tunisia",
      "code": "TN",
      "currency": "TND",
      "footprint": 3.66
    },
    {
      "name": "Turkey",
      "code": "TR",
      "currency": "TRY",
      "footprint": 6.5
    },
    {
      "name": "Turkmenistan",
      "code": "TM",
      "currency": "TMT",
      "footprint": 22.23
    },
    {
      "name": "Turks and Caicos Islands",
      "code": "TC",
      "currency": "USD",
      "footprint": 5.2
    },
    {
      "name": "Tuvalu",
      "code": "TV",
      "currency": "AUD",
      "footprint": 1.0076468543622
    },
    {
      "name": "Uganda",
      "code": "UG",
      "currency": "UGX",
      "footprint": 1.1
    },
    {
      "name": "Ukraine",
      "code": "UA",
      "currency": "UAH",
      "footprint": 20.5
    },
    {
      "name": "United Arab Emirates",
      "code": "AE",
      "currency": "AED",
      "footprint": 26.94
    },
    {
      "name": "Uruguay",
      "code": "UY",
      "currency": "UYU",
      "footprint": 11.65
    },
    {
      "name": "Uzbekistan",
      "code": "UZ",
      "currency": "UZS",
      "footprint": 5.6
    },
    {
      "name": "Vanuatu",
      "code": "VU",
      "currency": "VUV",
      "footprint": 2.26
    },
    {
      "name": "Venezuela",
      "code": "VE",
      "currency": "VEF",
      "footprint": 8.17
    },
    {
      "name": "Vietnam",
      "code": "VN",
      "currency": "VND",
      "footprint": 3.59
    },
    {
      "name": "Wallis and Futuna Islands",
      "code": "WF",
      "currency": "XPF",
      "footprint": 2.3202984306663
    },
    {
      "name": "Western Sahara",
      "code": "EH",
      "currency": "MAD",
      "footprint": 1.58
    },
    {
      "name": "Yemen",
      "code": "YE",
      "currency": "YER",
      "footprint": 1.2
    },
    {
      "name": "Zambia",
      "code": "ZM",
      "currency": "ZMK",
      "footprint": 1.47
    },
    {
      "name": "Zimbabwe",
      "code": "ZW",
      "currency": "ZWD",
      "footprint": 1.89
    }
  ]
};

export const getDataByCountryCode = (code) => {
  const found = data.countries.find((country) => country.code === code.toUpperCase());
  if (!found) {
    console.error(`requested country with unknown code: ${code}`)
    return data.countries.find(country => country.code === "US")
  }
  return found;
};

export const countryUsesImperialUnits = (code) => {
  return data.countries.find((country) => country.code === code.toUpperCase())?.unitSystem==="IMPERIAL";
};

export const getDataByCountryName = (name) => {
  return data.countries.find((country) => country.name.toUpperCase() === name.toUpperCase());
};

export const getDataByCountryCurrency = (currency) => {
  return data.countries.find((country) => country.currency === currency);
};

export const getCountryCount = data.countries.length;

export default getDataByCountryCode;
