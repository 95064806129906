import * as types from "./actionTypes";
import * as config from "../../../config";
const {getImpactMerchantApi} = require("@carbonclick/carbon-api-client/src/api/impactApi");
import { extractApiError } from '../../util/errorExtractor'
import { beginApiCall } from "./apiStatusActions";
import { errorHandlerActions } from './errorHandlerActions';

export function getMerchantImpactSuccess(impactMerchantStats) {
  return { type: types.MERCHANT_IMPACT_SUCCESS, impactMerchantStats };
}

export function getMerchantImpactFailed(error) {
  return { type: types.MERCHANT_IMPACT_FAILED, error };
}

export function getMerchantImpact(merchant, locale) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return getImpactMerchantApi(merchant, locale)
      .then(impact => {
        dispatch(getMerchantImpactSuccess(impact));
      })
      .catch(error => {
        const status = extractApiError(error).status;
        const sentryMessage = `${config.deployment_env} impactMerchantActions.js : impact api call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(getMerchantImpactFailed(error));
      });
  };
}
