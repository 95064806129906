import * as types from "../actions/actionTypes";

export default function geoLocationReducer(
    state = {},
    action
) {
    switch (action.type) {
        case types.GEO_LOCATION_SUCCESS:
            return {country: action.country};
        case types.GEO_LOCATION_FAILED:
            return action.error;
        default:
            return state;
    }
}
