import * as types from "./actionTypes";
import * as config from "../../../config";
const {
  customerUserApi,updateUserApi
} = require("@carbonclick/carbon-api-client/src/api/customerUserApi");
import { beginApiCall } from "./apiStatusActions";
import {extractApiError} from "../../util/errorExtractor";
import { errorHandlerActions } from './errorHandlerActions';

export function getUserSuccess(user) {
  return { type: types.GET_USER_SUCCESS, user };
}

export function getUserFailed(error) {
  return { type: types.GET_USER_FAILED, error };
}

export function resetUser(nullValue) {
  return { type: types.RESET_USER, nullValue };
}

export function doesUserExist(email, hasLoginCredentials) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return customerUserApi(email, hasLoginCredentials)
      .then((user) => {
        dispatch(getUserSuccess(user));
      })
      .catch((error) => {
        if(error && error.error && error.error.status !== 404){
          const status = extractApiError(error).status;
          const sentryMessage = `${config.deployment_env} customerActions.js (email: ${email ? email : 'Not found'}): customer api: doesUserExist call error ${JSON.stringify(extractApiError(error))}`;
          dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        }
        dispatch(getUserFailed(error));
      });
  };
}

export function updateUser(email, updateLastSignedInAt) {
  return function (dispatch) {
    return updateUserApi(email, updateLastSignedInAt)
      .catch((error) => {
        const status = extractApiError(error).status;
        const sentryMessage = `${config.deployment_env} customerActions.js (id: ${email ? email : 'Not found'}): customer api: updateUser call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"))
      })
  }
}

export function resetUserData(nullValue) {
  return function (dispatch) {
    return dispatch(resetUser(nullValue));
  };
}
