import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function subscriptionReducer(
  state = initialState.hasSubscription ? initialState.hasSubscription : [],
  action
) {
  switch (action.type) {
    case types.HAS_SUBS_SUCCESS:
      return action.hasSubscription;
    case types.HAS_SUBS_FAILED:
      return action.error;
    default:
      return state;
  }
}
