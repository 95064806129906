import {generatePath, matchPath, useRouteMatch} from "react-router-dom";
import * as link from "../components/common/pages";
import generatePathWithCurrentMerchantCodeAsBase from "./generatePathWithCurrentMerchantCodeAsBase";

const acquireBasePath = (merchantCode) => `${location.protocol}//${location.host}${!merchantCode ? '' : addOrReplaceMerchantCodeBasePathPart('',merchantCode)}`;

const removeUrlParameters = (urlParamsToRemove, reloadPage = true) => {
  if (!urlParamsToRemove.length) return;
  const newLocationSearch = "?" + (window.location.search
    .substring(1)
    .split("&")
    .filter(p => !urlParamsToRemove.includes(p.split("=")[0]))
    .join("&"));
  if (reloadPage) {
    window.location.search = newLocationSearch;
  } else {
    console.log('Route debug:pathUtils:removeUrlParameters')
    window.history.replaceState({}, document.title, window.location.pathname + newLocationSearch)
  }

}

const getLinkToProject = (code) =>
    `${
        generatePathWithCurrentMerchantCodeAsBase((useRouteMatch().url.includes(link.DASHBOARD) ? link.DASHBOARD : '') +link.PROJECT_DETAILS_PAGE)
     }/${code}`;

// Function joins path and ensures slash dividers without duplication.
// Example
// ["http://base.path/", "/transaction", "1234"] => "http://base.path/transaction/1234"
const joinPath = array => array
  .map(str => str.replace(/\/*$/, "/"))
  .map(str => str.replace(/^\/+/, ""))
  .reduce((a,b) => a.concat(b));

const addOrReplaceMerchantCodeBasePathPart = (path, newMerchantCode) =>{
  // console.log(`Adding/replacing merchant ${newMerchantCode} in ${path}`);
  try {

    const routeMatch = matchPath(location.pathname, {path: link.BASE_PATH_WITH_MERCHANT_CODE + ':remainder(/.*)'});

    if (routeMatch) {
      return generatePath(link.BASE_PATH_WITH_MERCHANT_CODE + routeMatch.params.remainder, {referrerMerchantCodeInPath: newMerchantCode})
    } else {
      return generatePath(link.BASE_PATH_WITH_MERCHANT_CODE + path, {referrerMerchantCodeInPath: newMerchantCode})
    }
  }
  catch (e) {
      console.log("Encountered issue in addOrReplaceMerchantCodeBasePathPart; return referrer merchant's '/' instead ")
      return generatePath(link.BASE_PATH_WITH_MERCHANT_CODE,{referrerMerchantCodeInPath:newMerchantCode});
    }
}


export {
  acquireBasePath, getLinkToProject, joinPath, removeUrlParameters, addOrReplaceMerchantCodeBasePathPart
}