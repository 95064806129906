import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function contributionsReducer(
  state = initialState.contributions,
  action
) {
  switch (action.type) {
    case types.GET_CONTRIBUTIONS_PAGE:
      return initialState.contributions;
    case types.GET_CONTRIBUTIONS_PAGE_FAILED:
      return action.error;
    case types.GET_CONTRIBUTIONS_PAGE_SUCCESS:
      return action.page;
    default:
      return state;
  }
}