import React, {Suspense, lazy, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Redirect, Route, Switch, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import * as link from "./common/pages";
import { Spin } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as geoLocationActions from "../redux/actions/geoLocationActions";
import * as cognitoActions from "../redux/actions/cognitoActions";
import * as userActions from "../redux/actions/userActions";

const { createMerchantApi } = require("@carbonclick/carbon-api-client/src/api/merchantApi");

import store from "store";
import {
  checkAllowThemingWithoutConfigurationLocation,
  getUIConfigByMerchantCode, uiConfigsMerchantCodes
} from "../util/uiConfigs/uiConfig";
import UIContext from "../util/UIContext";
import UserInfoContext from "../util/UserInfoContext";
import {setThemedCssVariables} from "../util/themeUtils";
import * as customerActions from "../redux/actions/customerActions";
import * as merchantActions from "../redux/actions/merchantActions";
import {addOrReplaceMerchantCodeBasePathPart} from "../util/pathUtils";
import * as authentication from "./security/authentication";
import * as Sentry from "@sentry/browser";
import {checkJwtToken} from "@carbonclick/carbon-api-client/src/api/baseApi";
import {getMyCCComponent} from "@carbonclick/carbon-api-client/src/api/cmsApi";
import ReactGA from "react-ga4";

export const reloadPage = (error) => {
  console.log("error that caused reload:"+error);
  window.location.href = window.location.pathname + window.location.search + window.location.hash;
  window.location = window.location.href;
}

const SignIn = lazy(() => import(/* webpackChunkName: "entry-page", webpackMode: "eager" */"./entry/SignIn").catch(reloadPage));
const MyCCDashboard = lazy(() => import(/* webpackChunkName: "my-cc-page" */"./my-carbon-click/MyCarbonClick").catch(reloadPage));
const PageNotFound = lazy(() => import(/* webpackChunkName: "not-found-page" */"./PageNotFound").catch(reloadPage));
const SubscriptionSuccess = lazy(() => import(/* webpackChunkName: "payment-page" */"./payment/SubscriptionSuccess").catch(reloadPage));
const SubscribeGuest = lazy(() => import(/* webpackChunkName: "plans-page", webpackMode: "eager" */"./subscribe-guest/SubscribeGuest").catch(reloadPage));
const TransactionPage = lazy(() => import(/* webpackChunkName: "transaction-page", webpackMode: "eager" */"./transaction/TransactionPage").catch(reloadPage));
const ContributionsPage = lazy(() => import(/* webpackChunkName: "contributions-page"*/"./my-carbon-click/contributions/ContributionsPage").catch(reloadPage))
const DynamicProjectsPage = lazy(() => import(/* webpackChunkName: "projects-page" */"./dynamic-projects").catch(reloadPage));
const MerchantsPage = lazy(() => import(/* webpackChunkName: "merchants-page"*/"./merchant/MerchantsPage").catch(reloadPage))
const ProjectsByBasketPage = lazy(() => import(/* webpackChunkName: "projects-page" */"./dynamic-projects/ProjectsByBasket").catch(reloadPage));
const ProjectDetailsPage = lazy(() => import(/* webpackChunkName: "projects-page" */"./dynamic-projects/ProjectDetails").catch(reloadPage));
const TrackAndTracePage = lazy(() => import(/* webpackChunkName: "projects-page" */"./dynamic-projects/TrackAndTracePage").catch(reloadPage));
const PublicViewMovementPage = lazy(() => import(/* webpackChunkName: "my-cc-page" */"./movement").catch(reloadPage));
const CalculatorPage = lazy(() => import(/* webpackChunkName: "cc-calculator" */"./calculator/Calculator").catch(reloadPage));
const FlightCalculatorPage = lazy(() => import(/* webpackChunkName: "cc-flight-calculator" */"./my-carbon-click/my-travel/FlightCalculator").catch(reloadPage));
const DrivingCalculatorWidgetEmbedded = lazy(() => import(/* webpackChunkName: "cc-driving-calculator" */"./driving-calculator/DrivingCalculatorWidgetEmbedded").catch(reloadPage));
const OffsetPurchaseWidgetEmbedded = lazy(() => import(/* webpackChunkName: "cc-driving-calculator" */"./purchase-offset/PurchaseOffsetWidgetEmbedded").catch(reloadPage));
const Merchant = lazy(() => import(/* webpackChunkName: "cc-merchant" */"./merchant/Merchant").catch(reloadPage))
const ProjectsContentPage = lazy(() => import(/* webpackChunkName: "cc-projects-content" */"./content-for-translation/ProjectsContentPage").catch(reloadPage))
const ProjectsCompliancePage = lazy(() => import(/* webpackChunkName: "cc-projects-compliance" */"./project-compliance-info/ProjectsCompliancePage").catch(reloadPage))

const subdomainRegex = new RegExp('(.*?)[./:]');
const subDomainRedirects={
  rt:{type:'merchant_portfolio',code:'round_theory'},
  128:{type:'merchant_portfolio',code:'round_theory'},
}; //127 is for local testing e.g. instead of localhost go to http://127.0.0.1:1112/

const SECONDS_TO_REFRESH_IF_NOT_LOADED = 10;

function App({currentUserCognito, ... props}) {

  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [bottomLevelDomainRedirectInfo,setBottomLevelDomainRedirectInfo] = useState();
  const [secondsUiNotLoaded, setSecondsUiNotLoaded] = useState(0);
  const match = useRouteMatch();


  //based on https://stackoverflow.com/questions/41030361/how-to-update-react-context-from-inside-a-child-component
  const [userInfo, setUserInfo] = useState({});
  const [ui, setUi] = useState(null);
  const [jwtAvailable, setJwtAvailable] = useState(false);
  const addUserInfo = (newInfo) =>{
    const newVar = {...userInfo, ...newInfo};
    setUserInfo(newVar)
  }
  const userInfoContextValue = {userInfo, setUserInfo, addUserInfo};

  useEffect(() => {
    props.actions.getCountryFromStorageOrGeoLocate();
    props.actions.getAuthenticatedUser();
  }, []);

  // Login as guest if we're not on a 'MyCC Logged In Page' AND once we've tried to login as 'human' user (it gets messy if we try to perform the login actions simulataneously)
  useEffect(() => {
    if (!location.pathname.includes('/my-cc') && currentUserCognito && (currentUserCognito.error || !currentUserCognito.isSignedIn)) {
      props.actions.loginGuest();
    }
  }, [location,currentUserCognito]);

  //Ideally this shouldn't be needed - the merchant code should be included in LINKS; but this is a catch all to at least make sure the current window location is correct (ie if shared/bookmarked)
  useEffect(() => {
    if(userInfo?.referrerMerchant?.code && (userInfo.referrerMerchant.code !== match?.params?.referrerMerchantCodeInPath)){
      const urlParams = new URLSearchParams(location.search);

      if (urlParams?.get('previousReferrerMerchant')===userInfo?.referrerMerchant?.code){
        console.log(`Route debug:App:Referrer merchant code not in url BUT NOT REDIRECTING DUE TO POTENTIAL LOOP ISSUE;. :\nfrom: ${window.location}\n userinfo context merchant code:${userInfo?.referrerMerchant?.code}\nmatch referrerMerchantCodeInPath: ${match?.params?.referrerMerchantCodeInPath}`);

      }else{
        let newPathname = `${addOrReplaceMerchantCodeBasePathPart(location.pathname,userInfo?.referrerMerchant?.code)}`;
        console.log(`Route debug:App:Referrer merchant code not in url; redirecting:\nfrom: ${window.location}\n userinfo context merchant code:${userInfo?.referrerMerchant?.code}\nmatch referrerMerchantCodeInPath: ${match?.params?.referrerMerchantCodeInPath}\nto:${newPathname}`);

        urlParams.set('previousReferrerMerchant',match?.params?.referrerMerchantCodeInPath)
        if (!location.pathname.includes('/my-cc')
            && !location.pathname.includes('/flight-calculator-embed')
            && match?.params?.referrerMerchantCodeInPath
            && match?.params?.referrerMerchantCodeInPath !== 'CARBONCLICK'
        ){
          authentication.signOut().then(() => {
            store.each((value, key) => { if (/CognitoIdentityServiceProvider/i.test(key)) store.remove(key) });
          });
          window.location.reload(false);
        } else {
          history.replace({ pathname: newPathname, search: `?${urlParams.toString()}`});
        }
      }

    }
  }, [match,userInfo]);


  useEffect(() => {
    const subDomainMatch = subdomainRegex.exec(window.location.host);
    setBottomLevelDomainRedirectInfo(subDomainMatch && (subDomainRedirects[subDomainMatch[1]] ) );
  }, []);

  const bottomLevelDomainDrivenComponent = !bottomLevelDomainRedirectInfo ? null : <Merchant/>;

  useEffect( async () => {
    if (currentUserCognito?.authData?.email) {
      const emailAddr = currentUserCognito.authData.email;

      const fetchData = async () => {
        const createMerchantData = store.get("createMerchantData")
        if (createMerchantData){
          // creates only if ?business is in the path
          store.remove("createMerchantData");
          await createMerchantApi({
            "name": createMerchantData.merchantName,
            "currency": createMerchantData.currency,
            "country": createMerchantData.merchantCountry,
            "platform": createMerchantData.platform,
            "email": emailAddr,
            "makeEmailAdmin": true,
            "merchantReferrerCode": createMerchantData.merchantReferrerCode,
            "industryCode": createMerchantData.industryCode,
            "contactName": currentUserCognito.authData.name
          });
        }

        props.actions.getUser(emailAddr);
        props.actions.updateUser(emailAddr, true);
      };
      fetchData();
    }
  }, [currentUserCognito]);

  useEffect(() => {
    if (currentUserCognito?.isSignedIn && currentUserCognito?.authData?.email){
      props.actions.loadCustomersByEmail(currentUserCognito.authData.email);
    }
  }, [currentUserCognito]);

  function getAdminRelationshipFromUser(user) {
    return user?.merchantUsers?.find(item => item.role === 'ADMIN' || item.role === 'SUPER_USER');
  }

  useEffect(() => {
    if (props.user?.merchantUsers?.length > 0) {
      const adminUser = getAdminRelationshipFromUser(props.user);
      if (adminUser?.merchantId) {
        props.actions.getMerchantById(adminUser.merchantId, true);
      }
    }
    }, [props.user])


  useEffect(() => {
    let canDetermineCurrentMerchant = false; //we don't want to update the current merchant until we have all the data we can get at this point in time; otherwise we get flickers
    if (currentUserCognito && !currentUserCognito.isSignedIn) { //not signed in
      // console.log('ready:'+JSON.stringify(currentUserCognito));
      canDetermineCurrentMerchant = true;
    } else {
      if (props.customers && props.user) { //have made loaded user and customer
        if (getAdminRelationshipFromUser(props.user)?.merchantId) { //we're a business admin...
          // console.log('ready?:'+JSON.stringify(props.merchant)+':'+!!props.merchant);
          canDetermineCurrentMerchant = !!props.merchant; //... and have loaded the merchant we're admin of
        } else {
          canDetermineCurrentMerchant = true;
          // console.log('ready:indy: '+JSON.stringify(props.user));
        }
      }
    }

    if(canDetermineCurrentMerchant){

      let userType = 'INDIVIDUAL'; //we're an individual by default unless we know otherwise
      let currentMerchant;
      let referrerMerchant;
      let programmeCode;

      if (props.merchant?.code){
        userType="BUSINESS";
        currentMerchant =  props.merchant; // a fatter version of the merchant object, from the API, when available
        referrerMerchant = {code:props.merchant.referrerMerchantCode ? props.merchant.referrerMerchantCode : 'CARBONCLICK'}; //if not explicit referrer merchant, then it's CARBONCLICK! ; yes, this is named referrerMerchantCode and all else is called merchantReferrerCode :(
        //console.log(`Business (?) merchant loaded, using referrer merchant: ${referrerMerchant}`);
      }
      else if((!match.params.referrerMerchantCodeInPath || match.params.referrerMerchantCodeInPath==='CARBONCLICK') && props.customers && !props.customers.error){
        let selectedCustomer = props.customers?.find(c => c.programmeCode !=null); //prefer a customer with a programme code (ie employee programme)
        if(!selectedCustomer){
          const sortedCustomers = props.customers.toSorted((a, b) => b.createdAt-a.createdAt);
          selectedCustomer = sortedCustomers[0]?.merchantCode==='CARBONCLICK' && sortedCustomers[1] ? sortedCustomers[1] : sortedCustomers[0]; //prefer non-CC customer if exists
          // console.log(`did not find referrerMerchantCodeInPath and have customers(${JSON.stringify(props.customers)}), so will use one of them:${customer.merchantCode}`);
        }

        currentMerchant={code: selectedCustomer?.merchantCode};
        programmeCode=selectedCustomer?.programmeCode;
      }
      else if(match.params.referrerMerchantCodeInPath){
        currentMerchant={code:match.params.referrerMerchantCodeInPath}
        //get programme code from merchant that matches in props.customers
        programmeCode = props.customers?.find(c => c.merchantCode === currentMerchant?.code)?.programmeCode;
      }
      else{
        currentMerchant={code:'CARBONCLICK'}
      }

      referrerMerchant = referrerMerchant || currentMerchant;
      addUserInfo({currentMerchant, referrerMerchant, userType, programmeCode})
    }
  }, [props.merchant, props.customers, match, props.user, currentUserCognito])

  function getProgrammeCodeFromUrlOrFromUriIfAvailable() {
    let programmeCode = urlParams.get('programmeCode');
    if(!programmeCode) {
      if (typeof sessionStorage !== 'undefined') {
        // Retrieve the stored URL from session storage
        const storedUrl = sessionStorage.getItem('from_uri'); // Replace 'yourKey' with the actual key used for storing the URL

        if (storedUrl) {
          var urlObject = new URL(window.location.origin+storedUrl);
          programmeCode = urlObject.searchParams.get('programmeCode'); // Replace 'yourQueryParam' with the actual query parameter key

        } else {
          console.error('URL not found in session storage');
        }
      }
    }

    return programmeCode;
  }

  useEffect( () => {
    // if `aasmartfuel` or 'merchantReferrerCode' query param is present, and there is no relevant customer - then create one
    if (currentUserCognito?.isSignedIn && currentUserCognito.authData?.email
        && props.customers
        && location.pathname.includes('/my-cc')
        && !props.customers.error
        && userInfo?.currentMerchant?.code
        && !(props.customers.some(c => c.merchantCode === userInfo?.currentMerchant?.code))) {
      const programmeCode = getProgrammeCodeFromUrlOrFromUriIfAvailable();

      //not allowed more than one programmeCode per customer
      if(programmeCode && (props.customers.some(c => c.programmeCode))){
        Sentry.captureException(new Error("User tried to join an additional programme"))
        console.log("User tried to join an additional programme");
        window.location='/';
      }
      else{
        props.actions.createCustomer(currentUserCognito.authData?.email, userInfo.currentMerchant.code, programmeCode)
            .then(() => {
              props.actions.loadCustomersByEmail(currentUserCognito.authData?.email);
            });
      }
    }
  }, [userInfo, props.currentUserCognito, props.customers])

  useEffect(  async () => {
    if((ui && ui.hasWlConfig && !checkAllowThemingWithoutConfigurationLocation(location.pathname)) || !jwtAvailable) {
      return;
    }

    if(userInfo.userType){
      const merchantThemeToUse =
          (userInfo.userType==='BUSINESS' && userInfo.referrerMerchant?.code)
          || (userInfo.userType==='INDIVIDUAL' && userInfo.currentMerchant?.code)
          || userInfo.currentMerchant?.code;

      let ui;

      if(uiConfigsMerchantCodes.includes(merchantThemeToUse) || checkAllowThemingWithoutConfigurationLocation(location.pathname)) {
        ui = await getUIConfigByMerchantCode(merchantThemeToUse, userInfo?.programmeCode, merchantThemeToUse);
      } else {
        ui = await getUIConfigByMerchantCode('CARBONCLICK', userInfo?.programmeCode, merchantThemeToUse);
      }

      ReactGA.gtag("set", "user_properties", {
        merchant_code: userInfo?.currentMerchant?.code,
      });

      ReactGA.gtag("set", "user_properties", {
        programme_code: userInfo?.programmeCode || 'none',
      });

      setUi(ui);
      setThemedCssVariables(ui.theme);
    }
  } , [userInfo, location, jwtAvailable]);

  useEffect(() => {
    if(secondsUiNotLoaded >= SECONDS_TO_REFRESH_IF_NOT_LOADED) {
      let guestAccessToken = '';
      let guestRefreshToken = '';
      let guestIdToken = '';
      let accessToken = '';
      let refreshToken = '';
      let idToken = '';
      for (let i = 0; i < window.localStorage.length; i++){
        if (window.localStorage.key(i).includes('CognitoIdentityServiceProvider')) {
          if(window.localStorage.key(i).includes('accessToken')){
            if(window.localStorage.key(i).includes('my-carbonclick')) {
              guestAccessToken = window.localStorage.getItem(window.localStorage.key(i));
            } else {
              accessToken = window.localStorage.getItem(window.localStorage.key(i));
            }
          } else if(window.localStorage.key(i).includes('refreshToken')){
            if(window.localStorage.key(i).includes('my-carbonclick')) {
              guestRefreshToken = window.localStorage.getItem(window.localStorage.key(i));
            } else {
              refreshToken = window.localStorage.getItem(window.localStorage.key(i));
            }
          } else if(window.localStorage.key(i).includes('idToken')){
            if(window.localStorage.key(i).includes('my-carbonclick')) {
              guestIdToken = window.localStorage.getItem(window.localStorage.key(i));
            } else {
              idToken = window.localStorage.getItem(window.localStorage.key(i));
            }
          }
        }
      }
      Sentry.captureMessage(
          `Reloading page due to UI not loaded for ${SECONDS_TO_REFRESH_IF_NOT_LOADED} seconds.`
          + `Refresh Token: ${refreshToken ? 'length is ' + refreshToken.length : 'null'}, `
          + `Access Token: ${accessToken ? 'length is ' + accessToken.length : 'null'}, `
          + `Id Token: ${idToken ? 'length is ' + idToken.length : 'null'}, `
          + `Guest Refresh Token: ${guestRefreshToken ? 'length is ' + refreshToken.length : 'null'}, `
          + `Guest Access Token: ${guestAccessToken ? 'length is ' + accessToken.length : 'null'}, `
          + `Guest Id Token: ${guestIdToken ? 'length is ' + idToken.length : 'null'}. `
          + `Cleaning up Cognito local storage variables.`, "fatal");

      for (let i = 0; i < window.localStorage.length; i++){
        if (window.localStorage.key(i).includes('CognitoIdentityServiceProvider')) {
          window.localStorage.removeItem(window.localStorage.key(i));
        }
      }

      window.location.reload();
    }
  }, [secondsUiNotLoaded])

  useEffect(async () => {
    let timeoutId;

    if(ui) {
      if(secondsUiNotLoaded !== 0) {
        setSecondsUiNotLoaded(0);
      }
    } else {
      timeoutId = setTimeout(async () => {
        setSecondsUiNotLoaded(secondsUiNotLoaded + 1);

        const jwtTokenAvailable = await checkJwtToken();

        if (jwtTokenAvailable !== jwtAvailable) {
          setJwtAvailable(jwtTokenAvailable);
        }
      }, 1000);
    }

    return () => {
      clearTimeout(timeoutId);
    }
  }, [ui, secondsUiNotLoaded, jwtAvailable]);

  if (!ui) {
    return (
        <div className="entry-spinner">
          <Spin tip="Loading..."></Spin>
        </div>)
  }

  return (
    <>
    <UserInfoContext.Provider value={userInfoContextValue}>
      <UIContext.Provider value={ui}>
      <Suspense
        fallback={
          <div className="entry-spinner">
            <Spin tip="Loading..."></Spin>
          </div>
        }
      >

        { bottomLevelDomainDrivenComponent ? bottomLevelDomainDrivenComponent :
        <>
        {ui?.code &&
        <Switch>
          <Redirect from="/:url*(/+)" to={location.pathname.slice(0, -1)+location.search}  />
          <Route
              path={`${match.path}${link.DASHBOARD}`}
              component={MyCCDashboard}
              key="my-cc-dashboard"
          />
          <Route
            exact
            path={`${match.path}${link.CALCULATOR}`}
            component={CalculatorPage}
            key="calculator-page"
          />
          <Route
            path={`${match.path}${link.PERSONAL_CALCULATOR_EMBEDDABLE}`}
            component={CalculatorPage}
            key="calculator-embeddable-page"
          />
          <Route
            path={`${match.path}${link.FLIGHT_CALCULATOR_EMBEDDABLE}`}
            component={() => <FlightCalculatorPage
                merchantCode={userInfo?.currentMerchant?.code}
                email={urlParams.get("emailAddressForWidget")}
                />}
            key="flight-calculator-embeddable-page"
          />
          <Route
            path={`${match.path}${link.DRIVING_CALCULATOR}`}
            component={DrivingCalculatorWidgetEmbedded}
            key="driving-calculator-page"
          />
           <Route
            path={`${match.path}${link.OFFSET_PURCHASE}`}
            render={() => <OffsetPurchaseWidgetEmbedded ownershipType={'PERSONAL'}/>}
            key="offset-purchase-page"
          />
           <Route
            path={`${match.path}${link.OFFSET_PURCHASE_EMBEDDABLE}`}
            render={() => <OffsetPurchaseWidgetEmbedded sendPurchaseDataPostMessage={true}/>}
            key="offset-purchase-page"
          />
          <Route
              path={`${match.path}${link.SUBSCRIPTION_SUCCESS_PAGE}`}
            component={SubscriptionSuccess}
            key="subs-success"
          />
          <Route
            path={`${match.path}${link.SUBSCRIBE_GUEST}`}
            component={SubscribeGuest}
            key="subscribe-guest"
          />

          <Route
            path={`${match.path}${link.TRANSACTION_PAGE}`}
            component={TransactionPage}
            key="transaction-page"
          />
          <Route
            path={`${match.path}${link.ALL_CONTRIBUTIONS}`}
            component={ContributionsPage}
            key="contributions-page"
          >
            <ContributionsPage cognitoUser={currentUserCognito} />
          </Route>
          <Route
            path={`${match.path}${link.PUBLIC_MOVEMENT_PAGE}/:groupId`}
            component={PublicViewMovementPage}
            key="public-movement-page"
          />
          <Route
          exact
            path={`${match.path}${link.PROJECT_DETAILS_PAGE}/:projectCode`}
            component={ProjectDetailsPage}
            key="project-details-page"
          />
          <Route
            exact
            path={`${match.path}${link.PROJECT_DETAILS_PAGE}/:projectCode${link.TRACK_AND_TRACE_PAGE}/:transactionId?`}
            component={TrackAndTracePage}
            key="project-details-page"
          />
          <Route
            path={`${match.path}${link.BASKETS_PAGE}/:basketId`}
            component={ProjectsByBasketPage}
            key="projects-by-basket-page"
          />
          <Route
            path={`${match.path}${link.BASKETS_PAGE}`}
            component={DynamicProjectsPage}
            key="baskets-page"
          />
          <Route
            path={`${match.path}${link.MERCHANTS_PAGE}`}
            component={MerchantsPage}
            key="baskets-page"
          />
          <Route
            path={`${match.path}${link.PROJECTS_CONTENT_PAGE}`}
            component={ProjectsContentPage}
            key="projects-content-page"
          />
          <Route
            path={`${match.path}${link.PROJECTS_COMPLIANCE_PAGE}`}
            component={ProjectsCompliancePage}
            key="projects-compliance-page"
          />
          <Route
            path={`${match.path}${link.APP_ENTRY_PAGE}`}
            key="app-entry-2"
          >
            <SignIn />
          </Route>
          <Route component={PageNotFound} key="not-found" />
        </Switch>
        }
        </>
        }
      </Suspense>
      </UIContext.Provider>
    </UserInfoContext.Provider>
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getCountryFromStorageOrGeoLocate: bindActionCreators(
        geoLocationActions.getCountry,
        dispatch
      ),
      updateUser: bindActionCreators(
        userActions.updateUser,
        dispatch
      ),
      loginGuest: bindActionCreators(cognitoActions.loginGuest, dispatch),
      getAuthenticatedUser: bindActionCreators(cognitoActions.getAuthenticatedUser, dispatch),
      getUser: bindActionCreators(userActions.doesUserExist, dispatch),
      loadCustomersByEmail: bindActionCreators(
          customerActions.getCustomers,
          dispatch
      ),
      getMerchantById: bindActionCreators(
          merchantActions.getMerchantById,
          dispatch
      ),
      createCustomer: bindActionCreators(
          customerActions.createCustomer,
          dispatch
      )
    }
  };
}

function mapStateToProps(state) {
  return {
    currentUserCognito: state.currentUserCognito,
    customers: state.customers,
    merchant: state.merchant,
    user: state.user
  };
}

App.propTypes = {
  actions: PropTypes.object,
  currentUserCognito: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(App);