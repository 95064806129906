import React from "react";
import {getCountryName} from "../../util/countryCodes";

const CountrySelectionStatus = ({ country, userSubscriptions, showInline, setShowModal, hideMessage, displayBasedOnView }) => {
    const CountryLink = (
        <b
            className="accent-dark-color cursor-pointer"
            onClick={() => setShowModal(true)}
            style={{ display: !showInline ? "block" : '' }}
        >
        {`${country && getCountryName(country)}`}
        </b>
    )

    const looksLikeText = (<p className="m-0">{!hideMessage && <>
        {displayBasedOnView 
            ? <>The calculation is based on {CountryLink} data</>
            : "Looks like you’re in "
        }
        </>}
        {!displayBasedOnView && CountryLink}
    </p>)
    const hasNoSubscriptions = !userSubscriptions || userSubscriptions.length === 0;

    return (country && <div style={{ fontSize: 14, display: !showInline ? "block" : 'inline-block' }} className={hasNoSubscriptions ? "location-editable" : "location-non-editable"}>
    {hasNoSubscriptions ?
        <>
        <div className="looks-like-text-dashboard">
            {looksLikeText}
        </div>
        </> :
        <>{country && <>
            <span className="looks-like-text-dashboard text-left">
                {looksLikeText}
            </span>
        </>}
        </>
    }
    </div>)
}

export default CountrySelectionStatus