import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function customersReducer(
  state = initialState.customers,
  action
) {
  switch (action.type) {
    case types.GET_CUSTOMERS_SUCCESS:
      return action.customers;
    case types.GET_CUSTOMERS_FAILED:
      return action.error;
    default:
      return state;
  }
}
