import { loginGuest } from './cognitoActions';
import * as Sentry from "@sentry/browser";
import { UNAUTHORIZED } from "../../util/httpCodes";
import { DASHBOARD } from "../../components/common/pages";

export function errorHandlerActions(status, message, flag) {
    return function (dispatch) {
        if (status === UNAUTHORIZED) {
            if (window.location.href.includes(DASHBOARD))
                window.location = window.location.href;
            else dispatch(loginGuest());
        } else
            Sentry.captureMessage(
                message,
                flag
            );
    }
}