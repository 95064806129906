import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import ReactGA from 'react-ga4'
import "antd/dist/antd.less";
import "./index.css";
import "./global.css";
import configureStore from "./redux/configureStore";
import "./styles/index.less";

//this will provide Redux store data to our React components
import { Provider as ReduxProvider } from "react-redux";
import config from "../config.js";
import BasePathRouter from "./components/BasePathRouter";

Sentry.init({
  release: config.semver,
  environment: config.deployment_env,
  dsn: config.sentry_dsn,
  ignoreErrors: [
    "ResizeObserver loop limit exceeded",
    "offsetWidth",
    "Failed to fetch",
    "Network request failed",
    "NetworkError",
    "DOMException: Failed to read the 'localStorage' property from 'Window'",
    /Loading chunk \d+ failed. *\n*\(timeout:/g,
    /Network Error/g,
  ]
//,  beforeSend(event, hint) {
//    const isChunkLoadingErrorPresent = event.breadcrumbs.find(a => a?.message?.match(/ChunkLoadError/i));
//    console.log('isChunkLoadingErrorPresent', isChunkLoadingErrorPresent)
//    if (isChunkLoadingErrorPresent) {
//      event.fingerprint = ["ChunkLoadError"];
//      return null;
//    }
//    return event;
//  }
});

ReactGA.initialize([
  {trackingId: config.google_analytics_measurement_id,
    gaOptions: {
      siteSpeedSampleRate: 100,
      allowLinker: true
    }
  }]);

ReactGA.ga('require', 'linker');
ReactGA.ga('linker:autoLink', ['carbonclick.com']);

const store = configureStore();

const params = new URLSearchParams(window.location.search)
const isHideCookieNotice = 'true' === params.get("hideCookieNotice")

render(
  <ReduxProvider store={store}>
    <Router history={history}>
      <BasePathRouter />
    </Router>
  </ReduxProvider>,
  document.getElementById("app")
);

// Cookie acceptance code
if (!isHideCookieNotice) {
  setTimeout(() => {
    const banner = document.getElementById("cookie-notice-banner");
    if (window?.localStorage?.getItem) {
      if (!window.localStorage.getItem("cookie-notice-seen")) {
        const button = document.getElementById("cookie-notice-hide");
        if (button) {
          button.addEventListener('click', () => {
            window.localStorage.setItem("cookie-notice-seen", 'true');
            banner.style.bottom = "-10vh";
            setTimeout(() => banner.remove(), 2000);
          });
          banner.style.removeProperty("visibility");
          setTimeout(() => banner.style.removeProperty("bottom"), 100);
        }
      } else banner?.remove();
    }
  }, 2000);
}
