
export default
    {
        code:'BES_US_METLIFE',
        currencies: ['USD'],
        content:{
            logo: {
                link: 'https://www.metlife.com/'
            },
            menu: {
                menuIconColor: true,
                url: 'https://www.metlife.com/',
                business: {
                    items: ['dashboard', 'myCalculator', 'projects', 'FAQ', 'myAccount']
                },
                individual: {
                    items: ['dashboard', 'myCalculator', 'projects', 'FAQ', 'myAccount']
                },
            },
            dashboard: {
                individual: {
                    items: ['badges', 'customHeader', 'calculateFootprintBanner',
                    'calculateMyFootprint', 'climateActions', 'reduceActions',
                    'supportedProjectsPanel', 'recentOffsets', 'whatIsOffsetting', 'invitationToOffset', 'myTeamPanel'],
                    merchantContent: {
                        headerText: "How you're part of our climate journey",
                        climateActionHeader: "Your personalised climate action tip",
                        items: [
                            {
                                title: "Climate Tips",
                                text: `
                                    <p>The best, most impactful tips on how to be a climate champion.</p>
                                `,
                                image: "https://res.cloudinary.com/carbonclick/image/upload/v1658137191/White%20Label/Metlife/banner-1_npa5ot.png",
                                link: "https://www.carbonclick.com/wp-content/uploads/2022/07/Climate_Tipsheet_Metlife.pdf",
                                buttonText: "Download Tipsheet",
                            },
                            {
                                title: "Climate Quiz",
                                text:`
                                    <p>Coming soon!</p>
                                    <p>Help unlock the quiz by ensuring your team is signed up!</p>
                                `,
                                image: "https://res.cloudinary.com/carbonclick/image/upload/v1658137190/White%20Label/Metlife/banner-2_nhxodb.png",
                            },
                            {
                                title: "Climate Survey",
                                text:`
                                    <p>Take the climate survey to help us understand how best to support you on your climate journey.</p>
                                `,
                                image: "https://res.cloudinary.com/carbonclick/image/upload/v1658137191/White%20Label/Metlife/banner-3_bdjnrd.png",
                                link: "https://carbonclick.typeform.com/metlife-cs-day0",
                                buttonText: "Start the survey",
                            },
                        ]
                    }
                }
            },
            myMovementPanel: {
                hideMovementInvitation: true,
                hideMovementCreation: true,
            },
            myTeamPanel: {
                hideOffsettingForPanel: true,
                items: ["teamStats"],
                hideUnnalocatedSeats: true,
                defaultUnitType: "t",
                content: {
                    borderStyle: true,
                    pointsTitle: "Points earned for Climate Friendly actions",
                    offsetTitle: "Offset equivalent in trees",
                    treesTitle: "Tree growth years",
                    totalOffset: true,
                }
            },
            signIn: {
                individual: {
                    hideSocialMedia: true,
                }
            },
        },
        theme: {
            palette: {
                additional: {
                    main: '#AA8FFF',
                }
            },
            images: {
                whatIsOffsetting: {
                    banner: 'url("https://res.cloudinary.com/carbonclick/image/upload/v1617159339/MyCC/bg-2_lslbhu.png")',
                },
                invitationToOffset: {
                    banner: 'url(\'/images/business-dashboard/Banner-Blue.png\')',
                }
            }
        }
    }