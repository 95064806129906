import React from 'react';
import { useLocation } from 'react-router-dom';

import PoweredBy from '../components/common/PoweredBy';

const PageHeader = ({ title, content, ...props }) => {
  const location = useLocation();

  const isMyCCDashboard = location.pathname.includes('/my-cc');

  return (
    <section
      className='hero-img-2 img-cover p-0'
      style={{ animation: 'animatedBackground 0.6s ease-in-out 1 normal' }}
      {...props}
    >
      <div
        className={`d-column gap-1 py-5 px-4 ${!isMyCCDashboard ? 'mx-auto' : ''}`}
        style={{
          width: 'fit-content',
          background: `linear-gradient(90deg, ${!isMyCCDashboard ? 'rgba(0, 0, 0, 0.00) 0%, ': ''} rgba(50, 42, 41, 0.21) 54.92%, rgba(0, 0, 0, 0.00) 100%)`
        }}
      >
        <h2 className={`color-white ${!isMyCCDashboard ? 'text-center' : ''}`}>{title}</h2>
        {content && (
          <div className='text-center'>{content}</div>
        )}
        {!isMyCCDashboard && <PoweredBy center={!isMyCCDashboard} />}
      </div>
    </section>
  );
};

export default PageHeader;
