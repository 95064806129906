const canyon = "/images/plans/canyon.jpg";
const aurora = "/images/plans/aurora.jpg";
const stars = "/images/plans/stars.jpg";
const people = "/images/plans/people.jpg";

const thumbPlanLight = "/images/plans/Light-sm.jpg"
const thumbPlanEveryday = "/images/plans/Everyday-sm.jpg"
const thumbPlanFamily = "/images/plans/Family-sm.jpg"
const thumbPlanHero = "/images/plans/Hero-sm.jpg"

const backPlanLight = "/images/plans/Light-lg.jpg";
const backPlanEveryday = "/images/plans/Everyday-lg.jpg";
const backPlanFamily = "/images/plans/Family-lg.jpg";
const backPlanHero = "/images/plans/Hero-lg.jpg";

export const staticPlanData = [
    {
      name: "Light",
      desc:
        "This will cover a portion of your monthly carbon footprint.",
      pricingLevel: 10,
      backgroundImage : stars,
      thumbnail: thumbPlanLight,
      cover: backPlanLight
    },
    {
      name: "Everyday",
      desc:
        "For most people, this will cover the large part of their carbon footprint.",
      pricingLevel: 20,
      backgroundImage : canyon,
      thumbnail: thumbPlanEveryday,
      cover: backPlanEveryday
    },
    {
      name: "Family",
      desc:
        "Covers a good portion of a small family’s footprint.",
      pricingLevel: 30,
      backgroundImage : people,
      thumbnail: thumbPlanFamily,
      cover: backPlanFamily
    },
    {
      name: "Hero",
      desc:
        "For climate rockstars! Cover your own climate footprint, and pay it forward to friends and family.",
      pricingLevel: 40,
      backgroundImage : aurora,
      thumbnail: thumbPlanHero,
      cover: backPlanHero
    }
  ];

export const getStaticPlanDataByPricingLevel = (pricingLevel) => {
    return staticPlanData.find((plan) => plan.pricingLevel === pricingLevel);
};
