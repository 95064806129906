import React, {useEffect, lazy, useContext} from 'react';

const TransactionDetails = lazy(() => import(/* webpackChunkName: "transaction-details", webpackMode: "eager" */"./TransactionDetails").catch(reloadPage));
const CustomHeader = lazy(() => import("../../components/common/banners/CustomHeader").catch(reloadPage));
const Footer = lazy(() => import("../../components/common/navigation/Footer").catch(reloadPage));
import * as Sentry from "@sentry/browser";

import { useMediaQuery } from "react-responsive";
import { isMobile } from "../common/viewBreakpoints";
import { generatePath, useHistory, useParams } from "react-router-dom";

import PropTypes from "prop-types";
import * as ccAnalytics from "../../util/analytics/ccAnalytics";
import {Spin} from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as impactTransactionActions from "../../redux/actions/impactTransactionActions";
import * as subscriptionActions from "../../redux/actions/subscriptionActions";
import * as paymentActions from "../../redux/actions/paymentActions";
import * as impactAllActions from "../../redux/actions/impactAllActions";
import * as impactMerchantActions from "../../redux/actions/impactMerchantActions";
import "./style.css";
import {reloadPage} from "../App";
import UIContext from "../../util/UIContext";
import {isUUID} from "../../util/validationUtils";
import UserInfoContext from "../../util/UserInfoContext";
import ProjectList from "../../components/common/projects/ProjectList";
import {Helmet} from "react-helmet";
import * as link from "../common/pages";

const TransactionPage = (props) => {

  const uiContext = useContext(UIContext);
  const { userInfo } = useContext(UserInfoContext);
  const history = useHistory();

  useEffect(async () => {
    if(
        props.transactionDetails?.merchantCode &&
        userInfo?.currentMerchant.code !== props.transactionDetails?.merchantCode
    ) {
      const urlParams = new URLSearchParams(location.search);
      let newPathname = `${
        generatePath(link.BASE_PATH_WITH_MERCHANT_CODE + link.TRANSACTION_PAGE,
            {
              id: props.transactionDetails.id, 
              referrerMerchantCodeInPath: props.transactionDetails?.merchantCode
            }
        )}`;
      history.replace({ pathname: newPathname, search: `?${urlParams.toString()}`});
    }
  }, [props.transactionDetails]);

  const isMobileDevice = isMobile(useMediaQuery);
  const reference = props.transactionDetails?.orderReference;
  const { id } = useParams();

  const { transactionImpact } = props;

  const displayMerchantImpactPanel = uiContext.content.transaction.items.includes('merchantImpactPanel');
  const isCarbonclick = userInfo?.currentMerchant.code === 'CARBONCLICK';
  let locale = props.geoLocationUser && `en_${props.geoLocationUser.country}`;

  useEffect(() => {
    if (props.geoLocationUser && (props.cognitoGuest?.isSignedIn || props.currentUserCognito?.isSignedIn)){
        if (displayMerchantImpactPanel && uiContext?.code) {
          props.actions.getMerchantImpact(uiContext?.code, `en_${props.geoLocationUser.country}`)
        } else {
          props.actions.loadCarbonClickImpact(locale);
        }
        if(isUUID(id)) {
          props.actions.getImpactTransaction(id, locale);
          props.actions.getTransactionById(id);
        }
        else{
          Sentry.captureMessage("Transaction id was invalid",{tags:{transactionId:id}})
        }
    }
  }, [id, props.geoLocationUser, props.cognitoGuest, props.currentUserCognito]);

  useEffect(() => {
    if (props.currentUserCognito?.isSignedIn && props.currentUserCognito.authData?.email) {
      props.actions.getSubscriptionsByEmail(props.currentUserCognito.authData.email);
    }
  }, [props.currentUserCognito]);

  const googleAnalyticsPage = "transaction";

  useEffect(() => {
    ccAnalytics.pageview(googleAnalyticsPage);
  }, []);

  const headerText = (<span className="transact-header">
    {isUUID(id) ?
        (uiContext.content.transaction.headerText || 'Track & trace')
        : 'Transaction wasn\'t found'}
  </span>);

  return (
    <div className={`translate-dynamic transaction-page`} styles={{ padding: isMobileDevice ? 20 : 80 }}>
      <Helmet>
        <meta name="robots" content="noindex"/>
      </Helmet>
      <CustomHeader
        customLogo={uiContext.content.logo.light}
        text={headerText}
        isMobileDevice={isMobileDevice}
        logoLink={uiContext.content.logo.link}
        customImageSrc={uiContext.theme.images.hero.main}
        subText={<span className="no-translate subText">{reference ? reference : ""}</span>}
        hidePoweredBy={isCarbonclick || uiContext.hideMentionsOfCarbonClick}
        ccLogo={isCarbonclick}
        hideOverlay={true}
      />

      <main className='pt-5 accent-light-gradient-bg'>
        <section className="content-width p-4 border-light" style={{ position: "relative", borderRadius: 0 }}>
          {!props.transactionDetails && isUUID(id) && <Spin
            className="transact-spinner"
            tip="Loading..."/>}
          <div style={{visibility: isUUID(id) ? props.transactionDetails ? "initial" : "hidden" : 'initial'}}>
            <TransactionDetails
              transactData={props.transactionDetails}
              transactImpact={props.transactionImpact}
              locale={locale}
            />
          </div>
        </section>

        <section className='my-4 content-width bold-family'>
          {!transactionImpact && <Spin style={{ display: 'block', margin: '60px auto' }} tip='Loading projects...' />}
          {transactionImpact?.projects && (
            <ProjectList
              title='Trace your impact'
              projects={transactionImpact && transactionImpact.projects}
              subtitle='This contribution is supporting real projects that make a meaningful difference to the planet and local communities, in all parts of the world. Click below to learn more about the projects and trace the positive impact through their retirement certificates.'
              transactionId={id}
            />
          )}
        </section>

        {!uiContext.hideMentionsOfCarbonClick && <Footer />}
      </main>
    </div>
  )
};

function mapStateToProps(state) {
  return {
    user: state.user,
    transactionDetails: state.paymentDetails,
    transactionImpact: state.impactTransactStats,
    carbonClickStats: state.impactAllStats,
    impactMerchantStats: state.impactMerchantStats,
    currentUserCognito: state.currentUserCognito,
    geoLocationUser: state.countryResult,
    cognitoGuest: state.cognitoGuest,
    subsList: state.subsList
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getImpactTransaction: bindActionCreators(
        impactTransactionActions.getImpactTransaction,
        dispatch
      ),
      getTransactionById: bindActionCreators(
        paymentActions.getPurchaseById,
        dispatch
      ),
      loadCarbonClickImpact: bindActionCreators(
        impactAllActions.getAllImpact,
        dispatch
      ),
      getSubscriptionsByEmail: bindActionCreators(
        subscriptionActions.getSubscriptionsByEmail,
        dispatch
      ),
      getMerchantImpact: bindActionCreators(
        impactMerchantActions.getMerchantImpact,
        dispatch
      )
    }
  };
}

TransactionPage.propTypes = {
  transactionDetails: PropTypes.object,
  actions: PropTypes.object,
  carbonClickStats: PropTypes.object,
  isMobileDevice: PropTypes.bool,
  currentUserCognito: PropTypes.object,
  cognitoGuest: PropTypes.object,
  'cognitoGuest.isSignedIn': PropTypes.bool,
  geoLocationUser: PropTypes.object,
  transactionImpact: PropTypes.object
};


export default connect(mapStateToProps, mapDispatchToProps)(TransactionPage);
