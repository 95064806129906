import merge from "deepmerge";
import airlinesBase from "./airlinesBase";

const overwriteMerge = (destinationArray, sourceArray) => {
    return sourceArray;
}

const overrides = {
    code:'BES_NZ_CLIMATE_FRIENDLY_TRAVELLER',
    isAirlinesUser: true,
    content: {
        menu: {
            url: 'https://traveller.carbon.click/',
        },
        dashboard: {
            business: {
                items: ['content', 'supportedProjectsPanel'],
            },
        },
    }
}

export default merge(airlinesBase, overrides, { arrayMerge: overwriteMerge });
