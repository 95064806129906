import React, { useState } from 'react';
import SocialLoginButton from "../common/SocialLoginButton";
import PropTypes from "prop-types";
import { getConfiguredAmplify } from './authentication';

const SocialLoginView = ({showFacebook = true, showGoogle = true}) => {

  const [loadingProvider, setLoadingProvider] = useState(null);

  const doSignIn = async (provider) => {
    setLoadingProvider(provider)
    getConfiguredAmplify().then(Amplify => {
      return Amplify.Auth.federatedSignIn({ provider })
    }).catch(() => setLoadingProvider(null))
  };
  return (
    <>
      {showFacebook &&  <div className="w-100">
        <SocialLoginButton
          onClickButton={() => doSignIn("Facebook")}
          buttonName="Continue with Facebook"
          type="primary"
          buttonColor="#357BB7"
          socialType="facebook"
          loading={loadingProvider === "Facebook"}
        />
      </div> }
      {showGoogle && <div className="w-100">
        <SocialLoginButton
          onClickButton={() => doSignIn("Google")}
          buttonName="Continue with Google"
          type="primary"
          buttonColor="#DA5350"
          socialType="google"
          loading={loadingProvider === "Google"}
        />
      </div>
      }
    </>
  );
};

SocialLoginView.propTypes = {
  showFacebook: PropTypes.bool,
  showGoogle: PropTypes.bool
};

export default SocialLoginView;
