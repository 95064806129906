import {useContext} from 'react';
import UserInfoContext from "./UserInfoContext";
import {generatePath} from "react-router-dom";
import {BASE_PATH_WITH_MERCHANT_CODE} from "../components/common/pages";

function generatePathWithCurrentMerchantCodeAsBase(subPath,params) {
    const {userInfo} = useContext(UserInfoContext);

    let merchantCodeToAddToPath = userInfo?.referrerMerchant?.code;
    return generatePath(BASE_PATH_WITH_MERCHANT_CODE+subPath,{referrerMerchantCodeInPath: merchantCodeToAddToPath, ... params})
}

export default generatePathWithCurrentMerchantCodeAsBase;