export default
{
    code:'MYC_PW_SAPPHIRE_TRAVELLER',
    content: {
        menu: {
            url: 'https://sapphire.carbon.click/',
        },
        dashboard: {
            business: {
                merchantContent: {
                    items: [
                        { title: "Why we need to care for our oceans", image: "https://res.cloudinary.com/carbonclick/image/upload/v1638312193/White%20Label/Sapphire%20Traveller/sapphire_h6oajf.png", link: "https://www.carbonclick.com/wp-content/uploads/2021/02/CarbonClick-Consumer-Attitudes-to-Sustainability.pdf", text: "As well as being home to a huge portion of the planet’s life, the ocean sequesters a large proportion of the world CO2. This is known as Blue Carbon. ", contentText: ""},
                        { title: "Understand your boat’s impact", buttonText: "Get the Guide", link: "https://www.carbonclick.com/wp-content/uploads/2021/06/A4-24-merged-compressed.pdf", image: "https://res.cloudinary.com/carbonclick/image/upload/v1638312193/White%20Label/Sapphire%20Traveller/sapphire_h6oajf.png", text: "Learn more about the carbon footprint of boating.<br/><br/>Find out how to use and maintain and use your boat in ways that can reduce your emissions on the water.", contentText: "" },
                        { title: "Calculate your boat’s emissions", image: "https://res.cloudinary.com/carbonclick/image/upload/v1638312193/White%20Label/Sapphire%20Traveller/sapphire_h6oajf.png", buttonText: "Get Started", link: "/my-cc/business-calculator", text: "Our calculator builds an overview of your boating emissions based on fuel use.<br/><br/>Find out the size of your footprint, offset it and lower your impact on the ocean", contentText: ""},
                        { title: "Share the good you are doing", buttonText: "Get the Onboarding Kit", image: "https://res.cloudinary.com/carbonclick/image/upload/v1638312193/White%20Label/Sapphire%20Traveller/sapphire_h6oajf.png", link: "https://www.carbonclick.com/climate-warrior-onboarding-kit/",  text: "Start telling your climate action story.<br/><br/>Find resources for social, posts, website badges and blurbs to help you communicate the good you are doing.", contentText: ""}
                    ]
                },
            }
        }
    }
}