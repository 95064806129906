import initialState from "./initialState";
import * as types from "../actions/actionTypes";

export default function basketReducer(state =  initialState.basket , action) {
    switch (action.type) {
        case types.GET_BASKET_SUCCESS:
            return action.basket;
        case types.GET_BASKET_FAILED:
            return action.error;
        default:
            return state;
    }
}
