/* eslint-disable */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import Constants from './constants';
export default function withGoogle(Comp) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(props) {
            var _this = _super.call(this, props) || this;
            _this.initGapi = _this.initGapi.bind(_this);
            _this.signIn = _this.signIn.bind(_this);
            _this.signOut = _this.signOut.bind(_this);
            _this.federatedSignIn = _this.federatedSignIn.bind(_this);
            _this.state = {};
            return _this;
        }
        class_1.prototype.signIn = function () {
            var _this = this;
            var ga = window.gapi.auth2.getAuthInstance();
            var onError = this.props.onError;
            ga.signIn().then(function (googleUser) {
                _this.federatedSignIn(googleUser);
                var payload = {
                    provider: Constants.GOOGLE,
                };
                try {
                    localStorage.setItem(Constants.AUTH_SOURCE_KEY, JSON.stringify(payload));
                }
                catch (e) {
                    console.debug('Failed to cache auth source into localStorage', e);
                }
            }, function (error) {
                if (onError)
                    onError(error);
                else
                    throw error;
            });
        };
        class_1.prototype.federatedSignIn = async function (googleUser) {
            const { Auth } = await import("aws-amplify");
            return __awaiter(this, void 0, void 0, function () {
                var _a, id_token, expires_at, profile, user, onStateChange;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = googleUser.getAuthResponse(), id_token = _a.id_token, expires_at = _a.expires_at;
                            profile = googleUser.getBasicProfile();
                            user = {
                                email: profile.getEmail(),
                                name: profile.getName(),
                                picture: profile.getImageUrl(),
                            };
                            onStateChange = this.props.onStateChange;
                            if (!Auth ||
                              typeof Auth.federatedSignIn !== 'function' ||
                              typeof Auth.currentAuthenticatedUser !== 'function') {
                                throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
                            }
                            return [4 /*yield*/, Auth.federatedSignIn('google', { token: id_token, expires_at: expires_at }, user)];
                        case 1:
                            _b.sent();
                            return [4 /*yield*/, Auth.currentAuthenticatedUser()];
                        case 2:
                            user = _b.sent();
                            if (onStateChange) {
                                onStateChange('signedIn', user);
                            }
                            return [2 /*return*/];
                    }
                });
            });
        };
        class_1.prototype.signOut = function () {
            var authInstance = window.gapi && window.gapi.auth2
                ? window.gapi.auth2.getAuthInstance()
                : null;
            if (!authInstance) {
                return Promise.resolve();
            }
            authInstance.then(function (googleAuth) {
                if (!googleAuth) {
                    console.debug('google Auth undefined');
                    return Promise.resolve();
                }
                console.debug('google signing out');
                return googleAuth.signOut();
            });
        };
        class_1.prototype.componentDidMount = function () {
            var google_client_id = this.props.google_client_id;
            var ga = window.gapi && window.gapi.auth2
                ? window.gapi.auth2.getAuthInstance()
                : null;
            if (google_client_id && !ga)
                this.createScript();
        };
        class_1.prototype.createScript = function () {
            var script = document.createElement('script');
            script.src = 'https://apis.google.com/js/platform.js';
            script.async = true;
            script.onload = this.initGapi;
            document.body.appendChild(script);
        };
        class_1.prototype.initGapi = function () {
            console.debug('init gapi');
            var that = this;
            var google_client_id = this.props.google_client_id;
            var g = window.gapi;
            g.load('auth2', function () {
                g.auth2.init({
                    client_id: google_client_id,
                    scope: 'profile email openid',
                });
            });
        };
        const WithGoogle = function () {
            var ga = window.gapi && window.gapi.auth2
              ? window.gapi.auth2.getAuthInstance()
              : null;
            return (React.createElement(Comp, __assign({}, this.props, { ga: ga, googleSignIn: this.signIn, googleSignOut: this.signOut })));
        };
        class_1.prototype.render = WithGoogle;
        return class_1;
    }(React.Component));
}