import * as React from 'react';

import withGoogle from './withGoogle';
import withFacebook from './withFacebook';
import withAmazon from './withAmazon';
import withOAuth from './withOAuth';
import withAuth0 from './withAuth0';

var __extends = (this && this.__extends) || (function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf ||
      ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
      function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
})();
var __assign = (this && this.__assign) || function () {
  __assign = Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
        t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
export function withFederated(Comp) {
  var Federated = withAuth0(withOAuth(withAmazon(withGoogle(withFacebook(Comp)))));
  return /** @class */ (function (_super) {
    __extends(class_1, _super);
    function class_1() {
      return _super !== null && _super.apply(this, arguments) || this;
    }
    class_1.prototype.render = function () {
      // @ts-ignore
      var federated = this.props.federated || {};
      return React.createElement(Federated, __assign({}, this.props, federated));
    };
    return class_1;
  }(React.Component));
}
