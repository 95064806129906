import React, { useEffect, lazy } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
const SignInPage = lazy(() => import(/* webpackChunkName: "sign-in-page", webpackMode: "eager" */'../security/SignInPage').catch(reloadPage));
import * as link from "../common/pages";
import { useHistory } from "react-router-dom";
import * as ccAnalytics from "../../util/analytics/ccAnalytics";
import { Spin } from "antd";
import {reloadPage} from "../App";

const SignIn = ({ ...props }) => {

  const { currentUserCognito } = props;

  const history = useHistory()

  useEffect(() => {
    const fromUri = new URLSearchParams(location.search).get("from");
    if(fromUri) sessionStorage.setItem("from_uri",new URLSearchParams(location.search).get("from"));
  }, [])

  useEffect(() => {
    const cognitoUserData = currentUserCognito && currentUserCognito.authData
    const { email, name, sub } = cognitoUserData ? cognitoUserData : "NA";
    ccAnalytics.set({ userId: sub });

    if (currentUserCognito && currentUserCognito.isSignedIn && name && email && sub) {
      goToDashboard();
    }
  }, [currentUserCognito])

  const goToDashboard = () => {
    history.push({
      search: history.location.search, // so that app doesn't loose any query parameters when going to dashboard
      pathname: link.DASHBOARD
    });
    }

  return (
    <>
      <>
        <Helmet>
          <title>Home | My CarbonClick</title>
        </Helmet>
        {(currentUserCognito && currentUserCognito.error) || currentUserCognito === null ? 
          <div className='old-styles'><SignInPage /></div> 
        :
        <div className="entry-spinner"><Spin tip="Loading..."></Spin></div>}
      </>
    </>
  );
}

SignIn.propTypes = {
  props: PropTypes.object,
  currentUserCognito: PropTypes.object,
  actions: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  loading: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    loading: state.apiCallsInProgress > 0,
    currentUserCognito: state.currentUserCognito
  };
}

export default React.memo(connect(mapStateToProps)(SignIn));
