import * as types from "./actionTypes";
import * as config from "../../../config";
const {
  makeStripePaymentApi,
  updateEmailPurchaseApi,
  getPurchasesByUserEmailApi,
  getRecentPurchasesByUserEmailApi,
  getPurchaseByIdApi,
  getGroupSubscriptionPurchasesByMerchantCodeApi
} = require("@carbonclick/carbon-api-client/src/api/paymentApi");
import { extractApiError } from '../../util/errorExtractor'
import { beginApiCall } from "./apiStatusActions";
import { errorHandlerActions } from './errorHandlerActions';

export function makePaymentSuccess(payment) {
  return { type: types.PAYMENT_SUCCESS, payment };
}

export function updatePaymentEmailSuccess(update_payment) {
  return { type: types.UPDATE_PAYMENT_EMAIL_SUCCESS, update_payment };
}

export function makePaymentFailed(error) {
  return { type: types.PAYMENT_FAILED, error };
}

export function updatePaymentEmailFailed(error) {
  return { type: types.UPDATE_PAYMENT_EMAIL_FAILED, error };
}

export function resetPayment(val) {
  return { type: types.RESET_PAYMENT, val };
}

export function getPurchasesSuccess(purhases) {
  return { type: types.GET_PURCHASES_BY_USER_SUCCESS, purhases}
}

export function getPurchasesFailed(error) {
  return { type: types.GET_PURCHASES_BY_USER_FAILED, error}
}

export function getPurchaseByIdSuccess(purchase) {
  return { type: types.GET_PURCHASE_BY_ID_SUCCESS, purchase}
}

export function getPurchaseByIdFailed(error) {
  return { type: types.GET_PURCHASE_BY_ID_FAILED, error}
}

export function getGroupSubscriptionPurchaseByMerchantCodeSuccess(groupSubscriptionPurchases) {
  return { type: types.GET_GROUP_PURCHASES_BY_MERCHANT_CODE_SUCCESS, groupSubscriptionPurchases }
}

export function getGroupSubscriptionPurchaseByMerchantCodeFailed(error) {
  return { type: types.GET_GROUP_PURCHASES_BY_MERCHANT_CODE_FAILED, error }
}

export function makePayment(payment_data) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return makeStripePaymentApi(payment_data)
      .then(payment_response => {
        dispatch(makePaymentSuccess(payment_response));
      })
      .catch(error => {
        const status = extractApiError(error).status;
        const sentryMessage = `${config.deployment_env} paymentActions.js : post purchase api call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(makePaymentFailed(error));
      });
  };
}

export function updatePaymentEmail(email_data) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return updateEmailPurchaseApi(email_data)
      .then(response => {
        dispatch(updatePaymentEmailSuccess(response));
      })
      .catch(error => {
        const status = extractApiError(error).status;
        const sentryMessage = `${config.deployment_env} paymentActions.js : patch purchase api call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(updatePaymentEmailFailed(error));
      });
  };
}

export function getPurchasesByEmail(email) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return getPurchasesByUserEmailApi(email)
      .then(response => {
        dispatch(getPurchasesSuccess(response))
      })
      .catch(error => {
        const status = extractApiError(error).status;
        const sentryMessage = `${config.deployment_env} paymentActions.js : get purchase api call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(getPurchasesFailed(error));
      });
  }
}

export function getGroupSubscriptionPurchasesByMerchantCode(merchantCode) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return getGroupSubscriptionPurchasesByMerchantCodeApi(merchantCode)
      .then(response => {
        dispatch(getGroupSubscriptionPurchaseByMerchantCodeSuccess(response))
      })
      .catch(error => {
        const status = extractApiError(error).status;
        const sentryMessage = `${config.deployment_env} paymentActions.js : get subscription purchase api call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(getGroupSubscriptionPurchaseByMerchantCodeFailed(error));
      });
  }
}

export function getPurchaseById(id) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return getPurchaseByIdApi(id)
      .then(response => {
        dispatch(getPurchaseByIdSuccess(response))
      })
      .catch(error => {
        const status = extractApiError(error).status;
        const sentryMessage = `${config.deployment_env} paymentActions.js : get purchase api call error for id: ${id} . ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(getPurchaseByIdFailed(error));
      });
  }
}


export function getRecentPurchasesByEmail(email) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return getRecentPurchasesByUserEmailApi(email)
      .then(response => {
        dispatch(getPurchasesSuccess(response))
      })
      .catch(error => {
        const status = extractApiError(error).status;
        const sentryMessage = `${config.deployment_env} paymentActions.js : get purchase api call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(getPurchasesFailed(error));
      });
  }
}
export function doResetPayment() {
  return function (dispatch) {
    dispatch(resetPayment([]));
  };
}
