
export default {
    code:'XXX_BES_GB_FOREST_CARBON_XXX',
    currencies:['GBP'],
    baskets: {
        includePublicBaskets: false,
        businessCodes: ['FC_UK_CSR','FC_INTERNATIONAL'],
        individualCodes: ['FC_UK_CSR','FC_INTERNATIONAL'],
    },
    siteName:'Carbon Club',
    content: {
        menu: {
            business: {
                items: ['dashboard', 'businessOffsetPlans','myAccount', 'projects',]
            },
            individual: {
                items: ['dashboard', 'myFootprint', 'myAccount', 'projects']
            },
            url: 'https://forest.carbon.click/',
            signOutUrl: null
        },
        dashboard: {
            business: {
                items: ['content', 'recentOffsets', 'supportedProjectsPanel'],
                merchantContent: {
                    items: [
                        {
                            title: "Subscribe to a Carbon Club plan",
                            image: "https://res.cloudinary.com/carbonclick/image/upload/w_600,o_60/v1638481239/White%20Label/Forest%20Carbon/header1_gzfx0n.jpg",
                            text: "Club membership makes it simple - just choose from one of our pre-set footprint levels, based on your business activity, and let us do the rest.",
                            link: "/RM-BES_GB_FOREST_CARBON/my-cc/business-offset-plans?business",
                            buttonText: "See Plans"
                        },
                        {
                            title: "Carbon Offsetting - the basics",
                            image: "https://res.cloudinary.com/carbonclick/image/upload/w_600,o_60/v1638481239/White%20Label/Forest%20Carbon/header1_gzfx0n.jpg",
                            text: "Our introduction to how carbon markets and carbon offsetting work, and where to next after the Glasgow COP meeting in 2021.",
                            link: "https://www.forestcarbon.co.uk/knowledge-base/carbon-markets",
                            buttonText: "Learn more"
                        },
                        {
                            title: "How to communicate your carbon and environmental contributions",
                            image: "https://res.cloudinary.com/carbonclick/image/upload/w_600,o_60/v1638481239/White%20Label/Forest%20Carbon/header1_gzfx0n.jpg",
                            text: "You’ve made the decision, done the research, assessed your footprint and made the investment. Make sure you communicate it in the right way.",
                            link: "https://www.forestcarbon.co.uk/storage/app/media/Marketing Messaging for Forest Carbon Partners.pdf",
                            buttonText: "Learn more"
                        },
                        {
                            title: "Quality Assurance in the carbon market",
                            image: "https://res.cloudinary.com/carbonclick/image/upload/w_600,o_60/v1638481239/White%20Label/Forest%20Carbon/header1_gzfx0n.jpg",
                            text: "Why it’s important, how it works, and how you know a project is certified.",
                            link: "https://www.forestcarbon.co.uk/storage/app/media/uploaded-files/Quality%20Assurance%20in%20the%20Carbon%20Market.pdf",
                            buttonText: "Learn more"
                        },
                    ]
                },
            },
            individual: {
                items: ['defaultHeader', 'badges', 'calculateFootprintBanner', 'recentOffsets', 'reduceActions', 'groupsImpact', 'supportedProjectsPanel']
            }
        },
        individualSubscribePlans: {
            paymentSuccess: {
                items: [],
            },
            items: [],
        },
        transaction: {
            headerText: 'Congratulations! You\'ve made a difference. Here are the details:',
            items: ['createAccount']
        },
        businessOffsetPlans: {
            headerText: 'Mitigate your business emissions',
            headerDescription: 'Contributing to our high quality projects is a powerful way to neutralise your carbon footprint. Select a plan below to start making a difference.',
            headerImage: 'https://res.cloudinary.com/carbonclick/image/upload/c_scale,e_brightness:-48,h_787/v1608003033/Projects/DoeMountain/doe_mountain_3_ipptni.jpg',
        },
        singleProject: {
            items: ['sustainableDevelopmentGoals']
        },
        projects: {
            headerText: 'Carbon Club Projects',
            descriptionHeaderText: 'Carbon Club\'s blended basket approach',
            descriptionContent: `
                <p>Each of the baskets below offers Carbon Club members carbon credits from a range of projects and allows us to effectively spread our support around more than one project.</p>
                <p>All projects - UK and international - are certified under one of the leading independent carbon project standards.</p>
                <p>The 'Supporting UK Nature' basket is for CSR driven investment, whereas the International 'Nature Based Projects' can be used for offsetting purposes.</p>
            `,
        },
        logo: {
            link: 'https://forest.carbon.click'
        },
        signIn: {
            business: {
                headerText: "Grow your sustainability journey with the Carbon Club",
                formHeaderText: "Sign up to the Carbon Club",
                backgroundImage: 'url("https://res.cloudinary.com/carbonclick/image/upload/e_colorize:60,co_rgb:69823f/v1638487337/White%20Label/Forest%20Carbon/Woodlands_WCoshievilleDH_084H_tj8vo4.jpg")',
                items: ["highlights"],
            },
            individual: {
                backgroundImage: 'url(\'https://res.cloudinary.com/carbonclick/image/upload/e_colorize:60,co_rgb:69823f/v1638487337/White%20Label/Forest%20Carbon/Woodlands_WCoshievilleDH_084H_tj8vo4.jpg\')',
            }
        },
        subscriptionPlans: {
            business: {
                planTemplate: 'forest-carbon-plan-template',
                items: [
                    {
                        numberOfTonnes: 25,
                        descriptionData: {
                            numberOfEmployees: 10,
                            numberOfTrees: 100,
                            numberOfMiles: '20,000',
                            businessExpenditure: '£25k',
                            src: 'https://res.cloudinary.com/carbonclick/image/upload/v1638311894/White%20Label/Forest%20Carbon/plan-25_dlorny.png',
                        },
                        order: ['header', 'image', 'description', 'button']
                    },
                    {
                        numberOfTonnes: 50,
                        descriptionData: {
                            numberOfEmployees: 20,
                            numberOfTrees: 200,
                            numberOfMiles: '40,000',
                            businessExpenditure: '£50k',
                            src: 'https://res.cloudinary.com/carbonclick/image/upload/v1638311894/White%20Label/Forest%20Carbon/plan-50_ixe7e1.png',
                        },
                        order: ['header', 'image', 'description', 'button']
                    },
                    {
                        numberOfTonnes: 100,
                        descriptionData: {
                            numberOfEmployees: 40,
                            numberOfTrees: 400,
                            numberOfMiles: '100,000',
                            businessExpenditure: '£75k',
                            src: 'https://res.cloudinary.com/carbonclick/image/upload/v1638311894/White%20Label/Forest%20Carbon/plan-100_ilcqqb.png',
                        },
                        order: ['header', 'image', 'description', 'button']
                    },
                ]
            }
        },
    },

    theme: {
        images: {
            hero: {
                main: 'url(\'https://res.cloudinary.com/carbonclick/image/upload/e_colorize:60,co_rgb:69823f/v1638487337/White%20Label/Forest%20Carbon/Woodlands_WCoshievilleDH_084H_tj8vo4.jpg\')',
            }
        }
    },
}
