import React, {lazy, useContext, useEffect, useState} from "react";
import {Select} from "antd";
import PropTypes from "prop-types";
import {useMediaQuery} from "react-responsive";
import * as countryUtil from "../common/impact/data/CountryData";
import * as link from "../common/pages";
import {connect} from "react-redux";
import merge from 'deepmerge'; //much smaller than lodash

import * as planActions from "../../redux/actions/planActions";
import {bindActionCreators} from "redux";
import {isMobile} from "../common/viewBreakpoints";
import {Route, useHistory, useLocation} from "react-router-dom";
import {getCountryName} from "../../util/countryCodes";
import * as ccAnalytics from "../../util/analytics/ccAnalytics";
import * as impactAllActions from "../../redux/actions/impactAllActions";

import {staticPlanData} from "./plansData";
import {convertToTonne} from "../../util/unitConverter";
import {reloadPage} from "../App";
import generatePathWithCurrentMerchantCodeAsBase from "../../util/generatePathWithCurrentMerchantCodeAsBase";
import UIContext from "../../util/UIContext";
import UserInfoContext from "../../util/UserInfoContext";
import * as basketActions from "../../redux/actions/basketActions";
import {getBasketCodes} from "../../util/uiConfigs/merchantBasketConfigUtil";
import PageHeader from "../PageHeader";
import ProjectList from "../common/projects/ProjectList";
import ImpactMetric from "../common/impact/ImpactMetric";
import { mycc_base_url } from "../../../config";
import CountrySelectionStatus from "../common/CountrySelectionStatus";

const PlansList = lazy(() => import("./PlansList").catch(reloadPage));

const Payment = lazy(() => import("../payment/SubscriptionPayment").catch(reloadPage));
const LookUpCountry = lazy(() => import("../common/CountrySelectionModal").catch(reloadPage));

const SubscribeGuest = ({ match, ...props }) => {
  const [selectedBasket, setSelectedBasket] = useState();

  
  const { baskets, actions } = props;

  const uiContext = useContext(UIContext);
  const {userInfo} = useContext(UserInfoContext);

  const { geoLocationUser, carbonClickStats,currentUserCognito, cognitoGuest, basket, apiPlanData } = props;
  const [currency, setCurrency] = useState("");
  const [planData, setPlanData] = useState();
  const [merchantBasket, setMerchantBasket] = useState(null);
  const [allowedBaskets, setAllowedBaskets] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [email, setEmail] = useState("");
  const [isShowModal, setShowModal] = useState(false)
  const [country, setCountry] = useState("");
  const isMobileDevice = isMobile(useMediaQuery);
  const location = useLocation();
  const history = useHistory();

  const urlParams = new URLSearchParams(location.search);
  const recommendedPricingLevel = parseInt(urlParams.get("recommendedPricingLevel"));
  const countryInURLParam = urlParams.get("country");
  const carbonAmountTonnesParam = urlParams.get("carbonAmountTonnes");

  const isMyCCDashboard = location.pathname.includes('/my-cc');

  useEffect(() => {
    if(!baskets && (currentUserCognito?.isSignedIn || cognitoGuest?.isSignedIn)) {
      if(getBasketCodes(uiContext, false)?.length > 0) {
        actions.getBaskets(getBasketCodes(uiContext, false), uiContext.baskets.includePublicBaskets, selectedBasket && selectedBasket?.code);
      } else {
        actions.getBaskets()
      }
    }
  }, [baskets, currentUserCognito, cognitoGuest, uiContext, selectedBasket])

  useEffect(() => {
    if (country && (currentUserCognito?.isSignedIn || cognitoGuest?.isSignedIn)) {
      props.actions.loadCarbonClickImpact(`en_${country}`);
    }
  }, [country,currentUserCognito,cognitoGuest]);

  useEffect(() => {
    if (planData && planData[0]?.currentBasket && (currentUserCognito?.isSignedIn || cognitoGuest?.isSignedIn)) {
      props.actions.getBasketById(planData[0]?.currentBasket?.id);
    }
  }, [planData,currentUserCognito,cognitoGuest]);

  useEffect(() => {
    if(basket && baskets && (baskets?.content || baskets).filter(b => b.id === basket?.id).length === 0) {
      setMerchantBasket(basket);
      if (!selectedBasket) {
        setSelectedBasket(basket);
      }
    }
  }, [basket, baskets, selectedBasket])

  useEffect(() => {
    if(merchantBasket) {
      setAllowedBaskets([...(baskets?.content || baskets), merchantBasket]);
    } else {
      setAllowedBaskets((baskets?.content || baskets));
    }
  }, [baskets, merchantBasket])

  useEffect(() => {
      const combinedData = [];
      if (!apiPlanData || apiPlanData.length === 0 || apiPlanData.error) {
        return staticPlanData;
      } else {
        staticPlanData.forEach((staticPlan) => {
          const apiPlan = apiPlanData.find((a) => a.pricingLevel === staticPlan.pricingLevel);
          if (apiPlan) {
            combinedData.push(merge(staticPlan,apiPlan));
          }
        });

        setPlanData(combinedData);
        setCurrency(combinedData && combinedData[0] && combinedData[0].fee && combinedData[0].fee.price.currency);
    }
  }, [apiPlanData]);

  useEffect(() => {
    props.actions.resetPlans();
    if (countryInURLParam?.length === 2 && countryUtil.isValidCountry(countryInURLParam)) {
      setCountry(countryInURLParam.toLowerCase());
    }
    else if (geoLocationUser && geoLocationUser.country) {
      setCountry(geoLocationUser.country)
    }
    else {
      setCountry("us")
    }
  }, [countryInURLParam, location.search.length, geoLocationUser, selectedBasket]);

  useEffect(() => {
    if (isShowModal) { //on modal open
      ccAnalytics.event({ category: 'countryselect', action: 'modal', label: 'open' });
    }
  }, [isShowModal])

  //this tracks both the initial page and the payment page as this is the parent compornent of both. Adding trailing '/' to keep this the same as it used to be for consistency in GA 
  useEffect(() => {
    ccAnalytics.pageview(location.pathname+ "/")
  }, [location]
  );

  useEffect(() => {
    if (country && userInfo?.currentMerchant?.code && (props.currentUserCognito && props.currentUserCognito.isSignedIn || props.cognitoGuest && props.cognitoGuest.isSignedIn)) {
      props.actions.loadPlans(country,userInfo.currentMerchant?.code, selectedBasket?.code || (getBasketCodes(uiContext, false).length > 0 && getBasketCodes(uiContext, false)[0]));
    }
  }, [props.cognitoGuest, props.currentUserCognito, country, userInfo, selectedBasket]);

  useEffect(() => {
    if(history.action === "POP" && isPaymentPage()) {
      let planWithRecommendedPricingLevel = planData?.find(p => p.pricingLevel === recommendedPricingLevel);
      if (planWithRecommendedPricingLevel) {
        setSelectedPlan(planWithRecommendedPricingLevel);
      } else {
        window.location = link.SUBSCRIBE_GUEST;
      }
    }
  }, [planData])

  const isPaymentPage = () => {
    return /.*payment/i.test(location.pathname);
  };

  let subscribeSuccessPath = generatePathWithCurrentMerchantCodeAsBase(`${link.SUBSCRIPTION_SUCCESS_PAGE}`);
  const handleSubscribeSuccess = (createSubscriptionResponse) => {

    ccAnalytics.event({
      category: 'subsciption-success',
      action: 'subscription-created',
      label: 'subscribe-guest'
    });
    history.push({
      pathname: subscribeSuccessPath ,
      state: {
        successSubscribePayment: true,
        subsResponse: createSubscriptionResponse,
        emailAddress: email
      }
    });
  };

  const planChosen = () => {
    if (isMyCCDashboard) {
      history.push(match.url + "/subscribe/payment");
    } else {
      history.push(match.url + '/payment');
    }
  };

  const locationText = location.pathname !== `${match.path}/payment` ? (
    <span>
      Looks like you are in
      <b> {getCountryName(country || "nz")} </b>
      where the average carbon footprint is <b>{countryUtil.getDataByCountryCode(country || "nz")?.footprint.toFixed(2)} </b>
      tonnes per year. <a onClick={() => setShowModal(true)} className='color-white underline no-WL'>Change location</a>
    </span>
  ) : (<>
    {!!isMobileDevice && selectedPlan.currentCarbonOffsetQuantity &&
      <div style={{ fontWeight: '600', margin: '20px 0px' }}>Offset about {convertToTonne(selectedPlan.currentCarbonOffsetQuantity.value * 12, selectedPlan.currentCarbonOffsetQuantity.unit).toFixed(0)} Tonnes of CO2 each year</div>
    }

    {!isMobileDevice &&
      <span style={{ fontSize: '16px', border: '1px solid green' }}>
        {selectedPlan?.fee?.price &&
          <span>{selectedPlan.fee.price.value} {selectedPlan.fee.price.currency}</span>
        } /month. Cancel at any time.
  </span>
    }

  </>);

  const CarbonAmountTonnes = carbonAmountTonnesParam && (
    <p className="text-center m-0">
      Your personal carbon footprint is <span className='font-bold'>{carbonAmountTonnesParam}</span> tonnes per
      year.
    </p>
  );

  return (
    <main className="cc-main-content">
      <Route exact path={`${match.path}${isMyCCDashboard ? '/subscribe' : ''}`}>
        <PageHeader title={isPaymentPage() ? selectedPlan.desc : uiContext.content.individualSubscribePlans.header.text} />

        <div className='d-column gap-6 px-3 pt-4'>
          <section className={`px-3 py-0 d-column gap-3 content-width-lg ${isMyCCDashboard ? 'mx-initial' : ''}`}>
          {CarbonAmountTonnes}

          <div>
            <p className="m-0 d-inline">Not sure which plan to choose? The average annual carbon footprint in </p>
            <CountrySelectionStatus
              country={country || 'nz'}
              hideMessage
              setShowModal={setShowModal}
              showInline
            />
            <p className="m-0 d-inline"> is {countryUtil.getDataByCountryCode(country || "nz")?.footprint.toFixed(2)} tonnes of CO₂. You can calculate your personal carbon footprint{' '}</p>
            <a
              href={mycc_base_url + generatePathWithCurrentMerchantCodeAsBase(link.DASHBOARD+link.CALCULATOR)}
              rel="noopener noreferrer"
              target="_blank"
            >
              here
            </a>.
          </div>

              {country && <PlansList
                  selectedPlan={selectedPlan}
                  onSelectedPlanChange={setSelectedPlan}
                  planChosen={planChosen}
                  planData={planData}
                  currency={currency}
                  country={country}
                  setCountry={setCountry}
                  setLocationModalVisible={setShowModal}
                  recommendedPricingLevel={recommendedPricingLevel}
                />
              }

              <p className="m-0 color-grey-md text-center" style={{ fontSize: 14 }}>
                The cost of carbon credits changes over time, so your monthly CO₂ offset may vary. You can check out how much 
                exactly you are offsetting each month with a free 
                <a href={mycc_base_url} target='_blank' rel="noopener noreferrer">{' '}MyCarbonClick{' '}</a>account.
              </p>
            </section>

                <section className={`px-3 py-0 content-width-lg ${isMyCCDashboard ? 'mx-initial' : ''}`}>
                  <div className="d-column gap-2">
                    <h4 className="m-0 text-center"><b>Where do your contributions go?</b></h4>
                    <p className="m-0 text-center">We support only the highest quality carbon projects around the world, to bring you peace of mind and results. Choose below which basket of projects you would like to contribute to.</p>
                      {allowedBaskets?.length > 1
                        ? <Select className='mx-auto' style={{ maxWidth: 420, width: '100%' }}
                            onChange={(e, value) => setSelectedBasket(allowedBaskets.filter(v => v.code === value.value)[0])}
                            value={allowedBaskets?.filter(v => v.code === (selectedBasket?.code || basket?.code))[0]?.displayName}
                            defaultValue={basket?.displayName}
                          >
                            {allowedBaskets && allowedBaskets.map(item => (
                                <Select.Option value={item.code} key={item.code}>{item.displayName}</Select.Option>
                            ))}
                          </Select>
                        : <div style={{fontWeight: 'bold'}} className="py-2 basket-select">{baskets && (baskets.content || baskets)[0]?.displayName}</div>}

                  {(selectedBasket || basket)?.projects && (
                    <ProjectList
                      projects={(selectedBasket || basket)?.projects}
                      hideImpactButton
                    />
                  )}
                  </div>

                </section>

            {uiContext.content.individualSubscribePlans.items.includes('impactPanel') && (<>
              {carbonClickStats && (
                <div style={{ background: 'var(--accent-light-color)' }}>
                  <section className={`container-lg ${isMyCCDashboard ? 'mx-initial' : 'mx-auto'}`}>
                    <div className='container g-16 d-column align-center col-12 py-4 px-2 text-center'>
                      <h4 className='font-bold m-0'>
                        Together we’ve offset{' '}
                        {carbonClickStats?.carbonOffsetImpact?.value ? Math.round(carbonClickStats?.carbonOffsetImpact?.value)?.toLocaleString() : '-'}{' '}
                        {carbonClickStats?.carbonOffsetImpact?.unit?.toLowerCase()} of CO₂
                      </h4>
                      <p className='m-0'>
                        That’s the same amount of carbon emissions that{' '}
                        {Math.round(carbonClickStats?.treeGrowthYears?.value)?.toLocaleString()}{' '}
                        trees absorb in one year.
                      </p>
                      <ImpactMetric impact={carbonClickStats}/>
                    </div>
                  </section>
                </div>
              )}
            </>)}
          </div>
              </Route>
              <Route path={`${match.path}${isMyCCDashboard ? '/subscribe' : ''}/payment`}>
                  <PageHeader title={uiContext.content.individualSubscribePlans.payment?.headerText || selectedPlan.desc} />
                <div className={`my-5 py-4 d-flex justify-center`}>
                  <Payment
                      plan={selectedPlan}
                      handleSubscribeSuccess={handleSubscribeSuccess}
                      handleEmail={setEmail}
                      locationText={!isMobileDevice && locationText}
                      preferredBasketCode={selectedBasket ? selectedBasket.code : basket?.code}
                  />
                </div>
              </Route>
      <LookUpCountry
        showModal={isShowModal}
        setLocationModalVisible={setShowModal}
      />
    </main>
  );
};

const mapStateToProps = (state) => {
  return {
    geoLocationUser: state.countryResult,
    apiPlanData: state.plan_list,
    cognitoGuest: state.cognitoGuest,
    currentUserCognito: state.currentUserCognito,
    apiLoading: state.apiCallsInProgress > 0,
    basket: state.basket,
    carbonClickStats: state.impactAllStats,
    baskets: state.baskets,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadCarbonClickImpact: bindActionCreators(
        impactAllActions.getAllImpact,
        dispatch
      ),
      resetPlans: bindActionCreators(
        planActions.resetPlans,
        dispatch
      ),
      loadPlans: bindActionCreators(
        planActions.getPlans,
        dispatch
      ),
      getBasketById: bindActionCreators(
          basketActions.getBasketById,
          dispatch
      ),
      getBaskets: bindActionCreators(
        basketActions.getBaskets,
        dispatch
    ),
    }
  };
}

SubscribeGuest.propTypes = {
  actions: PropTypes.object,
  match: PropTypes.object,
  geoLocationUser: PropTypes.object
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscribeGuest);
