import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function reduceFactsReducer(
  state = initialState.reduceFacts,
  action
) {
  switch (action.type) {
    case types.REDUCE_FACTS_SUCCESS:
      return action.reduceFacts;
    case types.REDUCE_FACTS_FAILED:
      return action.error;
    default:
      return state;
  }
}
