import * as types from "./actionTypes";
import * as config from "../../../config";
import store from "store";
import * as ccAnalytics from "../../util/analytics/ccAnalytics";
const {
  getGeoLocationApi
} = require("@carbonclick/carbon-api-client/src/api/geoLocationApi");

import { extractApiError } from '../../util/errorExtractor'
import { beginApiCall } from "./apiStatusActions";
import * as Sentry from "@sentry/browser";

export function getLocationSuccess(country) {
  return { type: types.GEO_LOCATION_SUCCESS, country };
}

export function getLocationFailed(error) {
  return { type: types.GEO_LOCATION_FAILED, error };
}

export function getCountry() {
  const storedCode = store.get("country_code");

  return function (dispatch) {
    //If a country is provided e.g. urlParam or from the user subscription, then set local storage and return.
    if (storedCode){
      dispatch(getLocationSuccess(storedCode))
    }
    //Last option is to geo locate and return the country via the api.
    else {
      dispatch(beginApiCall());
      getGeoLocationApi()
        .then((geolocation) => {
          if (geolocation && geolocation.country_code2) {
            ccAnalytics.event({ category: 'countryselect', action: 'geolocate', label: geolocation.country_code2 });
            store.set("country_code", geolocation.country_code2.toLowerCase());
            dispatch(getLocationSuccess(geolocation.country_code2));
          } else {
            ccAnalytics.event({ category: 'countryselect', action: 'geolocate', label: 'fail' });
            dispatch(getLocationSuccess('us'));
          }
        })
        .catch((error) => {
          ccAnalytics.event({ category: 'countryselect', action: 'geolocate', label: 'fail' });
          store.set("country_code", 'us');
          if (error.error.message !== "Network Error") {
            Sentry.captureMessage(
              `${config.deployment_env} geoLocationAction.js : geo location api call error ${JSON.stringify(extractApiError(error))}`,
              "fatal"
            );
          }
          dispatch(getLocationSuccess('us'));
        });
    }
  };
}
