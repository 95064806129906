export default
    {
        code:'BES_US_EARTHSHARE',
        content: {
            transaction: {
                headerText: 'Congratulations! You\'ve made a difference. Here are the details:',
                items: ['impactPanel', 'createAccount']
            },
            personalCalculator: {
                items: []
            },
            logo: {
                link: 'https://earthshare.carbon.click/'
            }
        },
       
    }