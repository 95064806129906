import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function getGroupReducer(
  state = initialState.group,
  action
) {
  
  switch (action.type) {
    case types.GET_GROUP:
      return initialState.groupCreated;
    case types.GET_GROUP_SUCCESS:
      return action.group;
    case types.GET_GROUP_FAILED:
      return action.error;
    default:
      return state;
  }
}
