import React, {useContext} from "react";
import { Button } from "antd";
import PropTypes from "prop-types";
import UIContext from "../../util/UIContext";

const CCButton = ({
  onClickButton,
  buttonName,
  isDisabled,
  type = "primary",
  size,
  loading,
  block,
  danger,
  backgroundColor,
  className,
  textColor,
  icon,
}) => {
  const uiContext = useContext(UIContext);

  const primaryColor = isDisabled ? "#B5BABD" : uiContext.theme.palette.primary.main;
  const secondaryColor = "white";
  const isPrimary = type === "primary";
  let fontColor = isPrimary ? secondaryColor : primaryColor;
  let buttonColor = isPrimary ? primaryColor : secondaryColor;
  let border = isPrimary ? "none" : ("1px solid " + primaryColor)
  return (
    <Button
      size={size ? size : "large"}
      onClick={onClickButton}
      disabled={isDisabled}
      loading={loading}
      danger={danger}
      type={type}
      block={block}
      className={className}
      style={{
        backgroundColor: backgroundColor || buttonColor,
        border: border,
        borderRadius: "0"
      }}
    >
      <span
        style={{
          color: textColor || fontColor,
          textAlign: "center",
          marginBottom: 2
        }}
        className={`${icon ? 'd-flex g-8 align-center' : ''}`}
      >
        {buttonName}
        {icon}
      </span>
    </Button>
  );
};
CCButton.propTypes = {
  onClickButton: PropTypes.func,
  buttonName: PropTypes.node.isRequired,
  isSelected: PropTypes.bool,
  isClicked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  hasImage: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.oneOf(["primary", "default"]),
  width: PropTypes.string,
  loading: PropTypes.bool,
  block: PropTypes.bool,
  danger: PropTypes.bool,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string
};

export default CCButton;
