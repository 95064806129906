import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function cognitoGuestReducer(state =  initialState.cognitoGuest , action) {
    switch (action.type) {
        case types.AUTHENTICATED_GUEST_SUCCESS:
            return action.cognitoGuest;
        case types.AUTHENTICATED_GUEST_FAILED:
            return action.cognitoGuest.error;
        default:
            return state;
    }
}