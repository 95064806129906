import * as link from "../../components/common/pages";
import {mycc_base_url} from "../../../config";

export default {
    currencies:['USD'],
    baskets: {
        includePublicBaskets: true,
        businessCodes: [],
        individualCodes: [],
    },
    siteName:'My CarbonClick',
    defaultUnitType: 'kg',
    hideMentionsOfCarbonClick: false,
    content: {
        menu: {
            business: {
                items: ['dashboard', 'businessFootprint', 'myTeam', 'Pledge', 'myTravel', 'contributions', 'myAccount', 'FAQ', 'projects']
            },
            individual: {
                items: ['dashboard', 'myFootprint', 'myTravel', 'contributions', 'myActions', 'myClimateMovements', 'myAccount', 'FAQ', 'projects']
            },
            url: 'https://www.carbonclick.com/',
            signOutUrl: null
        },
        dashboard: {
            business: {
                items: ['content', 'welcomePopup', 'calculateFootprintBanner', 'actions', 'recentOffsets', 'cumulativeImpactPanel', 'supportedProjectsPanel']
            },
            individual: {
                items: ['badges', 'defaultHeader', 'welcomePopup', 'calculateFootprintBanner', 'recentOffsets', 'calculateMyFootprint', 'subscribePanel', 'groupsImpact', 'climateActions', 'reduceActions', 'cumulativeImpactPanel', 'supportedProjectsPanel'],
                merchantContent: {
                        headerText: "Power up your climate action",
                        items: [
                            {
                                title: "Learn where to reduce",
                                text: "Read our tips on how to lower your day-to-day impact on the planet.",
                                image: "/images/my-cc-dashboard/tips-1.png",
                                link: mycc_base_url + '/resources/CarbonClick-ReductionTips.pdf',
                                buttonText: "Read our tips",
                                merchantCode: null,
                                path: null,
                            },
                            {
                                title: "Understand carbon credits",
                                text: "Learn about what makes an effective, high quality carbon credit.",
                                image: "/images/my-cc-dashboard/tips-2.png",
                                link: "https://www.carbonclick.com/news-views/carbon-offsets-the-myths-the-facts-and-how-you-can-truly-make-a-difference",
                                buttonText: "Learn more",
                                merchantCode: null,
                                path: null,
                            },
                            {
                                title: "Offset your travel",
                                text: "Travel makes up a big part of your carbon footprint. Calculate and offset your trips.",
                                image: "/images/my-cc-dashboard/tips-3.png",
                                link: null,
                                buttonText: "Get started",
                                merchantCode: link.DASHBOARD,
                                path: link.MY_TRAVEL
                            },
                        ]
                }
            }
        },
        transaction: {
            headerText: 'Track & trace',
            headerImage: null,
            items: ['subscribe', 'impactPanel', 'createAccount']
        },
        individualSubscribePlans: {
            header: {
                text: 'Select your subscription',
                description: 'Your subscription will make a real, traceable difference. We\'ll show you how your impacts grow every month.',
                image: null,
            },
            paymentSuccess: {
                headerImage: null,
                items: ['impactPanel'],
            },
            items: ['impactPanel'],
        },
        businessOffsetPlans: {
            headerText: 'Make a difference in fighting climate change',
            headerDescription: 'Your subscription will make a real, traceable difference. We\'ll show you how your impacts grow every month.',
            headerImage: null,
        },
        singleProject: {
            items: ['sustainableDevelopmentGoals', 'ourTrustedSupplier']
        },
        projects: {
            headerText: 'Projects',
            descriptionHeaderText: 'Curated projects portfolios',
            descriptionContent: `
                <p class='m-0'>Browse our curated project portfolios below, or browse individual projects <a href='https://www.carbonclick.com/projects' target='_blank' rel="noopener noreferrer">on our website</a>.</p>
            `,
        },
        logo: {
            dark: "https://res.cloudinary.com/carbonclick/image/upload/c_scale,h_40/v1606968790/cc_logo_green-black_ismfmt.png",
            light: "https://res.cloudinary.com/carbonclick/image/upload/c_scale,h_40/v1634115499/carbonclick-logo-white_qeuea4.png",
            link: 'https://carbonclick.com/individuals/',
        },
        mobileLogo: {
            dark: "https://res.cloudinary.com/carbonclick/image/upload/c_scale,h_32/v1606968790/cc_logo_green-black_ismfmt.png",
            light: "https://res.cloudinary.com/carbonclick/image/upload/c_scale,h_32/v1634115499/carbonclick-logo-white_qeuea4.png" },
        signIn: {
            business: {
                headerText: "Grow your sustainability journey with the Climate Action Portal",
                formHeaderText: "Sign up to the Climate Action Portal for free",
                backgroundImage: 'url("https://res.cloudinary.com/carbonclick/image/upload/h_1400,f_auto,q_auto/v1696950306/MyCC/Header_vertical_gqlakz.jpg")',
                items: ["highlights"],
                highlightsData: {
                    title1: "Manage all your sustainable activity in one place",
                    title2: "Share the good you are doing for the planet",
                    title3: "Bring your team on the journey",
                }
            },
            individual: {
                hideSocialMedia: false,
                backgroundImage: 'url("https://res.cloudinary.com/carbonclick/image/upload/h_1400,f_auto,q_auto/v1696950306/MyCC/Header_vertical_gqlakz.jpg")',
            }
        },
        personalCalculator: {
            items: ['learnToReduce']
        },
        oneOffPayment: {
            contactEmail: 'info@carbonclick.com'
        },
        myMovementPanel: {
        },
        myTeamPanel: {
            items: ["climateFootprintHeader", "climateActionHeader"],
            teamMemberListVisibility: 'PUBLIC', // PUBLIC, TEAM, ADMIN
            hideUnnalocatedSeats: false,
            content: {
            }
        }
    },
    theme: {
        palette: {
            primary: {
                main: '#FF7800',
                light: '#FFAE66',
                dark: '#CC6000',
                contrastText: '#322A29'
            },
            secondary: {
                main: '#F6E052',
                light: '#FBF1B3',
                dark: '#F3D722',
                contrastText: '#322A29'
            },
            accent: {
                light: '#FBF5EF',
                dark: '#FF7800'
            },
            header: {
                main: '#FFF',
                contrastText: '', // this could be used for a header background colour
                dark: '#000',
                light: '#FFF',
            },
            callToAction: {
                background: '#FF6B00'
            },
            simpleImage: {
                background: "#ff7800",
                color1: "#ffae66",
                color2: "#ea8f40",
                color3: "#d87824",
                color4: "#f0c6a2",
                color5: "#ff7800",
            },
        },
        images: {
            banner: {
                banner1: 'url(\'/images/business-dashboard/Banner-Yellow.png\')',
                banner2: 'url(\'/images/business-dashboard/Banner-Red.png\')',
                banner3: 'url(\'/images/business-dashboard/Banner-Blue.png\')',
                bannerImpact: 'url(\'/images/business-dashboard/Banner-Impact.png\')',
            },
            hero: {
                main: 'url(https://res.cloudinary.com/carbonclick/image/upload/f_auto,q_auto/v1696247268/MyCC/Header_qxvgy5.jpg)',
                vertical: 'url(https://res.cloudinary.com/carbonclick/image/upload/h_1400,f_auto,q_auto/v1696950306/MyCC/Header_vertical_gqlakz.jpg)',
            }
        }
    },
}
