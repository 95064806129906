import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function impactGroupUsersReducer(
    state = initialState.impactGroupUsersStats ? initialState.impactGroupUsersStats : null,
    action
) {
    switch (action.type) {
        case types.GROUP_USERS_IMPACT_SUCCESS:
            return action.impactGroupUsersStats;
        case types.GROUP_USERS_IMPACT_FAILED:
            return action.error;
        default:
            return state;
    }
}
