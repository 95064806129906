import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function subscriptionReducer(
  state = initialState.subs ? initialState.subs : null,
  action
) {
  switch (action.type) {
    case types.CREATE_SUBS_SUCCESS:
      return action.create_subs;
    case types.CREATE_SUBS_FAILED:
      return action.error;
    case types.DELETE_SUBS_SUCCESS:
      return action.remove_subs;
    case types.DELETE_SUBS_FAILED:
      return action.error;
    case types.RESET_SUBS:
      return null;
    default:
      return state;
  }
}
