import React from "react";
import {matchPath, Route, Switch, useHistory, useLocation} from "react-router-dom";
import App from "./App";
import {addOrReplaceMerchantCodeBasePathPart} from "../util/pathUtils";
import {BASE_PATH_WITH_MERCHANT_CODE} from "./common/pages";

function BasePathRouter() {

    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const history = useHistory();

    let merchantCodeToAddToPath = null;

    const baseRouteMatch = matchPath(location.pathname,BASE_PATH_WITH_MERCHANT_CODE);

    if(!baseRouteMatch){
        if(urlParams.has('aasmartfuel')){
            merchantCodeToAddToPath = "AASMARTFUEL";
            urlParams.delete('aasmartfuel');
        }
        else if(urlParams.get('merchantReferrerCode')){
            merchantCodeToAddToPath = urlParams.get('merchantReferrerCode');
            urlParams.delete('merchantReferrerCode');
        }
        else if(urlParams.get('merchantCode')){
            merchantCodeToAddToPath = urlParams.get('merchantCode');
            urlParams.delete('merchantCode');
        }
    }

    if(merchantCodeToAddToPath){
        console.log('Route debug:BasePathRouter: Replacing merchantCodeToAddToPath')
        let searchPart = urlParams.toString() ? `?${urlParams.toString()}` : '';
        history.replace({ pathname: addOrReplaceMerchantCodeBasePathPart(location.pathname,merchantCodeToAddToPath),search: searchPart});
    }

    return(
      <Switch>
          {/*<Redirect from="/:url*(/)" to={location.pathname.slice(0, -1)} />*/}

          {/*<Redirect from="/:url(.*)" to=":url:url" />*/}

        <Route path={BASE_PATH_WITH_MERCHANT_CODE}>
          <App/>
        </Route>

        <Route path={'*'}>
          <App/>
        </Route>

      </Switch>

  )
}

export default BasePathRouter;