export const extractApiError = (error) => {
  if (error?.error?.error?.response?.data) {
    return {
      body: error.error.error.response.data,
      status: error?.error?.status
    }
  } else if (error?.error?.error?.error) {
    return {
      body: error.error.error.error,
      status: error?.error?.status
    }
  } else if (error?.error?.error?.message) {
    return {
      body: error.error.error.message,
      status: error?.error?.status
    }
  } else {
    return error;
  }
};
