import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function impactAllReducer(
  state = initialState.impactFlightsStats,
  action
) {
  switch (action.type) {
    case types.IMPACT_FLIGHTS_SUCCESS:
      return action.result;
    case types.IMPACT_FLIGHTS_FAILED:
      return action.error;
    default:
      return state;
  }
}
