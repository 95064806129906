import initialState from "./initialState";
import * as types from "../actions/actionTypes";

export default function flightsStatsReducer(state =  initialState.flightsStats || null , action) {
  switch (action.type) {
    case types.GET_FLIGHTS_STATS_SUCCESS:
      return action.flightsStats;
    case types.GET_FLIGHTS_STATS_FAILED:
      return action.error;
    case types.GET_FLIGHTS_STATS:
      return initialState.flightsStats;
    default:
      return state;
  }
}
