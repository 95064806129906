import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function projectReducer(state = initialState.project, action) {
    switch (action.type) {
        case types.GET_PROJECT_SUCCESS:
            return action.project;
        case types.GET_PROJECT_FAILED:
            return action.error;
        default:
            return state;
    }
}
