import React, {useEffect, useState} from "react";
import { Button, Row, Col } from "antd";
import PropTypes from "prop-types";
import TermsAndConditions from "../../common/TermsAndConditions";
import CCButton from "../../common/CCButtonV3";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import * as style from "./css";
import eye_regular from "../../../images/eye-regular.svg";
import eye_slash_solid from "../../../images/eye-slash-solid.svg";
import passwordValidator, {DisplayValidation} from "../passwordValidator";
import * as ccAnalytics from "../../../util/analytics/ccAnalytics";
import { getUserPool, signIn } from '../authentication';

const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

import * as link from "../../common/pages";
import generatePathWithCurrentMerchantCodeAsBase from "../../../util/generatePathWithCurrentMerchantCodeAsBase";
import {capitalize} from "lodash";

const SignUp = ({ trackPageViews, handleGoBack, showGoBackButton, titleText, isMobileDevice, googleAnalyticsParentPath, emailAddr}) => {
  const [loading, setLoading] = useState(false);

  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [validatePassword, setValidatePassword] = useState({
    isPasswordValid: false,
    passwordChecker: null
  });
  const [password, setPassword] = useState("");
  const [isNameValid, setIsNameValid] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [isCreateAccountClicked, setIsCreateAccountClicked] = useState(false);

  const [signUpResponse, setSignUpResponse] = useState({
    message: "",
    error: false,
    created: false
  });
  const [errorFirstName] = useState({
    message: "NA",
    error: false
  });

  const bannedSpecialCharactersRegExp = /^[a-z\d\-'\s]+$/i;

  useEffect(() => {
    if(trackPageViews){
      ccAnalytics.pageview(`${googleAnalyticsParentPath}/sign-up`);
    }
  }, []);


  const onEnterSignIn = (event) => {
    if (event.keyCode === 13) {
      handleSignUp(event)
    }
  }

  let dashboardPath = generatePathWithCurrentMerchantCodeAsBase(link.DASHBOARD);
  const goToHome = () => {
    window.location = dashboardPath;
  }

  const isFormValid = () => {
      if (isNameValid && validatePassword.isPasswordValid) {
        return true;
    }
  }

  const handleSignUp = (e) => {
    e.preventDefault();
    setIsCreateAccountClicked(true);
    if(isFormValid()){
      setLoading(true);
      appSignUp(emailAddr, firstName, password);
    }
  };


  const appSignUp = async (username, name, pass) => {
    const userPool = getUserPool();
    const attributeList = [
      new AmazonCognitoIdentity.CognitoUserAttribute({Name: 'email', Value: username.toLowerCase()}),
      new AmazonCognitoIdentity.CognitoUserAttribute({Name: 'name',  Value: name}),
      new AmazonCognitoIdentity.CognitoUserAttribute({Name: 'custom:source',Value: "my-carbon-click" })
    ]
    userPool.signUp(username.toLowerCase(), pass, attributeList, null,  (err)  => {
      if (err) {
        setLoading(false);
        setSignUpResponse({ message: err.message, error: true, created: false });
        return;
      }
      setSignUpResponse({ error: false, created: true });
      appSignIn(username.toLowerCase(), pass)
        .then(() => {
          ccAnalytics.event({ category: 'user', action: 'user-registered'});
          goToHome();
        });
    });
  };

  async function appSignIn(username, userPassword) {
    if (username.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      try {
        await signIn(username, userPassword);
      } catch (err) {
        setLoading(false);
        console.log("appSignIn error:  ", err);
        if (err.code === "UserNotConfirmedException") {
          // The error happens if the user didn't finish the confirmation step when signing up
          // In this case you need to resend the code and confirm the user
          // About how to resend the code and confirm the user, please check the signUp part
        } else if (err.code === "PasswordResetRequiredException") {
          // The error happens when the password is reset in the Cognito console
          // In this case you need to call forgotPassword to reset the password
          // Please check the Forgot Password part.
        } else if (err.code === "NotAuthorizedException") {
          // The error happens when the incorrect password is provided
        } else if (err.code === "UserNotFoundException") {
          // The error happens when the supplied username/email does not exist in the Cognito user pool
        } else {
          console.log("appSignIn", err);
        }
      }
    }
  }

  const handleNameChange = (e) => {
    const { value } = e.target;
    setIsNameValid((value.match(bannedSpecialCharactersRegExp) && value.length >= 2 && value.length <= 50) ? true : false);
    setFirstName(capitalize(value));
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setValidatePassword(passwordValidator(value));
    setPassword(value);
  };

  const SignupButton = (
    <CCButton
      buttonName="Create account"
      onClickButton={handleSignUp}
      isDisabled={false}
      size="large"
      type="primary"
      width="120"
      loading={loading}
    />
  )

  const signUpMessage = titleText ? titleText: `Looks like it's your first time signing in. Complete the form below to create an account.`;

  const PasswordValidation = passwordValidator.length > 0 &&
    !validatePassword.isPasswordValid && 
    <DisplayValidation passwordValidator={validatePassword.passwordChecker}/>

    const validationMessage = (isValid, message) => {
      return isCreateAccountClicked && isValid === false ? (
        <Row style={isMobileDevice ? { ...style.MOBILE_STYLE } : null}>
          <span style={{ color: "red", fontSize: 14 }}>
            {message}
        </span>
        </Row>
      ) : null;
    }
   
  return (
    <div className="signUpFormWrapper">
      <form onSubmit={handleSignUp}>
        <Row className="pb-2">
          <span style={{ fontSize: 15}}>{signUpMessage}</span>
        </Row>
        <div className="w-100 d-inline-grid text-left">
          <label style={style.LABEL}>Enter email address</label>
          <div style={style.INPUT_WRAPPER_STYLE}>
            {emailAddr ? emailAddr : "Your email"}
          </div>
        </div>
        <Row style={style.DIV_SPACING}>
          <div className="inputWrapper">
            <input
              value={firstName}

              size="large"
              autoComplete={"name"}
              onChange={handleNameChange}
              placeholder="First name"
              onKeyDown={(e) => onEnterSignIn(e)}
              style={{
                ...style.INPUT_STYLE
              }}
            />
          </div>
        </Row>
        {validationMessage(isNameValid, "Name should be between 2-50 characters and cannot contain special characters")}
        <Row>
          <span style={{ fontSize: 10, color: "red" }}>
            {errorFirstName.error ? errorFirstName.message : null}
          </span>
        </Row>
        <Row style={style.DIV_SPACING}>
          <div className="inputWrapper">
            <Row>
              <Col span={22}>
                <input
                  type={!isPasswordVisible ? "password" : "text"}
                  autoComplete="new-password"
                  value={password}
                  size="large"
                  onChange={handlePasswordChange}
                  placeholder="Create password"
                  onKeyDown={(e) => onEnterSignIn(e)}
                  style={{
                    ...style.INPUT_STYLE
                  }}
                />
              </Col>
              <Col span={2}>
                <img
                  onClick={() => setPasswordVisible(!isPasswordVisible)}
                  style={{ width: 20 }}
                  src={isPasswordVisible ? eye_slash_solid : eye_regular}
                />
              </Col>
            </Row>
          </div>
        </Row>
        {PasswordValidation}
        <Row style={style.DIV_SPACING}>
          <TermsAndConditions />
        </Row>
        {signUpResponse.error ? (
          <Row style={{ color: "red", fontSize: 14, paddingTop: 10 }}>
            {signUpResponse.message}
          </Row>
        ) : signUpResponse.created && signUpResponse?.message ? (
          <Row style={{ color: "green", fontSize: 14, paddingTop: 10 }}>
            {signUpResponse?.message}
          </Row>
        ) : null}
        <div className="flex-wrap-between" style={style.DIV_SPACING}>
          {showGoBackButton === false ? null: <Button
            size="large"
            onClick={handleGoBack}
            type="link"
          >
            <FontAwesomeIcon icon={faArrowLeft} />
            <span style={{ paddingLeft: 5 }}>{`Go Back`}</span>
          </Button>}
          <div style={{ flexGrow: 0 }}>{SignupButton}</div>
        </div>
      </form>
    </div>
  );
};

SignUp.propTypes = {
  OAuthSignIn: PropTypes.func,
  isMobileDevice: PropTypes.bool
};

export default SignUp;
