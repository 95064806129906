import React from 'react';
import { Button } from 'antd';

import './Button.css';

const CustomButton = ({ onClick, text, type, icon, color }) => {
  return (
    <Button
      onClick={onClick}
      className={`${type} ${color ? 'color-' + color : ''}`}
      type={type}
      size={'large'}
    >
      <div className='d-flex g-8 align-center'>
        {text}
        {icon}
      </div>
    </Button>
  );
};

export default CustomButton;
