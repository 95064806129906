import React from "react";
import { Button, Spin } from "antd";
import PropTypes from "prop-types";
import fb_icon from "../../images/social/F_icon.svg";
import google_icon from "../../images/social/G_icon.svg";

const SocialLoginButton = ({
  onClickButton,
  buttonName,
  type,
  size,
  buttonColor,
  socialType, loading
}) => {

  const BUTTON_STYLE = {
    backgroundColor: buttonColor,
    border: "none",
    borderRadius: 5,
    fontWeight: '600',
    marginTop: 4,
    marginBottom: 4
  };


  const SOCIAL_ICON_STYLE = {
    width: 20,
    display: "inline",
    float: "right"
  };

  const picture = socialType === "facebook"
    ? <img style={SOCIAL_ICON_STYLE} alt='' src={fb_icon} />
    : <img style={SOCIAL_ICON_STYLE} alt='' src={google_icon} />;

  return (
    <Spin spin={2} spinning={loading}>
      <Button
        size={size || 'large'}
        onClick={onClickButton}
        type={type}
        block
        style={BUTTON_STYLE}
        className="no-WL"
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ flexGrow: 1, margin: '0 7px 0 0' }}>{picture}</div>
          <div style={{ flexGrow: 999, maxWidth: '15rem' }}><span style={{ display: 'block' }}>{buttonName}</span></div>
          <div style={{ flexGrow: 1, margin: '0 7px 0 0' }}/>
        </div>
      </Button>
    </Spin>
  );
};

SocialLoginButton.propTypes = {
  onClickButton: PropTypes.func.isRequired,
  buttonName: PropTypes.string.isRequired,
  size: PropTypes.string,
  type: PropTypes.string,
  socialType: PropTypes.string,
  buttonColor: PropTypes.string
};

export default SocialLoginButton;
