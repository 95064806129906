
export default
    {
        code:'BES_NZ_FOREVER_FORESTS',
        content:{
            logo: {
                link: 'https://www.foreverforests.co.nz/'
            },
            menu: {
                url: 'https://www.foreverforests.co.nz/',
                business: {
                    items: ['dashboard','businessFootprint', 'myTravel', 'myAccount', 'projects']
                },
                individual: {
                    items: ['dashboard', 'myFootprint', 'myTravel', 'myClimateMovements', 'myAccount', 'projects']
                },
            },
            projects: {
                headerText: 'Carbon Offsetting Projects',
                descriptionHeaderText: 'Forever Forests\' blended basket approach',
                descriptionContent:`
                    <p>We have carefully selected a basket of offsetting projects with a blend of New Zealand Forever Forests, 
                        international reforesation and forest conservation, and renewable energy.</p>
                    <p>This blend has a primary focus on nature-based solutions at home and abroad, 
                        furthering our ambition of protecting and restoring the natural environment for future generations to enjoy. 
                        The harnessing of renewable energy solutions in developing nations is crucial in the fight 
                        against climate change and along with adding variety to the basket of offsetting projects, 
                        helps to balance out the price of more expensive nature-based credits.</p>
                    <p>These projects may be periodically updated over time, 
                        though a least 75% of the offsetting cost will go towards developing nature-based solutions.</p>
                `,
            },
            dashboard: {
                business: {
                    items: ['welcomePopup', 'recentOffsets', 'supportedProjectsPanel']
                },
                individual: {
                    items: ['defaultHeader', 'badges', 'welcomePopup', 'recentOffsets', 'calculateMyFootprint', 'subscribePanel', 'groupsImpact', 'climateActions', 'reduceActions', 'supportedProjectsPanel'],
                }
            },
        },
        baskets: {
            includePublicBaskets: false,
            businessCodes: ['FOREVER_FORESTS'],
            individualCodes: ['FOREVER_FORESTS']
        },
        theme: {

        }
    }