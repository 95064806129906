import React, {useEffect, useState} from "react";
import { isMobile } from "../common/viewBreakpoints";
import SignIn from './SignIn';
import SignInUpStart from './SignInUpStart';
import SignUp from './signup/index';
import queryString from "query-string";

import {
  useLocation
} from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import * as userActions from "../../redux/actions/userActions";
import { bindActionCreators } from "redux";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Spin } from "antd";

const SignInWidget = ({hideUserMsg, countryCode, industryId, isClimateFriendlyTeamFlow, showGoBackButton,titleText, defaultEmail, googleAnalyticsPrefixText, ... props}) => {
  let location = useLocation();

  let googleAnalyticsParentPath = googleAnalyticsPrefixText ? googleAnalyticsPrefixText : null;

  const isMobileDevice = isMobile(useMediaQuery);

  const parsed = queryString.parse(location.search);
  
  const splitAnyUrlParams = parsed?.from?.split("?");
  const convertToURLParamFormat = splitAnyUrlParams && `?${splitAnyUrlParams[1]}`;
  const urlParams = new URLSearchParams(convertToURLParamFormat);
  const emailInURLParam = urlParams.get("email");
  const trackPageViews = emailInURLParam!=null; //shouldn't track widget steps if we have email in url param - we'll go to them automatically

  const [verifiedEmail, setVerifiedEmail] = useState(null);
  const [displayLoading, setDisplayLoading] = useState(false);
  const [stage, setStage] = useState('start');

  const verifyUserExistsAndReroute = (email) => {
      const decodedEmail = email ? decodeURIComponent(email).replace(/\s/g,"+") : null;
      setVerifiedEmail(decodedEmail);
      props.actions.verifyUserExists(decodedEmail , true);
  };

  // triggered by verifyUserExistsAndReroute() call
  useEffect(() => {
    if (!verifiedEmail) {
      setDisplayLoading(false);
      setStage('start');
    } else {
      if (props.userDoesNotExistOrNoLogins) {
        setDisplayLoading(false);
        setStage('signup');
      } else if (props.userExistsWithLogins) {
        setDisplayLoading(false);
        setStage('signin');
      }
    }
  }, [props.userDoesNotExistOrNoLogins, props.userExistsWithLogins]);

  useEffect(() => {
    if (parsed.email || defaultEmail || emailInURLParam) {
      setDisplayLoading(true);
      if(props.cognitoGuest && props.cognitoGuest.isSignedIn){
        verifyUserExistsAndReroute(parsed.email ? parsed.email : defaultEmail ? defaultEmail : emailInURLParam ? emailInURLParam : null);
      }
    }
  }, [props.cognitoGuest, emailInURLParam, defaultEmail]);

  return (
      <div className={`old-styles ${isMobileDevice && 'w-100'}`}>
      {displayLoading && stage ? 
        <Spin style={{display: "block", margin: "100px auto"}} tip="Looking for your account..."/>
        :
        {
          'start': <SignInUpStart isClimateFriendlyTeamFlow={isClimateFriendlyTeamFlow} showGoBackButton={showGoBackButton} defaultEmail={emailInURLParam ? emailInURLParam :defaultEmail} onNext={verifyUserExistsAndReroute} pagePathForAnalytics={'signin'} />,
          'signin': <SignIn trackPageViews={trackPageViews} handleGoBack={()=>{setStage('start'); hideUserMsg()}} showGoBackButton={showGoBackButton} isMobileDevice={isMobileDevice} googleAnalyticsParentPath={googleAnalyticsParentPath} emailAddr={verifiedEmail}/>,
          'signup': <SignUp trackPageViews={trackPageViews} handleGoBack={()=>{setStage('start'); hideUserMsg()}} countryCode={countryCode} industryId={industryId} showGoBackButton={showGoBackButton} titleText={titleText}  isMobileDevice={isMobileDevice} googleAnalyticsParentPath={googleAnalyticsParentPath} emailAddr={verifiedEmail}/>
        }[stage]
      }
      </div>
  );
};

SignInWidget.propTypes = {
  defaultEmail: PropTypes.string
};

function mapStateToProps(state) {
  const userExistsWithLogins = state.user && state.user.loginMethods && state.user.loginMethods.length>0;
  let userDoesNotExistOrNoLogins;

  if(!userExistsWithLogins){
    userDoesNotExistOrNoLogins = state.user && (
      (state.user.error && state.user.error.status === 404) || 
      (state.user.loginMethods && state.user.loginMethods.length==0)
      );
  }

  return {
    userExistsWithLogins: userExistsWithLogins,
    userDoesNotExistOrNoLogins: userDoesNotExistOrNoLogins,
    cognitoGuest: state.cognitoGuest
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    verifyUserExists: bindActionCreators(
      userActions.doesUserExist,
      dispatch
    )
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInWidget);
