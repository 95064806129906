import * as types from "./actionTypes";
import { beginApiCall } from "./apiStatusActions";
import { checkSocialLoginWasUsed, getConfiguredAmplify, getCurrentUser, signInGuest } from '../../components/security/authentication';

const fetchUserProfilePicture = (userId) => fetch(`https://graph.facebook.com/v8.0/${userId}/picture?redirect=false`);

export function getAuthenticatedUserSuccess(currentUserCognito) {
  return { type: types.AUTHENTICATED_USER_SUCCESS, currentUserCognito };
}

export function getAuthenticatedUserFailed(error) {
  return { type: types.AUTHENTICATED_USER_FAILED, error };
}

export function resetAuthenticatedUser() {
  return { type: types.AUTHENTICATED_USER_RESET, currentUserCognito: null };
}

export function resetAuthenticatedGuest() {
  return { type: types.AUTHENTICATED_GUEST_RESET, cognitoGuest: null };
}

export function authenticatedUserProfilePicture(profilePictureUrl) {
    return {type: types.AUTHENTICATED_USER_PROFILE_PICTURE, profilePictureUrl}
}

export function getAuthenticatedUser() {
  return async function (dispatch) {
    dispatch(resetAuthenticatedGuest());
    dispatch(beginApiCall());

    // only amplify can handle social logins, so load it dynamically when needed
    if (checkSocialLoginWasUsed()) await getConfiguredAmplify();
    const cognitoUser = getCurrentUser()
    if (!cognitoUser) {
      return dispatch(getAuthenticatedUserFailed({error: {message: "User is not authenticated"}}))
    }
    return cognitoUser.getSession((err, session) => {
      if (err) {
        return dispatch(getAuthenticatedUserFailed({ error: err }));
      }
      const currentUserCognito = {
        authData: session.getIdToken().decodePayload(),
        isSignedIn: true
      }
      const cognitoUsername = currentUserCognito.authData['cognito:username'];
      if (cognitoUsername && /Facebook_\d+/.test(cognitoUsername))
        fetchUserProfilePicture(cognitoUsername.split('_')[1])
          .then(response => response.json())
          .then(data => dispatch(authenticatedUserProfilePicture(data.data.url)));
      return dispatch(getAuthenticatedUserSuccess(currentUserCognito));
    });
  };
}

export function getAuthenticatedGuestSuccess() {
  return { type: types.AUTHENTICATED_GUEST_SUCCESS, cognitoGuest:{isSignedIn: true} };
}

export function getAuthenticatedGuestFailed(e) {
  return { type: types.AUTHENTICATED_GUEST_FAILED, cognitoGuest:{error:e} };
}

export function loginGuest() {
  return function (dispatch) {
    dispatch(resetAuthenticatedUser());
    signInGuest()
      .then(() => dispatch(getAuthenticatedGuestSuccess()))
      .catch((err) => dispatch(getAuthenticatedGuestFailed(err)));
  };
}