export default
    {
        code: 'BES_GB_CARBON_VISIBILITY',
        currencies: ['GBP'],
        baskets: {
            includePublicBaskets: false,
            businessCodes: ['CARBON_VISIBILITY'],
            individualCodes: ['CARBON_VISIBILITY']
        },
        defaultUnitType: 't',
        content: {
            oneOffPayment: {
                customerLink: 'https://carbonvisibility.carbon.click/'
            },
            transaction: {
                headerText: 'Nice work! You just supported genuine environmental projects that really make a difference.',
                items: ['impactPanel']
            },
            logo: {
                link: 'https://www.carbonvisibility.com/',
            },
            signIn: {
                individual: {
                    backgroundImage: 'url("https://res.cloudinary.com/carbonclick/image/upload/v1652904385/White%20Label/Carbon%20Visibility/carbon_visibility_projects_vc9psx.jpg")',
                }
            },
        },
        theme: {
            images: {
                hero: {
                    main: 'url(\'https://res.cloudinary.com/carbonclick/image/upload/v1652904385/White%20Label/Carbon%20Visibility/carbon_visibility_projects_vc9psx.jpg\')',
                },
                banner: {
                    banner1: 'url(\'https://res.cloudinary.com/carbonclick/image/upload/v1652904385/White%20Label/Carbon%20Visibility/carbon_visibility_projects_vc9psx.jpg\')',
                }
            }
        },
    }