import React from 'react';

import { mycc_base_url } from '../../../config';

const PoweredBy = ({ color = 'ffffff', hideTitle, center }) => {
  return (
    <div
      className='powered-by'
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: center ? 'center' : 'flex-start',
      }}
    >
      {!hideTitle && <p style={{ color: `#${color}`, marginBottom: 0 }}>Powered by</p>}
      <a href={'http://www.carbonclick.com/'} target='_blank' rel="noopener noreferrer">
        <img
          src={`https://res.cloudinary.com/carbonclick/image/upload/c_scale,co_rgb:${color},e_colorize:100,f_png,w_800/v1693829282/Merchant%20Portal/cc-logo-white_cgqhrt.svg`}
          alt='CarbonClick logo'
          style={{ maxHeight: 20, width: 'auto' }}
        />
      </a>
    </div>
  );
};

export default PoweredBy;
