export const getFormattedDate = (timestamp, locale = 'en_NZ', options) => {
  const date = new Date(timestamp);
  const validLocaleFormat = locale?.replace('_', '-');

  if (!date) {
    return;
  }

  const backupOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  return date?.toLocaleString(validLocaleFormat, options || backupOptions)?.replace(',', '');
};
