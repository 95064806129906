export default {
    content: {
        menu: {
            business: {
                items: ['dashboard', 'merchantOffsets', 'myAccount', 'projects', 'FAQ']
            }
        },
        dashboard: {
            business: {
                items: ['content', 'recentOffsets', 'supportedProjectsPanel'],
            }
        }
    }
}