

export function setThemedCssVariables(theme) {
    document.documentElement.style.setProperty("--primary-color", theme.palette.primary.main);
    document.documentElement.style.setProperty("--primary-hover-color", theme.palette.primary.main);
    document.documentElement.style.setProperty("--primary-light-color", theme.palette.primary.light);
    document.documentElement.style.setProperty("--primary-main-color", theme.palette.primary.main);
    document.documentElement.style.setProperty("--primary-contrast-text-color", theme.palette.primary.contrastText);
    document.documentElement.style.setProperty("--primary-dark-color", theme.palette.primary.dark);
    document.documentElement.style.setProperty("--secondary-color", theme.palette.secondary.main);
    document.documentElement.style.setProperty("--secondary-background-color", theme.palette.secondary.contrastText);
    document.documentElement.style.setProperty("--accent-dark-color", theme.palette.accent.dark);
    document.documentElement.style.setProperty("--accent-light-color", theme.palette.accent.light);
    document.documentElement.style.setProperty("--header-color", theme.palette.header.main);
    document.documentElement.style.setProperty("--hero-img", theme.images.hero.main);
    document.documentElement.style.setProperty("--hero-img-1", theme.images.hero.hero1);
    document.documentElement.style.setProperty("--hero-img-2", theme.images.hero.hero2);
    document.documentElement.style.setProperty("--hero-img-3", theme.images.hero.hero3);
    document.documentElement.style.setProperty("--hero-img-4", theme.images.hero.hero4);
    document.documentElement.style.setProperty("--banner-img-1", theme.images.banner.banner1);
    document.documentElement.style.setProperty("--banner-img-2", theme.images.banner.banner2);
    document.documentElement.style.setProperty("--banner-img-3", theme.images.banner.banner3);
    document.documentElement.style.setProperty("--banner-impact", theme.images.banner.bannerImpact);

    document.documentElement.style.setProperty("--simple-themed-image-background", theme.palette.simpleImage.background);
    document.documentElement.style.setProperty("--simple-themed-image-color-1", theme.palette.simpleImage.color1);
    document.documentElement.style.setProperty("--simple-themed-image-color-2", theme.palette.simpleImage.color2);
    document.documentElement.style.setProperty("--simple-themed-image-color-3", theme.palette.simpleImage.color3);
    document.documentElement.style.setProperty("--simple-themed-image-color-4", theme.palette.simpleImage.color4);
    document.documentElement.style.setProperty("--simple-themed-image-color-5", theme.palette.simpleImage.color5);

    document.documentElement.style.setProperty("--antd-wave-shadow-color", theme.palette.primary.main);
}