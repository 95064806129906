import { Row } from "antd";
import PropTypes from "prop-types";
import React, {useContext} from 'react';
import FadeIn from 'react-fade-in';
import leaf from "../../images/carbonclick_leaves.jpg";
import logo from "../../images/cc_logo_white.svg";
import UIContext from "../../util/UIContext";
import PoweredBy from "./PoweredBy";

const CustomHeaderView = ({ isSideView, text, subText, isMobileDevice, customImageSrc, darkness, customLogo, hideLogo }) => {
  const uiContext = useContext(UIContext);
  const isTopView = !isSideView;

  const hidePoweredBy = !uiContext?.hasWlConfig || uiContext?.hideMentionsOfCarbonClick || uiContext?.code === 'CARBONCLICK';

  const HEADER_STYLE = {
    maxWidth: isMobileDevice ?  '100%' : '350px',
    maxHeight: isMobileDevice ?  '160px' : '300px',
    padding: isMobileDevice ? '40px 50px 0 50px' : '40px 0 0 50px'
  }

  const FONT = {
    color: "#FFFFFF"
  };

  const validImageSourceUrl =
    customImageSrc && !customImageSrc?.includes('url')
      ? `url(${customImageSrc})`
      : customImageSrc;

  const CUSTOM_HEADER_MAIN = {
    backgroundPosition: 'center',
    backgroundImage: `var(--dark-top-overlay), ${validImageSourceUrl || uiContext.theme.images.hero.main || `url(${leaf})`}`,
    maxWidth: "100%",
    backgroundSize: "cover",
    textAlign: "center",
    minHeight: isTopView ? (300) : "100vh",
    position: isTopView ? "relative" : "",
  };

  const CONTENT_TEXT_STYLE = {
    marginLeft: isTopView ? (isMobileDevice ? 50 : 110) : 50,
    marginRight: isTopView ? (isMobileDevice ? 50 : 110) : 50,
    paddingTop: isMobileDevice ? 10 : 30
  };

  const FOOTER_CONTAINER = {
    marginTop: 'auto',
    marginBottom: 0,
    width: '100%',
    backgroundImage: `linear-gradient(to top, var(--dark-overlay-color) 20%, transparent 100%)`,
  }

  const FOOTER_STYLE = {
    paddingLeft: isTopView ? (isMobileDevice ? 50 : 110) : 50,
    paddingRight: isTopView ? (isMobileDevice ? 30 : 110) : 50,
    paddingBottom: 30,
    paddingTop: 40,
  };

  const OVERLAY_STYLE = {
    position: "absolute",
    top: 0, bottom: 0, left: 0, right: 0,
    background: "rgba(0,0,0," + darkness + ")"
  };

  const CONTENT_TEXT_ALIGN = {
    textAlign: isTopView && !isMobileDevice ? "center" : "left"
  };

  const SUB_TEXT_STYLE = {
    marginRight: isMobileDevice ? 20 : 0,
    marginTop: 10,
    ...CONTENT_TEXT_ALIGN,
    ...FONT,
    fontSize: isMobileDevice ? 16 : 20
  };

  const MAIN_TEXT_STYLE = {
    ...FONT,
    fontSize: isMobileDevice ? 24 : 42,
    ...CONTENT_TEXT_ALIGN
  };

const CONTENT_BODY_STYLE = {
  textAlign: "left",
  width: "100%",
  zIndex: 10,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  alignSelf: hideLogo && 'center'
}

  return (
    <>
      <Row style={CUSTOM_HEADER_MAIN}>
        {darkness ? <div style={OVERLAY_STYLE}></div> : null}
        <div style={CONTENT_BODY_STYLE}>
          {!hideLogo && <div>
            <a href={uiContext.content.logo.link} target="_blank" rel="noopener noreferrer" >
              <img style={HEADER_STYLE} src={customLogo ? customLogo :logo} alt="carbonclick" sp="true" />
            </a>
          </div>}
          <div style={CONTENT_TEXT_STYLE}>
            <h3 className='font-secondary' style={MAIN_TEXT_STYLE}>{text}</h3>
            <div style={SUB_TEXT_STYLE}>
              {subText && subText != '' ?
                <FadeIn>{subText}</FadeIn>
                : null
              }
            </div>
          </div>
        </div>

        {!hidePoweredBy && (
          <div style={FOOTER_CONTAINER}>
            <div style={FOOTER_STYLE}>
              <PoweredBy />
            </div>
          </div>
        )}
      </Row>
    </>
  );
};

CustomHeaderView.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  subText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  isSideView: PropTypes.bool,
  isMobileDevice: PropTypes.bool.isRequired
};

export default CustomHeaderView;
