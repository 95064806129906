import React from "react";

const currencies = {
    "USD": "$",
    "NZD": "$",
    "AUD": "$",
    "GBP": "£",
    "CNY": "¥",
    "JPY": "¥",
    "EUR": "€",
    "HKD": "$"
};

const formatPriceTextOnly = (price) => {
    const symbol = currencies[price.currency.toUpperCase()];

    const number = price.value.toLocaleString(undefined,{minimumFractionDigits: price?.zeroDecimalCurrency ? 0 :2});

    return symbol ? symbol + number : `${number} ${price.currency.toUpperCase()}`;

};

const formatPriceTextOnlyWithSymbolAndCode = (price, includeCurrency = true) => {
    if(!price) return '';

    
    let symbol = '';
    if(currencies[price?.currency?.toUpperCase()]) symbol = currencies[price?.currency?.toUpperCase()] 

    return symbol + `${parseFloat(price?.value).toLocaleString(undefined, { minimumFractionDigits: price?.zeroDecimalCurrency ? 0 : 2 , maximumFractionDigits: 2})} ${includeCurrency ? price?.currency?.toUpperCase() : ''}`;
};

const formatNZDPriceAsAASmartFuelCentsPerLitre = (price) => {
    if(!price?.currency || !price?.value || price.currency.toUpperCase()!='NZD' ) return '';

    const cpl = parseFloat(price.value/25); 

    return `$${cpl.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
};


const formatPrice = (price) => {
    const symbol = currencies[price.currency.toUpperCase()];

    if (symbol) {
        return <>
            <span className="currencySymbol">{symbol}</span>
            <span className="price">{price.value.toFixed(price?.zeroDecimalCurrency ? 0 :2)}</span>
        </>
    }
    else{
        return <>
            <span className="price">{price.value.toFixed(price?.zeroDecimalCurrency ? 0 :2)}</span>
        <span className="currencyCode">{price.currency.toUpperCase()}</span>
    </>
    }
};

export { formatPriceTextOnly, formatPrice, currencies, formatPriceTextOnlyWithSymbolAndCode, formatNZDPriceAsAASmartFuelCentsPerLitre};