import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function groupReduceStatsReducer(
  thisInitialState = initialState.groupReduceStats ? initialState.groupReduceStats : null,
  action
) {
  switch (action.type) {
    case types.GROUP_REDUCE_STATS_SUCCESS:{
      return action.groupReduceStats;
    }
    case types.GROUP_REDUCE_STATS_FAILED:
      return action.error;
    default:
      return thisInitialState;
  }
}