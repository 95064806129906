import merge from "deepmerge";
import aviationBase from "./aviationBase";

const overwriteMerge = (destinationArray, sourceArray) => {
    return sourceArray;
}

const overrides = {
        code:'BES_AU_MISIO',
    content: {
        signIn: {
            business: {
                headerText: "Grow your sustainability journey with CarbonClick",
                secondLogoUrl: "https://res.cloudinary.com/carbonclick/image/upload/v1668510947/White%20Label/MISIO/Misio_Logo_primary_Wht_kehj39.svg",
                logoSeparatorUrl: "https://res.cloudinary.com/carbonclick/image/upload/v1668765301/White%20Label/X-cross-white_soelr9.svg",
                formHeaderText: "Sign up today, for free, and make a positive impact",
                backgroundImage: "url('https://res.cloudinary.com/carbonclick/image/upload/v1668510949/White%20Label/MISIO/shutterstock_1769197388sml-CC_ncbbxv.jpg')",
                highlightsData: {
                    title1: "Measure the carbon footprint of your business",
                    title2: "Offset your emissions with high-quality, meaningful climate projects",
                    title3: "Engage your customers in transparent climate action",
                }
            },
        },
        logo: {
            link: 'https://hq.misio.io/'
        }
    }
}

export default merge(aviationBase,overrides, { arrayMerge: overwriteMerge });
