/* eslint-disable */
// noinspection CommaExpressionJS

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import Constants from './constants';
export default function withFacebook(Comp) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(props) {
            var _this = _super.call(this, props) || this;
            _this.fbAsyncInit = _this.fbAsyncInit.bind(_this);
            _this.initFB = _this.initFB.bind(_this);
            _this.signIn = _this.signIn.bind(_this);
            _this.signOut = _this.signOut.bind(_this);
            _this.federatedSignIn = _this.federatedSignIn.bind(_this);
            _this.state = {};
            return _this;
        }
        class_1.prototype.signIn = function () {
            var _this = this;
            var fb = window.FB;
            fb.getLoginStatus(function (response) {
                var payload = {
                    provider: Constants.FACEBOOK,
                };
                try {
                    localStorage.setItem(Constants.AUTH_SOURCE_KEY, JSON.stringify(payload));
                }
                catch (e) {
                    console.debug('Failed to cache auth source into localStorage', e);
                }
                if (response.status === 'connected') {
                    _this.federatedSignIn(response.authResponse);
                }
                else {
                    fb.login(function (response) {
                        if (!response || !response.authResponse) {
                            return;
                        }
                        _this.federatedSignIn(response.authResponse);
                    }, {
                        scope: 'public_profile,email',
                    });
                }
            });
        };
        class_1.prototype.federatedSignIn = function (response) {
            console.debug(response);
            var onStateChange = this.props.onStateChange;
            var accessToken = response.accessToken, expiresIn = response.expiresIn;
            var date = new Date();
            var expires_at = expiresIn * 1000 + date.getTime();
            if (!accessToken) {
                return;
            }
            var fb = window.FB;
            fb.api('/me', { fields: 'name,email' },  async function (response) {
                var user = {
                    name: response.name,
                    email: response.email,
                };
                const { Auth } = await import("aws-amplify");
                if (!Auth ||
                  typeof Auth.federatedSignIn !== 'function' ||
                  typeof Auth.currentAuthenticatedUser !== 'function') {
                    throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
                }
                Auth.federatedSignIn('facebook', { token: accessToken, expires_at: expires_at }, user)
                  .then(function (credentials) {
                      return Auth.currentAuthenticatedUser();
                  })
                  .then(function (authUser) {
                      if (onStateChange) {
                          onStateChange('signedIn', authUser);
                      }
                  });
            });
        };
        class_1.prototype.signOut = function () {
            var fb = window.FB;
            if (!fb) {
                console.debug('FB sdk undefined');
                return Promise.resolve();
            }
            fb.getLoginStatus(function (response) {
                if (response.status === 'connected') {
                    return new Promise(function (res, rej) {
                        console.debug('facebook signing out');
                        fb.logout(function (response) {
                            res(response);
                        });
                    });
                }
                else {
                    return Promise.resolve();
                }
            });
        };
        class_1.prototype.componentDidMount = function () {
            var facebook_app_id = this.props.facebook_app_id;
            if (facebook_app_id && !window.FB)
                this.createScript();
        };
        class_1.prototype.fbAsyncInit = function () {
            console.debug('init FB');
            var facebook_app_id = this.props.facebook_app_id;
            var fb = window.FB;
            fb.init({
                appId: facebook_app_id,
                cookie: true,
                xfbml: true,
                version: 'v2.11',
            });
            fb.getLoginStatus(function (response) { return console.debug(response); });
        };
        class_1.prototype.initFB = function () {
            var fb = window.FB;
            console.debug('FB inited');
        };
        class_1.prototype.createScript = function () {
            window.fbAsyncInit = this.fbAsyncInit;
            var script = document.createElement('script');
            script.src = 'https://connect.facebook.net/en_US/sdk.js';
            script.async = true;
            script.onload = this.initFB;
            document.body.appendChild(script);
        };
        const WithFacebook = function () {
            var fb = window.FB;
            return (React.createElement(Comp, __assign({}, this.props, { fb: fb, facebookSignIn: this.signIn, facebookSignOut: this.signOut })));
        };
        class_1.prototype.render = WithFacebook;
        return class_1;
    }(React.Component));
}