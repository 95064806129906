import React from 'react';

import * as path from "../../common/pages"
import { getLinkToProject } from '../../../util/pathUtils';
import CustomButton from '../../controls/CustomButton';
import themedImpactSVGs from '../../../images/impact/ThemedSVGs';
import generatePathWithCurrentMerchantCodeAsBase from "../../../util/generatePathWithCurrentMerchantCodeAsBase";
import CCButton from '../../common/CCButtonV3';
import ProgressCircle from '../ProgressCircle';
import './style.css';

const SingleProject = ({ project, showLearnMore, transactionId, hideImpactButton  }) => {
  const link = getLinkToProject(project.code);

  const getTrackAndTraceUrl = (transactionId) => {
    const data = {
      projectCode: project?.code,
    }

    if (transactionId) {
      data.transactionId = transactionId;
    }

    return generatePathWithCurrentMerchantCodeAsBase(
      path.TRACK_AND_TRACE_PAGE_FULL_WITH_PROJ_PARAM, data
    )
  }

  const trackAndTraceUrl = getTrackAndTraceUrl(transactionId);

  return (
    <div className='project-basket-c gap-2'>
      <div
        className='project-basket-image no-translate m-0 cursor-pointer'
        onClick={() => link && window.open(link, '_blank')}
        style={{
          objectFit: 'cover',
          background: `url(${project?.thumbnailImageUrl})`,
          // hacky way to fix image without using libraries and affecting others
          backgroundPosition: project?.code === 'COOKSTOVES_UGANDA_GS6604' ? 'left' : 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      />

      <div className='project-basket-info-box'>
        <div className='d-flex align-end project-basket-header justify-between'>
          <div>
            <p className='location-description px-3'>{project.locationDescription}</p>
            <p className='name px-3'>{project.name}</p>
          </div>
          {project?.portion && (
            <ProgressCircle value={project?.portion} />
          )}
        </div>

        <div className='project-details p-3'>
          <p className='short-description'>{project.shortDescription}</p>
          <div className='buttons-container d-flex'>
            {showLearnMore && (
              <CustomButton
                text='Learn more'
                type='link'
                onClick={() => link && window.open(link, '_blank')}
                icon={themedImpactSVGs.cubes()}
                color='grey'
              />
            )}
            {!hideImpactButton && project.productType !== 'MONEY' && (
              <CCButton
                buttonName="Trace impact"
                onClickButton={() => trackAndTraceUrl && window.open(trackAndTraceUrl, '_blank')}
                type="primary"
                textColor={'var(--primary-contrast-text-color)'}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ProjectList = ({ projects, title, subtitle, showLearnMore = true, transactionId, hideImpactButton }) => {
  const projectList = projects?.length > 0 ? projects
    .filter((a) => {
      if (!a.name) return;
      return !a.name.toLowerCase().includes('sasaboe');
    })
    .sort((a, b) => {
        return (a.portion && b.portion) ? b.portion - a.portion : a.rank - b.rank
    })
  : [];

  return (
    <section className='project-basket-list py-2'>
      {title && <div className='title'>{title}</div>}
      {subtitle && <span className='text-center mb-3'>{subtitle}</span>}
      {projectList
        ? projectList.map((project) => {
            return (
                <SingleProject
                  key={project.id}
                  project={project}
                  showLearnMore={showLearnMore}
                  transactionId={transactionId}
                  hideImpactButton={hideImpactButton}
                />
            );
          })
        : ''}
    </section>
  );
};

export default ProjectList;
