import * as types from "./actionTypes";
import * as config from "../../../config";
const { getImpactAllApi} = require("@carbonclick/carbon-api-client/src/api/impactApi");
import { extractApiError } from '../../util/errorExtractor'
import { errorHandlerActions } from './errorHandlerActions';
import { beginApiCall } from "./apiStatusActions";

export function getAllImpactSuccess(impactAllStats) {
  return { type: types.IMPACT_ALL_SUCCESS, impactAllStats };
}

export function getAllImpactFailed(error) {
  return { type: types.IMPACT_ALL_FAILED, error };
}

export function getAllImpact(locale) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return getImpactAllApi(locale)
      .then(impactAllStats => {
        dispatch(getAllImpactSuccess(impactAllStats));
      })
      .catch(error => {
        const status = extractApiError(error).status;
        const sentryMessage = `${config.deployment_env} impactAllApi: api call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(getAllImpactFailed(error));
      });
  };
}
