import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function impactRoadTripReducer(
  state = initialState.impactRoadTrip,
  action
) {
  switch (action.type) {
    case types.ROAD_TRIP_IMPACT_SUCCESS:
      return action.impactRoadTrip;
    case types.ROAD_TRIP_IMPACT_FAILED:
      return action.error;
    default:
      return state;
  }
}