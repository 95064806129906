import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function paymentReducer(
  state = initialState.payment,
  action
) {
  switch (action.type) {
    case types.GET_PURCHASES_BY_USER_SUCCESS:
      return ({...state, all: action.purhases});
    case types.GET_PURCHASES_BY_USER_FAILED:
      return ({...state, error: action.error});
    default:
      return state;
  }
}