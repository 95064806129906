import React, {useState} from "react";
import PropTypes from "prop-types";
import VerifyCodeAndNewPassword from "./VerifyCodeAndNewPassword";
import CCButton from "../../common/CCButtonV3";
import { Modal } from "antd";
import * as link from '../../common/pages'
import '../style.css'
import { forgotPassword, forgotPasswordSubmit, signIn } from '../authentication';

const ResetPassword = ({showModal, setModalVisible,email}) => {
  const [resetPasswordMessage, setResetPasswordMessage] = useState("");
    const [requestForPasswordDone, setRequestForPasswordDone] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [resetCode, setResetCode] = useState("");
  const [isNewPasswordOk, setNewPasswordIsOk] = useState(false);
    const [verificationError, setVerificationError] = useState("");

    const handleSendResetEmail = () => {
        doResetPassword();
      };
    
      const handleSubmitCodeAndPassword = () => {
        doSubmitCodeAndPassword();
      };
    
      const handleCancel = () => {
        setModalVisible(false);
      };

      async function doResetPassword() {
        forgotPassword(email)
            .then((data) => {
                if (data.CodeDeliveryDetails && data.CodeDeliveryDetails.Destination)
                    setResetPasswordMessage(
                        `If we found your account, we'll send you a password reset code to ${data.CodeDeliveryDetails.Destination}. Enter it below to reset your password.`
                    );
                setRequestForPasswordDone(true);
            })
            .catch((err) => {
                setResetPasswordMessage(err.message)
                setRequestForPasswordDone(false);
                console.log(err)
            });
      }

      async function doSubmitCodeAndPassword() {
        forgotPasswordSubmit(email.toLowerCase(), resetCode, newPassword)
          .then(() => {
            signIn(email.toLowerCase(), newPassword).then(() => {
              window.location = link.DASHBOARD;
            });
          })
          .catch(() => setVerificationError('Invalid reset code provided, please try again.'));
      }

    return <>
        <Modal
            onCancel={handleCancel}
            title="Forgot Password?"
            visible={showModal}
            footer={[
                <CCButton key="back"
                    buttonName={"Cancel"}
                    onClickButton={handleCancel}
                    size="large"
                    type="default"
                    block={false}
                />,
                <CCButton key="submit" type="primary"
                    isDisabled={requestForPasswordDone && (!isNewPasswordOk || resetCode.length < 3)}
                    onClickButton={requestForPasswordDone ? handleSubmitCodeAndPassword : handleSendResetEmail}
                    buttonName={requestForPasswordDone ? `Continue` : `Reset Password`}>
                </CCButton>
            ]}
        >
        {requestForPasswordDone ? (
            <VerifyCodeAndNewPassword
                email={email}
                resetPasswordMessage={resetPasswordMessage}
                doSubmitCodeAndPassword={handleSubmitCodeAndPassword}
                setResetCode={setResetCode}
                setNewPassword={setNewPassword}
                resetCode={resetCode}
                newPassword={newPassword}
                setNewPasswordIsOk={setNewPasswordIsOk}
                    verificationError={verificationError}
            />
        ) : (
                <div className="reset-email-message">
                    <span >{`To reset the password for `}</span>
                    <strong>{email}</strong>
                    <span>{`, click the reset password button.`}</span>
                    <div className="div-spacing">
                        {resetPasswordMessage && <span style={{ color: "red" }}>{resetPasswordMessage}</span>}
                    </div>
                </div>
            )}
        </Modal></>
}

ResetPassword.propTypes = {
    email: PropTypes.string,
    showModal: PropTypes.bool,
    setModalVisible: PropTypes.func,
    isMobileDevice: PropTypes.bool
  };

export default ResetPassword;