import * as types from "./actionTypes";
import * as config from "../../../config";
const {
  getMerchantsPageApi, getMerchantsCountApi, getMerchantByCodeApi, getMerchantByIdApi, industryCodeForMerchantApi
} = require("@carbonclick/carbon-api-client/src/api/merchantApi");
import { extractApiError } from '../../util/errorExtractor'
import { beginApiCall } from "./apiStatusActions";
import { errorHandlerActions } from './errorHandlerActions';


export function getMerchantsPageAction() {
  return { type: types.GET_MERCHANTS_PAGE  };
}

export function getMerchantsPageSuccess(page) {
  return { type: types.GET_MERCHANTS_PAGE_SUCCESS, page };
}

export function getMerchantsPageFailed(error) {
  return { type: types.GET_MERCHANTS_PAGE_FAILED, error };
}

export function getMerchantsCountSuccess(count) {
  return { type: types.GET_MERCHANTS_COUNT_SUCCESS, count };
}

export function getMerchantsCountFailed(error) {
  return { type: types.GET_MERCHANTS_COUNT_FAILED, error };
}

export function getMerchantCodeSuccess(merchant) {
  return { type: types.GET_MERCHANT_DETAILS_SUCCESS, merchant };
}

export function getMerchantCodeFailed(error) {
  return { type: types.GET_MERCHANT_DETAILS_FAILED, error };
}

export function getMerchantsPage(page, pageSize, category, shuffle) {
  const PLATFORMS = ['SHOPIFY','MAGENTO','WOOCOMMERCE'];
  const REQUIRED_FIELDS = ['shortDescription', 'thumbnailUrl']

  return function (dispatch) {
    dispatch(beginApiCall());
    dispatch(getMerchantsPageAction())
    return getMerchantsPageApi(page, pageSize || 20, category, REQUIRED_FIELDS, PLATFORMS, shuffle)
      .then(page => {
        dispatch(getMerchantsPageSuccess(page));
      })
      .catch(error => {
        const status = extractApiError(error).status;
        const sentryMessage = `${config.deployment_env} merchantActions.js : get merchant page api call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(getMerchantsPageFailed(error));
      });
  };
}

export function getMerchantsCount() {
  return function (dispatch) {
    dispatch(beginApiCall());
    return getMerchantsCountApi()
      .then(count => {
        dispatch(getMerchantsCountSuccess(count));
      })
      .catch(error => {
        const status = extractApiError(error).status;
        const sentryMessage = `${config.deployment_env} merchantActions.js : get merchants count api call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(getMerchantsCountFailed(error));
      });
  };
}

export function getMerchantByCode(code) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return getMerchantByCodeApi(code)
      .then(merchant => {
        dispatch(getMerchantCodeSuccess(merchant));
      })
      .catch(error => {
        const status = extractApiError(error).status;
        const sentryMessage = `${config.deployment_env} merchantActions.js : get merchant call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(getMerchantCodeFailed(error));
      });
  };
}

export function getMerchantById(id, pledge) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return getMerchantByIdApi(id, pledge)
      .then(merchant => {
        dispatch(getMerchantCodeSuccess(merchant));
      })
      .catch(error => {
        const status = extractApiError(error).status;
        const sentryMessage = `${config.deployment_env} merchantActions.js : get merchant call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(getMerchantCodeFailed(error));
      });
  };
}

export function updateMerchantIndustryByCode(code, industryCode) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return industryCodeForMerchantApi(code, industryCode)
      .then(merchant => {
        dispatch(getMerchantCodeSuccess(merchant));
      })
      .catch(error => {
        const status = extractApiError(error).status;
        const sentryMessage = `${config.deployment_env} merchantActions.js : get merchant call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(getMerchantCodeFailed(error));
      });
  };
}


