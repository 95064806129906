import * as types from "./actionTypes";
import * as config from "../../../config";
const {
  getCustomerApi, getCustomersApi, createCustomerApi
} = require("@carbonclick/carbon-api-client/src/api/customerApi");
import { beginApiCall } from "./apiStatusActions";
import {extractApiError} from "../../util/errorExtractor";
import { errorHandlerActions } from './errorHandlerActions';

export function getCustomerSuccess(customer) {
  return { type: types.GET_CUSTOMER_SUCCESS, customer };
}

export function getCustomerFailed(error) {
  return { type: types.GET_CUSTOMER_FAILED, error };
}

export function getCustomersSuccess(customers) {
  return { type: types.GET_CUSTOMERS_SUCCESS, customers };
}

export function getCustomersFailed(error) {
  return { type: types.GET_CUSTOMERS_FAILED, error };
}

export function getCustomerUserSuccess(customerExists) {
  return { type: types.GET_CUSTOMER_USER_SUCCESS, customerExists };
}

export function getCustomerUserFailed(error) {
  return { type: types.GET_CUSTOMER_USER_FAILED, error };
}

export function getCustomer(email) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return getCustomerApi(email)
      .then((customer) => {
        dispatch(getCustomerSuccess(customer));
      })
      .catch((error) => {
        const status = extractApiError(error).status;
        const sentryMessage = `${config.deployment_env} customerActions.js (email: ${email ? email : 'Not found'}): customer ${email && email} api call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(getCustomerFailed(error));
      });
  };
}

export function createCustomer(email, merchantCode, programmeCode) {
  return function (dispatch) {
    return createCustomerApi(email, merchantCode, programmeCode)
      .catch((error) => {
        const status = extractApiError(error).status;
        const sentryMessage = `${config.deployment_env}  customerActions.js (email: ${email ? email : 'Not found'}): post customer api call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(getCustomerFailed(error));
      });
  }
}

export function getCustomers(email) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return getCustomersApi(email)
      .then((customers) => {
        dispatch(getCustomersSuccess(customers));
      })
      .catch((error) => {
        const status = extractApiError(error).status;
        const sentryMessage = `${config.deployment_env} customerActions.js (email: ${email ? email : 'Not found'}): customers ${email && email} api call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(getCustomersFailed(error));
      });
  };
}