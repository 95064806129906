import initialState from "./initialState";
import * as types from "../actions/actionTypes";

export default function personalFootprintReducer(state = initialState.personalFootprintData , action) {
  switch (action.type) {
    case types.GET_PERSONAL_FOOTPRINT_SUCCESS:
      return action.footprintData;
    case types.GET_PERSONAL_FOOTPRINT_FAILED:
      console.log('GET_PERSONAL_FOOTPRINT_FAILED'+JSON.stringify(action.error));
      return action.error;
    default:
      return state;
  }
}
