import initialState from "./initialState";
import * as types from "../actions/actionTypes";

export default function transactionsReducer(
    state =  initialState.transactions || null ,
    action
) {
    switch (action.type) {
        case types.GET_TRANSACTIONS_BY_MERCHANT_CODE_PAGE:
            return initialState.transactions;
        case types.GET_TRANSACTIONS_BY_MERCHANT_CODE_PAGE_FAILED:
            return action.error;
        case types.GET_TRANSACTIONS_BY_MERCHANT_CODE_PAGE_SUCCESS:
            return action.page;
        default:
            return state;
    }
}
