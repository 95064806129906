import merge from "deepmerge";
import aviationBase from "./aviationBase";

const overwriteMerge = (destinationArray, sourceArray) => {
    return sourceArray;
}

const overrides = {
    code:'AMADEUS_GLOBAL',
    isAviationUser: true,
    content: {
        menu: {
            url: 'https://amadeus.carbon.click/',
        },
        signIn: {
            business: {
                headerText: "Power up your journey to becoming a Climate Friendly Travel Agent",
                formHeaderText: "Sign up to the Travel Agent Portal",
                backgroundImage: "url('https://res.cloudinary.com/carbonclick/image/upload/v1657032996/White%20Label/Amadeus/rain-forest-from-air-near-kuranda-queensland_xgy5dm.jpg')",
                highlightsData: {
                    title1: "View your offsetting history and impact",
                    title2: "Share the good you are doing for the planet",
                    title3: "Access resources to engage your customers",
                }
            },
        },
        dashboard: {
            business: {
                items: ['content', 'recentOffsets', 'supportedProjectsPanel'],
                merchantContent: {
                    items: [
                        {
                            title: "The need for climate friendly travel",
                            image: 'https://res.cloudinary.com/carbonclick/image/upload/v1621554634/MyCC/Group_86_t9pppn.png',
                            text:`
                                <p>Access key insights into travel’s impact on the climate and travellers attitudes towards climate change.</p>
                                <p>Understand what’s key in engaging your customers in meaningful climate action.</p>`,
                            buttonText: "View insights",
                            link: "https://www.carbonclick.com/amadeus-carbonclick-insights-infographic-english/",
                        },
                        {
                            title: "Become a Climate Friendly Travel Agent",
                            image: 'https://res.cloudinary.com/carbonclick/image/upload/v1621554633/MyCC/Group_87_ux638o.png',
                            text: `
                                <p><b>Why </b>is a climate friendly approach to travel important?</p>
                                <p><b>What </b>does CarbonClick enable you and your customers to do?</p>
                                <p><b>How </b>can you engage your customers?</p>`,
                            buttonText: "Get started",
                            link: "https://www.carbonclick.com/amadeus-carbonclick-quickcard-english/",
                        },
                        { 
                            title: "Engage your customers",
                            image: 'https://res.cloudinary.com/carbonclick/image/upload/v1621554632/MyCC/Group_88_wcwvqc.png',
                            text: `
                                <p>Find out how to start the dialogue with your customers.</p>
                                <p>Download and bookmark this handy tipsheet.</p>`,
                            buttonText: "Download tipsheet",
                            link: "https://www.carbonclick.com/amadeus-carbonclick-tipsheet-english/",
                        },
                    ]
                },
            },
        },
        logo: {
            link: 'https://amadeus.carbon.click/'
        }
    }
}

export default merge(aviationBase,overrides, { arrayMerge: overwriteMerge });
