import * as types from "./actionTypes";
import * as config from "../../../config";
const {getBasketsApi, getBasketByIdApi} = require("@carbonclick/carbon-api-client/src/api/projectsApi");
import { beginApiCall } from "./apiStatusActions";
import { errorHandlerActions } from './errorHandlerActions';
import { extractApiError } from '../../util/errorExtractor'

export function getBasketsSuccess(baskets) {
    return { type: types.GET_BASKETS_SUCCESS, baskets };
}

export function getBasketsFailed(error) {
    return { type: types.GET_BASKETS_FAILED, error };
}

export function getBasketSuccess(basket) {
    return { type: types.GET_BASKET_SUCCESS, basket };
}

export function getBasketFailed(error) {
    return { type: types.GET_BASKET_FAILED, error };
}

export function getBaskets(codes = null, includePublic = null) {
    return function (dispatch) {
        dispatch(beginApiCall());
        return getBasketsApi(codes, includePublic)
            .then(baskets => {
                dispatch(getBasketsSuccess(baskets));
            })
            .catch(error => {
                const status = extractApiError(error).status;
                const sentryMessage = `${config.deployment_env} basketActions.js baskets api call error ${JSON.stringify(extractApiError(error))}`;
                dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
                dispatch(getBasketsFailed(error));
            });
    };
}


export function getBasketById(id) {
    return function (dispatch) {
        dispatch(beginApiCall());
        return getBasketByIdApi(id)
            .then(basket => {
                dispatch(getBasketSuccess(basket));
            })
            .catch(error => {
                const status = extractApiError(error).status;
                const sentryMessage = `${config.deployment_env} basketActions.js basket api call error ${JSON.stringify(extractApiError(error))}`;
                dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
                dispatch(getBasketFailed(error));
            });
    };
}
