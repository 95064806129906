export const INPUT_STYLE = {
  height: "auto",
  padding: 0,
  fontSize: "20px",
  lineHeight: "1.3em",
  width: "100%",
  color: "black",
  "::placeholder": {
    color: "#aab7c4"
  },
  border: 0,
  outline: 0
};

export const DIV_SPACING = { paddingTop: 20 };

export const INPUT_ERROR_STYLE = {
  border: "2px solid #ec0606"
};

export const INPUT_WRAPPER_STYLE = {
  padding: "14px 12px 12px 12px",
  borderRadius: 4,
  backgroundColor: "#ebeced",
  border: "1px solid lightgrey",
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};

export const LABEL = {
  position: "relative",
  top: "1em",
  zIndex: "99",
  left: 10,
  paddingLeft: 4,
  paddingRight: 4,
  fontSize: 11
};

export const MOBILE_STYLE = {
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 10
};
