import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function cognitoReducer(state = initialState.currentUserCognito,action) {
    switch (action.type) {
        case types.AUTHENTICATED_USER_SUCCESS:
            return action.currentUserCognito;
        case types.AUTHENTICATED_USER_PROFILE_PICTURE:
            return {...state, profilePictureUrl: action.profilePictureUrl};
        case types.AUTHENTICATED_USER_FAILED:
            return action.error;
        case types.AUTHENTICATED_USER_RESET:
            return action.currentUserCognito
        default:
            return state;
    }
}
