import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function impactGroupsReducer(
  thisInitialState = initialState.groupImpacts ? initialState.groupImpacts : null,
  action
) {
  switch (action.type) {
    case types.GROUP_IMPACT_SUCCESS:{
      const newState=[action.groupImpact];
      for(const oldImpact of (thisInitialState ? thisInitialState : [])){ //null safe
          if (oldImpact.groupId!==action.groupImpact.groupId) newState.push(oldImpact);
      }
      return newState;
    }
    case types.GROUP_IMPACT_FAILED:
      return action.error;
    default:
      return thisInitialState;
  }
}