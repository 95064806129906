import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function groupFootprintsStatsReducer(
  thisInitialState = initialState.groupFootprintsStats ? initialState.groupFootprintsStats : null,
  action
) {
  switch (action.type) {
    case types.GROUP_FOOTPRINTS_STATS_SUCCESS:{
      return action.groupFootprintsStats;
    }
    case types.GROUP_FOOTPRINTS_STATS_FAILED:
      return action.error;
    default:
      return thisInitialState;
  }
}