import React from 'react';
import { Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const Tooltip = ({ color, content, title }) => {
  return (
    <Popover
      overlayStyle={{ maxWidth: '500px', minWidth: '300px' }}
      className='popover-question'
      content={<span className='translate-dynamic'>{content}</span>}
      title={title && <span className='translate-dynamic'>{title}</span>}
      trigger="click"
    >
      <span className="larger-click-area">
        <span className='popover-question-text'>
          <FontAwesomeIcon color={color} icon={faInfoCircle} />
        </span>
      </span>
    </Popover>
  );
};

export default Tooltip;
