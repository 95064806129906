import React from 'react';
import {Col} from "antd";
import PropTypes from "prop-types";

const GuestPageContainer = ({children, style}) => (
  <Col span={12} className="content-main" style={{ textAlign: "center", marginTop: 0, height: '100vh'}}>
    <div style={{display: 'flex', flexDirection: 'column', height: '100%', alignItems: 'center'}}>
      <div style={{
        flexGrow: 1,
        maxHeight: 130
      }}> </div>
      <div style={{
        flexGrow: 0,
        padding: 10,
        maxWidth: 560,
        width: '100%',
        display: "inline-block",
        textAlign: 'justify',
        ...style
      }}>{children}</div>
    </div>
  </Col>
);

GuestPageContainer.propTypes = {
  children: PropTypes.array.isRequired,
  style: PropTypes.array
};

export default GuestPageContainer;