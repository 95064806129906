import React, {useContext, useState} from 'react';
import PropTypes from "prop-types";
import { Divider, Space } from 'antd';

import {formatPriceTextOnly, formatPriceTextOnlyWithSymbolAndCode} from '../../util/priceFormatter';
import CustomButton from '../../components/controls/CustomButton';
import themedImpactSVGs from '../../images/impact/ThemedSVGs';
import Tooltip from '../Tooltip';
import { getFormattedDate } from '../../util/dateFormatter';
import UserInfoContext from "../../util/UserInfoContext";

const DisplayDetails = ({ transactData, transactImpact, locale }) => {
  const { userInfo } = useContext(UserInfoContext);

  const [showCostBreakdown, setShowCostBreakdown] = useState(false);

	const paymentProviderFeeInfo = "Charged by the payment provider for secure credit card payment processing";
  const serviceFeeInfo = "Covers the expenses for checking, auditing, onboarding, and maintaining a supply of high-quality carbon credits"
	const netAmount = transactData && transactData.netAmount;
	const paymentProviderFee = transactData && transactData.paymentProviderFee;
	const serviceFee = transactData && transactData.serviceFee;
	const totalAmountCharged = transactData && transactData.totalAmountCharged;

  const carbonOffsetImpact = transactImpact && transactImpact.carbonOffsetImpact;
    const formatPricing = (price, showCode) => {
        if (price) {
            return showCode ? formatPriceTextOnlyWithSymbolAndCode(price) : formatPriceTextOnly(price) ;
        } else return `$--.--`;
    }

  const isPricingExist = (fee) => fee?.value > 0;
  const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };

  //Go-launch hack for Etihad - hide the payment info for invoice (0 payment fee)
  const shouldDisplayPaymentInformation = userInfo?.currentMerchant?.code!=='ETIHAD_EY' || isPricingExist(paymentProviderFee);


  const Wrapper = ({ sx, children }) => (
    <div className='d-flex gap-4 justify-between mb-1' style={{ ...sx }}>
      {children}
    </div>
  );

  return (
    <div className='transact-details text-center'>
      <h2 className='accent-dark-color fw-700 mb-3'>
        {carbonOffsetImpact?.value ? <>You’ve offset{' '}
          <span className='no-translate'>
            {!isNaN(carbonOffsetImpact?.value) ? Math.round(carbonOffsetImpact?.value).toLocaleString() : ''}{' '}
            {carbonOffsetImpact?.unit ? carbonOffsetImpact?.unit?.toLowerCase() : ''}{' '}
          </span>{' '}
          of CO₂</>
        : 'Something went wrong'}
      </h2>
        {
            userInfo?.currentMerchant?.code !== 'ETIHAD_EY' && transactImpact?.treeGrowthYears?.value && <p className={'translate-dynamic'}>That’s the same amount of carbon emissions that {(transactImpact?.treeGrowthYears?.value).toLocaleString()} trees absorb in one year.</p>
        }

      {transactData ? (<>
      <div style={{ maxWidth: 280, margin: '1.5rem auto' }}>
        <Wrapper>
          <b className='text-left'>Reference</b>
          <p className='no-translate text-right'>{transactData?.reference}</p>
        </Wrapper>
        <Wrapper>
          <b className='text-left'>Transaction date</b>
          <p className='no-translate text-right'>
            {!Number.isNaN(transactData?.transactionDate)
              ? getFormattedDate(transactData?.transactionDate, locale, dateOptions)
              : '--- -- --- ----'}
          </p>
        </Wrapper>
        <Wrapper>
          <b className='text-left'>CO₂ amount</b>
          <p className='no-translate text-right'>
            {Math.round(transactImpact?.carbonOffsetImpact?.value).toLocaleString(undefined, { maximumFractionDigits: 0 })}{' '}
            {transactImpact?.carbonOffsetImpact?.unit?.toLowerCase()}
          </p>
        </Wrapper>
        {shouldDisplayPaymentInformation &&
        <Wrapper>
          <b className='text-left'>Cost</b>
          <p className='no-translate text-right'>
            {formatPricing(totalAmountCharged, true)}
          </p>
        </Wrapper>}
      </div>

      {shouldDisplayPaymentInformation && !showCostBreakdown && (
        <CustomButton
          text='Show full cost breakdown'
          type='link'
          onClick={() => setShowCostBreakdown(true)}
          icon={themedImpactSVGs.cubes()}
          color='dark'
        />
      )}

      {showCostBreakdown && (
        <Space
          direction='vertical'
          size='middle'
          style={{ maxWidth: 500, width: '100%', margin: '0 auto' }}
          className='accent-light-bg p-3 cost-breakdown'
        >
          <div>
            <Wrapper>
              <p className='text-left'>Project contributions</p>
              <p className='value no-translate'>{formatPricing(netAmount)}</p>
            </Wrapper>
            <Wrapper>
              <p className='text-left'>Tax</p>
              <p className='value no-translate'>{formatPriceTextOnly({
                  currency: transactData?.totalAmountCharged?.currency,
                  symbol: '',
                  value: 0,
                  zeroDecimalCurrency: transactData?.totalAmountCharged?.zeroDecimalCurrency,
              })}</p>
            </Wrapper>
            {isPricingExist(paymentProviderFee) && (
              <Wrapper>
                <p className='text-left'>Payment provider fee
                  <Tooltip content={paymentProviderFeeInfo} />
                </p>
                <p className='value no-translate'>{formatPricing(paymentProviderFee)}</p>
              </Wrapper>
            )}
            {isPricingExist(serviceFee) && (
              <Wrapper>
                <p className='text-left'>Service fee
                  <Tooltip content={serviceFeeInfo} />
                </p>
                <p className='value no-translate'>{formatPricing(serviceFee)}</p>
              </Wrapper>
            )}
          </div>

          <Divider className='m-0' />

          <Wrapper className='text-left'>
            <b>Total</b>
            <p className='value no-translate'>{formatPricing(totalAmountCharged)}</p>
          </Wrapper>
        </Space>
      )}
      </>)
      : <p>Oops, looks like something went wrong. We couldn’t find the transaction you’re looking for.</p>
      }
      
    </div>
  );
};

DisplayDetails.propTypes = {
    transactData: PropTypes.object,
    transactImpact: PropTypes.object
};

export default DisplayDetails;