import initialState from "./initialState";
import * as types from "../actions/actionTypes";

export default function flightsReducer(state =  initialState.flights || null , action) {
  switch (action.type) {
    case types.GET_FLIGHTS_SUCCESS:
      return action.flights;
    case types.GET_FLIGHTS_FAILED:
      return action.error;
    case types.GET_FLIGHTS:
    case types.GET_FLIGHTS_BY_MERCHANT_CODE_PAGE:
      return initialState.flights;
    default:
      return state;
  }
}
