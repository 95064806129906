import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function merchantUserReducer(
  state = initialState.merchantUser,
  action
) {
  switch (action.type) {
    case types.GET_MERCHANT_USER:
      return initialState.merchantUser;
    case types.GET_MERCHANT_USER_FAILED:
      return action.error;
    case types.GET_MERCHANT_USER_SUCCESS:
      return action.merchantUser;
    default:
      return state;
  }
}
