import {getDataByCountryCode, getDataByCountryName} from '../components/common/impact/data/CountryData'

export const getCountryName = (countryCode) => {
    const country = getDataByCountryCode(countryCode.toUpperCase());
    
    return country ? country.name : "Unknown";
}

export const getCountryCodeFromName = (countryName) => {
    const countryCode = getDataByCountryName(countryName.toUpperCase());

    return countryCode ? countryCode.code : "XX";
}