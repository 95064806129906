
export default
    {
        code: 'BES_AU_RETAIL_GLOBAL',
        content: {
            menu: {
                url: 'https://rg.carbon.click/'
            },
            dashboard: {
                business: {
                    merchantContent: {
                        items: [
                            {
                                title: "Start your climate journey",
                                image: "https://res.cloudinary.com/carbonclick/image/upload/v1638312165/White%20Label/Retail%20Global/rg-content_qmplvy.png",
                                text: "Grab our sustainability guide to help you take action as an e-commerce business.",
                                link: "https://drive.google.com/file/d/1PXy1jQie_9dhE-gJisx8_z1nfzZ8Qmya/view?usp=sharing",
                                buttonText: "Get the guide",
                                contentText: `
                                    <h3><b>E-commerce</b></h3>
                                    <h3>Sustainability Guide</h3>
                                `
                            },
                            {
                                title: "We’re taking climate action",
                                image: "https://res.cloudinary.com/carbonclick/image/upload/v1638312165/White%20Label/Retail%20Global/rg-content_qmplvy.png",
                                text: "We’re on a mission to help the industry meet consumer needs and are bringing you along the journey!",
                                link: "https://vimeo.com/528094410",
                                buttonText: "Watch the video",
                                contentText: `
                                    <h3><b>We’re taking</b></h3>
                                    <div><h3>Climate Action</h3></div>
                                    <div>[Video]</div> 
                                `
                            },
                            {
                                title: "Why take action?",
                                image: "https://res.cloudinary.com/carbonclick/image/upload/v1638312165/White%20Label/Retail%20Global/rg-content_qmplvy.png",
                                text: "Get the infographic with top insights about why to take climate action and engage your customers.",
                                link: "https://drive.google.com/file/d/1T1IIrSX_7S9hFf-CZhvfSNw1i5t3cpX5/view?usp=sharing",
                                buttonText: "View the infographic",
                                contentText: `
                                    <h3><b>Consumer Insights</b></h3>
                                    <div>[Infographic]</div>
                                `
                            },
                            {
                                title: "Customer’s needs are changing",
                                image: "https://res.cloudinary.com/carbonclick/image/upload/v1638312165/White%20Label/Retail%20Global/rg-content_qmplvy.png",
                                text: "How do you meet customer needs in building a sustainable eCommerce brand?",
                                link: "https://www.carbonclick.com/meet-customer-needs-sustainable-ecommerce/",
                                buttonText: "Read the blog",
                                contentText: `
                                    <h3><b>Customer needs</b></h3>
                                    <div>[Blog]</div>
                                `
                            },
                        ]
                    },
                }
            }
        }
    }
