export default
{
    content: {
        menu: {
            business: {
                items: ['dashboard','myAccount','flightCalculator', 'flightContributions', 'projects',]
            }
        },
        dashboard: {
            business: {
                items: ['content', 'recentOffsets', 'supportedProjectsPanel'],

            }
        }
    }
}