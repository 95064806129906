export const isMobile = (useMediaQuery) => {
  return useMediaQuery({ query: "(max-width: 840px)" });
};

export const xSmallBreakpoint = (useMediaQuery) => {
  return useMediaQuery({ query: "(max-width: 576px)" });
};

export const x800Breakpoint = (useMediaQuery) => {
  return useMediaQuery({ query: "(max-width: 800px)" });
};