import React from "react";
import { Row } from "antd";
import "./style.css";

const validatePassword = (password)=> {
        let isLowerCase = password.toUpperCase() !== password;
        let isUpperCase = password.toLowerCase() !== password;
        let isTrimmed = password.trim() == password;
        let hasNumber = /\d/.test(password);
        let atleast8Char = password.length >= 8;

        const passwordChecker = [
          {
            text: "Password must contain a lower case character",
            status: isLowerCase
          },
          {
            text: "Password must contain a upper case character",
            status: isUpperCase
          },
          {
            text: "Password must contain a number",
            status: hasNumber
          },
          {
            text: "Password must contain atleast 8 characters",
            status: atleast8Char
          },
          {
            text: "Password must not start or end with a space",
            hideWhenValid: true,
            status: isTrimmed
          }
        ];

        const isPasswordValid = (isLowerCase && isUpperCase && hasNumber && atleast8Char && isTrimmed);

        return {
            isPasswordValid,
            passwordChecker
        }
      };

      export const DisplayValidation = ({passwordValidator}) => {
         return passwordValidator && passwordValidator.map((check, idx) => {
           return check.status && check.hideWhenValid ? null : (
                <Row
                  style={{
                    color: check.status === false ? "red" : "green",
                    fontSize: 14
                  }}
                  key={idx}
                >
                  {check.text}
                </Row>
            );
          });
      }

export default validatePassword;