import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function makePaymentReducer(state = initialState.makePayment,action) {
  switch (action.type) {
    case types.PAYMENT_SUCCESS:
      return action.payment;
    case types.PAYMENT_FAILED:
      return action.error;
    case types.UPDATE_PAYMENT_EMAIL_SUCCESS:
      return action.update_payment;
    case types.UPDATE_PAYMENT_EMAIL_FAILED:
      return action.error;
    case types.RESET_PAYMENT:
      return null;
    default:
      return state;
  }
}