/*
These are mostly pulled from the .gitlab-ci.yml or other GitLab environment variables. 
WebPack will replace these at build time.
In local build, the defaults and .env files will be used.
*/
module.exports = {
  cognito_region: process.env.COGNITO_REGION,
  cognito_userPoolId: process.env.COGNITO_USER_POOL_ID,
  cognito_userPoolWebClientId: process.env.COGNITO_USER_POOL_CLIENT_ID, //cognito-app-authentication-userPool
  cognito_domain: process.env.COGNITO_DOMAIN,
  cognito_guest_domain: process.env.COGNITO_GUEST_DOMAIN,
  cognito_signInUrl: process.env.COGNITO_SIGN_IN_URL,
  cognito_signOutUrl: process.env.COGNITO_SIGN_OUT_URL,
  cognito_guestUserPoolId: process.env.COGNITO_GUEST_USER_POOL_ID, //cognito-api-authentication-userPool
  cognito_guestUserPoolWebClientId: process.env.COGNITO_GUEST_USER_POOL_CLIENT_ID,
  cognito_guestUsername: process.env.COGNITO_GUEST_USERNAME,
  cognito_guestPassword: process.env.COGNITO_GUEST_PASSWORD,

  google_client_id: process.env.GOOGLE_CLIENT_ID
};
