import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function impactAllReducer(
  state = initialState.impactAllStats
    ? initialState.impactAllStats
    : [],
  action
) {
  switch (action.type) {
    case types.IMPACT_ALL_SUCCESS:
      return action.impactAllStats;
    case types.IMPACT_ALL_FAILED:
      return action.error;
    default:
      return state;
  }
}
