import React, { useState } from "react";
import { Row, Col } from "antd";
import PropTypes from "prop-types";
import eye_regular from "../../../images/eye-regular.svg";
import eye_slash_solid from "../../../images/eye-slash-solid.svg";
import "../style.css";
import passwordValidator, {DisplayValidation} from "../passwordValidator";

const VerifyCodeAndNewPassword = ({ resetPasswordMessage, resetCode, newPassword, setNewPassword, setResetCode, setNewPasswordIsOk, verificationError}) => {

  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [validatePassword, setValidatePassword] = useState({
    isPasswordValid: false,
    passwordChecker: null
  })

  const handleResetCodeChange = (e) => {
    const { value } = e.target;
    setResetCode(value);
  };

  const handleNewPassChange = (e) => {
    const { value } = e.target;

    const validatePass = passwordValidator(value);
    setValidatePassword(validatePass);

    if (validatePass.isPasswordValid ) {
      setNewPasswordIsOk(true);
    } else setNewPasswordIsOk(false);
    setNewPassword(value);
  };

  return (
    <>
      <div className="reset-email-message">{resetPasswordMessage} </div>
      <div className="div-spacing" />
      <div className="reset-email-message-sub-text">
        {`Didn't receive it? We may have not found your account. Check your spam, or try logging in with Facebook or Google.`}</div>
      <div className="reset-email-message-sub-text">{`Need help? Email us at `} <a href="mailto:support@carbonclick.com">support@carbonclick.com</a> </div>
      <div className="div-spacing" />
      <div className="inputWrapper">
        <input
          type="number"
          className="input-style"
          value={resetCode}
          onChange={handleResetCodeChange}
          placeholder="Code"
        />
      </div>
      <div className="div-spacing"/>
      <div className="inputWrapper">
            <Row>
              <Col span={22}>
                <input
                  className="input-style"
                  type={!isPasswordVisible ? "password" : "text"}
                  value={newPassword}
                  onChange={handleNewPassChange}
                  placeholder="Enter new password"
                />
              </Col>
              <Col span={2}>
                <img
                  onClick={() => setPasswordVisible(!isPasswordVisible)}
                  style={{ width: 20 }}
                  src={isPasswordVisible ? eye_slash_solid : eye_regular}
                />
              </Col>
            </Row>
          </div>
      {!validatePassword.isPasswordValid && <DisplayValidation passwordValidator={validatePassword.passwordChecker} />}
      <div className="div-spacing">
        {verificationError && <span style={{ color: "red" }}>{verificationError}</span>}
      </div>
    </>
  );
};

VerifyCodeAndNewPassword.propTypes = {
  resetPasswordMessage: PropTypes.string,
  resetCode: PropTypes.string, 
  newPassword: PropTypes.string, 
  setNewPassword: PropTypes.string, 
  setResetCode: PropTypes.func, 
  setNewPasswordIsOk: PropTypes.func,
  verificationError: PropTypes.string
};


export default VerifyCodeAndNewPassword;
