import * as types from "./actionTypes";
import * as config from "../../../config";
const { createGroupApi, getGroupMembershipsByEmailApi, getGroupByReferenceApi, joinGroupByReferenceAndEmailApi, updateGroupByReferenceApi, getGroupByIdApi} = require("@carbonclick/carbon-api-client/src/api/groupApi");
import { extractApiError } from '../../util/errorExtractor'
import { beginApiCall, beginGroupApiCall } from "./apiStatusActions";
import { errorHandlerActions } from './errorHandlerActions';


export function createGroupReset() {
  return { type: types.CREATE_GROUP };
}

export function createGroupSuccess(group) { 
  return { type: types.CREATE_GROUP_SUCCESS, group };
}

export function createGroupFailed(error) {
  return { type: types.CREATE_GROUP_FAILED, error };
}

export function joinGroupReset() {
  return { type: types.JOIN_GROUP };
}

export function joinGroupSuccess(group) { 
  return { type: types.JOIN_GROUP_SUCCESS, group };
}

export function joinGroupFailed(error) {
  return { type: types.JOIN_GROUP_FAILED, error };
}

export function getGroupMembershipsReset() {
  return { type: types.GET_GROUP_MEMBERSHIPS};
}

export function getGroupMembershipsSuccess(response) {
  return { type: types.GET_GROUP_MEMBERSHIPS_SUCCESS, groupMemberships: response.memberships};
}

export function getGroupMembershipsFailed(error) {
  return { type: types.GET_GROUP_MEMBERSHIPS_FAILED, error };
}

export function getGroupSuccess(group) {
  return { type: types.GET_GROUP_SUCCESS, group};
}

export function getGroupFailed(error) {
  return { type: types.GET_GROUP_FAILED, error };
}

export function createGroup(group) {
  return function (dispatch) {
    dispatch(beginApiCall());
    dispatch(createGroupReset());
    return createGroupApi(group)
      .then(response => {
        dispatch(createGroupSuccess(response));
      })
      .catch(error => {
        const status = extractApiError(error).status;
        const sentryMessage = `${config.deployment_env} groupActions.js : create group (name: ${group && group.name && group.name}) api call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(createGroupFailed(error));
      });
  };
}

export function getGroupMembershipsByEmail(email) {
  return function (dispatch) {
    dispatch(beginGroupApiCall());
    dispatch(getGroupMembershipsReset());
    return getGroupMembershipsByEmailApi(email)
      .then((response) => {
        dispatch(getGroupMembershipsSuccess(response));
      })
      .catch((error) => {
        const status = extractApiError(error).status;
        const sentryMessage = `${config.deployment_env} groupActions.js : get group (email: ${email && email}) memberships api call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(getGroupMembershipsFailed(error));
      });
  };
}

export function getGroupByReference(reference) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return getGroupByReferenceApi(reference)
      .then((response) => {
        //API changed to (correctly) return a list for this endpoint, rather than a single entity
        const groupToUse = response?.content ? response.content[0] : response;
        dispatch(getGroupSuccess(groupToUse));
      })
      .catch((error) => {
        const status = extractApiError(error).status;
        const sentryMessage = `${config.deployment_env} groupActions.js : get group reference (reference: ${reference && reference}) api call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(getGroupFailed(error));
      });
  };
}

export function getGroupById(groupId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return getGroupByIdApi(groupId)
      .then((response) => {
        dispatch(getGroupSuccess(response));
      })
      .catch((error) => {
        const status = extractApiError(error).status;
        const sentryMessage = `${config.deployment_env} groupActions.js : get group (groupId: ${groupId && groupId}) api call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(getGroupFailed(error));
      });
  };
}

export function updateGroupByReference(group) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return updateGroupByReferenceApi(group)
      .then((response) => {
        dispatch(getGroupSuccess(response));
      })
      .catch((error) => {
        const status = extractApiError(error).status;
        const sentryMessage = `${config.deployment_env} groupActions.js : put group (name: ${group && group.name && group.name}) api call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(getGroupFailed(error));
      });
  };
}

export function joinGroupByReferenceAndEmail(reference, email, deleteOtherMemberships) {
  return function (dispatch) {
    dispatch(beginApiCall());
    dispatch(joinGroupReset());
    return joinGroupByReferenceAndEmailApi({reference,email,deleteExistingMemberMemberships:deleteOtherMemberships})
      .then((response) => {
        dispatch(joinGroupSuccess(response));
      })
      .catch((error) => {
        const status = extractApiError(error).status;
        const sentryMessage = `${config.deployment_env} groupActions.js : join group (reference: ${reference && reference}) api call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(joinGroupFailed(error));
      });
  };
}