/* eslint-disable */

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import Constants from './constants';
export default function withAmazon(Comp) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(props) {
            var _this = _super.call(this, props) || this;
            _this.initAmazon = _this.initAmazon.bind(_this);
            _this.signIn = _this.signIn.bind(_this);
            _this.signOut = _this.signOut.bind(_this);
            _this.federatedSignIn = _this.federatedSignIn.bind(_this);
            _this.state = {};
            return _this;
        }
        class_1.prototype.signIn = function () {
            var _this = this;
            var amz = window.amazon;
            var options = { scope: 'profile' };
            amz.Login.authorize(options, function (response) {
                if (response.error) {
                    return;
                }
                var payload = {
                    provider: Constants.AMAZON,
                };
                try {
                    localStorage.setItem(Constants.AUTH_SOURCE_KEY, JSON.stringify(payload));
                }
                catch (e) {
                  console.error(e)
                }
                _this.federatedSignIn(response);
            });
        };
        class_1.prototype.federatedSignIn = async function (response) {
            const { Auth } = await import("aws-amplify");
            var access_token = response.access_token, expires_in = response.expires_in;
            var onStateChange = this.props.onStateChange;
            var date = new Date();
            var expires_at = expires_in * 1000 + date.getTime();
            if (!access_token) {
                return;
            }
            var amz = window.amazon;
            amz.Login.retrieveProfile(async function (userInfo) {
                if (!userInfo.success) {
                    console.debug('Get user Info failed');
                    return;
                }
                var user = {
                    name: userInfo.profile.Name,
                    email: userInfo.profile.PrimaryEmail,
                };
                if (!Auth ||
                  typeof Auth.federatedSignIn !== 'function' ||
                  typeof Auth.currentAuthenticatedUser !== 'function') {
                    throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
                }
                Auth.federatedSignIn('amazon', { token: access_token, expires_at: expires_at }, user)
                  .then(function (credentials) {
                      return Auth.currentAuthenticatedUser();
                  })
                  .then(function (authUser) {
                      if (onStateChange) {
                          onStateChange('signedIn', authUser);
                      }
                  });
            });
        };
        class_1.prototype.signOut = function () {
            var amz = window.amazon;
            if (!amz) {
                console.debug('Amazon Login sdk undefined');
                return Promise.resolve();
            }
            console.debug('Amazon signing out');
            amz.Login.logout();
        };
        class_1.prototype.componentDidMount = function () {
            var amazon_client_id = this.props.amazon_client_id;
            if (amazon_client_id && !window.amazon)
                this.createScript();
        };
        class_1.prototype.createScript = function () {
            var script = document.createElement('script');
            script.src = 'https://api-cdn.amazon.com/sdk/login1.js';
            script.async = true;
            script.onload = this.initAmazon;
            document.body.appendChild(script);
        };
        class_1.prototype.initAmazon = function () {
            console.debug('init amazon');
            var amazon_client_id = this.props.amazon_client_id;
            var amz = window.amazon;
            amz.Login.setClientId(amazon_client_id);
        };
        const WithAmazon = function () {
            var amz = window.amazon;
            return (React.createElement(Comp, __assign({}, this.props, { amz: amz, amazonSignIn: this.signIn, amazonSignOut: this.signOut })));
        };
        class_1.prototype.render = WithAmazon;
        return class_1;
    }(React.Component));
}