import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function joinGroupReducer(
  state = initialState.groupJoined,
  action
) {
  
  switch (action.type) {
    case types.JOIN_GROUP:
      return initialState.groupJoined;
    case types.JOIN_GROUP_SUCCESS:
      return action.group;
    case types.JOIN_GROUP_FAILED:
      return action.error;
    default:
      return state;
  }
}