import React from 'react';

const EmailDomainsHint = ({ emailDomains }) => {
  if (!emailDomains?.length > 0) {
    return null;
  }

  return (
    <div>
      <h5 className='mb-3'>Please use your company email address.</h5>
      <div className='d-flex gap-1 flex-wrap mb-3'>
        {emailDomains.map((domain, idx) => (
          <p key={idx} className='m-0'>
            *@{domain} {idx < emailDomains.length - 1 && ','}
          </p>
        ))}
      </div>
    </div>
  );
};

export default EmailDomainsHint;
