import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function planReducer(
  state = initialState.plan_list ? initialState.plan_list : [],
  action
) {
  switch (action.type) {
    case types.GET_PLANS:
      return initialState.plan_list;
    case types.GET_PLANS_SUCCESS:
      return action.plan_list;
    case types.GET_PLANS_FAILED:
      return action.error;
    default:
      return state;
  }
}
