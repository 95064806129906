import * as types from "./actionTypes";
import * as config from "../../../config";
const {
  getPlansApi
} = require("@carbonclick/carbon-api-client/src/api/planApi");
import { extractApiError } from '../../util/errorExtractor'
import { beginApiCall } from "./apiStatusActions";
import { errorHandlerActions } from './errorHandlerActions';

const getPlansAction = {type: types.GET_PLANS};

export function getPlansSuccess(plan_list) {
  return { type: types.GET_PLANS_SUCCESS, plan_list };
}

export function getPlansFailed(error) {
  return { type: types.GET_PLANS_FAILED, error };
}

export function resetPlans() {
  return function (dispatch) {
    dispatch(getPlansAction);
  }
}

export function getPlans(location, merchantCode, basketCode) {
  return function (dispatch) {
    dispatch(getPlansAction);
    dispatch(beginApiCall());
    return getPlansApi(location, merchantCode, basketCode)
      .then(plan_list => {
        dispatch(getPlansSuccess(plan_list));
      })
      .catch(error => {
        const status = extractApiError(error).status;
        const sentryMessage = `${config.deployment_env} plansActions.js : plans api call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(getPlansFailed(error));
      });
  };
}
