export default {
  apiCallsInProgress: 0,
  groupApiCallInProgress: 0,
  payment: { //should refactor these into separate states - they're not really related
    last: null,
    all: null
  },
  personalFootprintData: null,
  makePayment:null,
  paymentDetails:null, 
  impactAllStats: {},
  impactFlightsStats: null,
  impactByProjectStats: null,
  impactMerchantStats: null,
  impactTransactStats: null,
  impactUserStats: null,
  impactRoadTrip: null,
  quote: [],
  plan_list: [],
  subs: null,
  subsList: null,
  customer: null,
  user: null,
  customers: null,
  project: null,
  projects: null,
  geolocation: {},
  groupCreated: null,
  group: null,
  groupMemberships: null,
  groupJoined: null,
  currentUserCognito: null,
  cognitoGuest: null,
  impactGroupUsersStats: null,
  groupReduceStats:null,
  groupFootprintsStats:null,
  inventory: null,
  projectInventorySales: null,
  baskets: null,
  basket: null,
  merchants: null,
  merchant: null,
  merchantUser: null,
  merchantsCount: null,
  flightQuote: null,
  flightsStats: null,
  flights: null,
  transactions: null,
  groupSubscriptionPurchases: null,
  reduceFacts: null,
  reduceActions: null,

  contributions: null,
  contributionsPage: null,
};
