import Config from "../../../config.js";

export const BASE_PATH_WITH_MERCHANT_CODE = "/RM-:referrerMerchantCodeInPath(\\w*)?";

export const APP_ENTRY_PAGE = "/";
export const DASHBOARD = "/my-cc";
export const ACCOUNT_SETTINGS = `/account-settings`;
export const MY_MOVEMENTS = `/my-movements`;
export const MY_TRAVEL = `/my-travel`;
export const MY_ACTIONS = `/my-actions`;
export const CREATE_MOVEMENT = `/create-movement`;
export const JOIN_MOVEMENT = `/join-movement`;
export const JOIN_MOVEMENT_WITH_GROUP_REFERENCE = `${JOIN_MOVEMENT}/:groupReference`;
export const FLIGHT_CONTRIBUTIONS = `/flight-contributions`;
export const TRANSACTIONS_BY_MERCHANT_CODE = `/transactions`;

export const PAYMENT_SUCCESS_PAGE = "/payment-success";
export const TRANSACTION_PAGE_BASE = "/transaction/";
export const TRANSACTION_PAGE = `${TRANSACTION_PAGE_BASE}:id`;
export const BASKETS_PAGE = "/baskets";
export const PROJECT_DETAILS_PAGE = "/projects";
export const MERCHANTS_PAGE = "/merchants";
export const PUBLIC_MOVEMENT_PAGE = "/movement";
export const PUBLIC_MOVEMENT_PAGE_WITH_ID = `${PUBLIC_MOVEMENT_PAGE}/:groupId`;
export const SUBSCRIPTION_SUCCESS_PAGE = "/subscription-confirmation";
export const PAYMENT_PAGE = "/payment";
export const EMAIL_SENT_PAGE = "/email-sent";
export const SUBSCRIBE_GUEST = "/subscribe";
export const TRACK_AND_TRACE_PAGE = "/track-n-trace";
export const TRACK_AND_TRACE_PAGE_FULL_WITH_PROJ_PARAM = "/projects/:projectCode/track-n-trace/:transactionId?";
export const SIGN_IN = "?id=sign-in";
export const SIGN_UP = "?id=sign-up";
export const CALCULATOR = "/calculator";
export const PERSONAL_CALCULATOR_EMBEDDABLE = "/personal-calculator-embed";
export const FLIGHT_CALCULATOR_EMBEDDABLE = "/flight-calculator-embed";
export const FLIGHT_CALCULATOR = "/flight-calculator";
export const DRIVING_CALCULATOR = "/driving-calculator";
export const OFFSET_PURCHASE = "/purchase-offset";
export const OFFSET_PURCHASE_EMBEDDABLE = "/purchase-offset-embed";
export const MERCHANT_PORTFOLIO_PAGE = "/merchant";
export const CLIMATE_FRIENDLY_TRAVELLER = "/cft";
export const CLIMATE_FRIENDLY_TEAM = `/climate-friendly-team`;
export const CLIMATE_PLEDGE = "/climate-pledge";
export const BUSINESS_CALCULATOR = "/business-calculator";
export const GENERIC_PAYMENT = `/payment`;
export const BUSINESS_OFFSET_PLANS = `/business-offset-plans`;
export const ALL_CONTRIBUTIONS = `/contributions`;
export const PROJECTS_CONTENT_PAGE = "/content/projects";
export const PROJECTS_COMPLIANCE_PAGE = "/projects-additional-information";


export const getLinkWithBaseUrl = (link) => `${Config.mycc_base_url}${link}`