import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function reduceActionsReducer(
  state = initialState.reduceActions,
  action
) {
  switch (action.type) {
    case types.GET_REDUCE_ACTIONS_SUCCESS:
      return action.reduceActions;
    case types.GET_REDUCE_ACTIONS_FAILED:
      return action.error;
      // dispatch({type:types., engagement:{actionId,type} });
    case types.CREATE_REDUCE_ACTION_USER_ENGAGEMENT_SUCCESS:{
      return { 
        ...state, 
        content: state.content.map(
            (reduceAction) => reduceAction.id === action.engagement.actionId ? {...reduceAction, mostRecentRelevantEngagement:{type:action.engagement.type}}
                                    : reduceAction
        )
      }
    }
    case types.CREATE_REDUCE_ACTION_USER_ENGAGEMENT_FAILED:{
      return { 
        ...state, 
        content: state.content.map(
            (reduceAction) => reduceAction.id === action.actionId ? {...reduceAction, error:action.error}
                                    : reduceAction
        )
      }
    }
    default:
      return state;
  }
}
