import * as types from "../actions/actionTypes";
import initialState from "./initialState";

//There seems to be an occasional bug where this can get out of sync and be -1 what it should be; uncomment the console.log to fault find
export default function apiStatusReducer(
    state = initialState.groupApiCallInProgress,
    action
) {

    if (action.type == types.BEGIN_GROUPS_API_CALL) {
        return state + 1;
    } else if (action.type === types.GET_GROUP_MEMBERSHIPS_SUCCESS) {
        return state - 1;
    } else if (action.type === types.GET_GROUP_MEMBERSHIPS_FAILED) {
        return state - 1;
    }
    return state;
}
