import React from "react";

const TermsAndConditions = () => {
  return (
    <>
      <div style={{ fontSize: 14 }}>
        By proceeding, you agree to our{" "}
        <a
          href="https://carbonclick.com/terms/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms and Conditions
        </a>
        . To find out what personal data we collect and how we used it, please
        visit our{" "}
        <a
          href=" https://carbonclick.com/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        .
      </div>
    </>
  );
};

export default TermsAndConditions;
