import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function paymentDetailsReducer(
  state = initialState.paymentDetails,
  action
) {
  switch (action.type) {
    case types.GET_PURCHASE_BY_ID_SUCCESS:
      return action.purchase;
    case types.GET_PURCHASE_BY_ID_FAILED:
      return action.error;
    default:
      return state;
  }
}
