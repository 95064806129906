import React, {useState, useEffect, useContext, useMemo} from "react";
import { Col, Row, Alert } from "antd";
import FadeIn from "react-fade-in";
import { isMobile } from "../common/viewBreakpoints";
import SignInWidget from "./SignInWidget";
import { useMediaQuery } from "react-responsive";
import CustomHeaderView from "../common/CustomHeaderView";
import GuestPageContainer from '../common/GuestPageContainer';
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import * as groupActions from "../../redux/actions/groupActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as ccAnalytics from "../../util/analytics/ccAnalytics";
import UIContext from "../../util/UIContext";
import { portal_url } from "../../../config";

const SignInPage = ({ group, actions, ...props }) => {

  const uiContext = useContext(UIContext);

  const [isFacebookEmailError, setFacebookEmailError] = useState(false);
  const [groupReference, setGroupReference] = useState();
  const mainHeaderText = "Sign in or create an account to see your climate impact grow";
  const [mainHeaderSubText, setMainHeaderSubText] = useState();
  let history = useHistory();
  let location = useLocation();
  const urlParams = new URLSearchParams(location.search)
  const emailDomains = uiContext?.emailDomains;

  const titleStyle = emailDomains?.length > 0 && 'font-p-bold';
  const [hasMerchantUsers, setHasMerchantUsers] = useState(false);

  const isClimateFriendlyTeamFlow = urlParams.get("from")?.match('/my-cc/climate-friendly-team.*');

  const isMobileDevice = isMobile(useMediaQuery);
  const headerRightText = "Sign in or create an account";

  useEffect(() => {
    setHasMerchantUsers(!!props?.user?.hasMerchantUsers);
  }, [props?.user]);

  const industryId = urlParams.get("industryId");
  const countryCode = urlParams.get("country");

  const googleAnalyticsPage = 'signin-page';

  useEffect(() => {
    setGroupReference(getGroupRefFromFromUri());

    let parsed = history && history.location && queryString.parse(history.location.hash);
    const errorFB = parsed && parsed.error_description ? parsed.error_description : "";
    if (errorFB.includes("email"))
      setFacebookEmailError(true);
    const timer = setTimeout(() => {
      setFacebookEmailError(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, [])

  useEffect(() => {
    if (groupReference && props.cognitoGuest && props.cognitoGuest.isSignedIn) {
      actions.getGroupByReference(groupReference);
    }
  }, [groupReference, props.cognitoGuest])

  useEffect(() => {
    if (group) {
      setMainHeaderSubText(`You'll be joining ${group.name}`);
    }
  }, [group])

  useEffect(() => {
    ccAnalytics.pageview(googleAnalyticsPage);
  }, [])

  const getGroupRefFromFromUri = () => {
    const fromURI = new URLSearchParams(location.search).get("from");

    if (fromURI) {
      const groupUriMatches = fromURI.match('.*/join-movement/([^?^/]*).*');
      if (groupUriMatches && groupUriMatches.length === 2) {
        return groupUriMatches[1];
      }
    }
  };

  const displayUserMsg = useMemo(() => {
    return(
      <FadeIn>
        <p>
          It looks like you have a Business or Merchant account. If you wish to access this please click{' '}
          <a href={portal_url} target='_blank' rel='noopener noreferrer'>here</a>{' '}
          and use the same email address and password to login. For legacy business subscription users, please proceed to login here to manage your subscription on the My Account page. Can`t find what you need? Contact Us.
        </p>
      </FadeIn>
    )
  }, [hasMerchantUsers]);

  const hideUserMsg = () => {
    setHasMerchantUsers(false);
  }

  return (
    <>
      {isMobileDevice ? (
        <>
          <Row>
                <CustomHeaderView
                customImageSrc={uiContext.content.signIn.individual.backgroundImage}
                customLogo={uiContext?.content.mobileLogo.light}
                isMobileDevice={true}
                text={mainHeaderText}
                subText={mainHeaderSubText}
              />
          </Row>
          <Row className="content-main">
            {isFacebookEmailError && <Alert
              message="Oops, we need your email address, please sign up using your email address below instead."
              type="info"
              showIcon
            />}
            <div style={DIV_STYLE} className={titleStyle}>{headerRightText}</div>
            <SignInWidget hideUserMsg={hideUserMsg} industryId={industryId} isClimateFriendlyTeamFlow={isClimateFriendlyTeamFlow} googleAnalyticsPrefixText={googleAnalyticsPage} />
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Col span={12}>
                <CustomHeaderView
                  customImageSrc={uiContext.content.signIn.individual.backgroundImage}
                  customLogo={uiContext?.content.logo.light}
                  isSideView={true}
                  isMobileDevice={false}
                  text={mainHeaderText}
                  subText={mainHeaderSubText}
                />
            </Col>
            <GuestPageContainer>
              {isFacebookEmailError && <Alert
                message="Oops, we need your email address, please sign up using your email address below instead."
                type="info"
                showIcon
              />}
              <span style={{padding:'12px', textAlign:'left'}}>
                <div style={DIV_STYLE} className={titleStyle}>{headerRightText}</div>
                {hasMerchantUsers && displayUserMsg}
                <SignInWidget hideUserMsg={hideUserMsg} countryCode={countryCode} industryId={industryId} isClimateFriendlyTeamFlow={isClimateFriendlyTeamFlow} googleAnalyticsPrefixText={googleAnalyticsPage} />
              </span>
            </GuestPageContainer>
          </Row>
        </>
      )}
    </>
  );
};

const DIV_STYLE = {
  fontWeight: 400,
  fontSize: "28px",
  paddingBottom: 20
};


function mapStateToProps(state) {
  return {
    loading: !state.group,
    group: state.group,
    user: state.user,
    cognitoGuest: state.cognitoGuest
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getGroupByReference: bindActionCreators(groupActions.getGroupByReference, dispatch)
    }
  };
}

SignInPage.propTypes = {
  group: PropTypes.object,
  actions: PropTypes.object,
  cognitoGuest: PropTypes.object,
  'cognitoGuest.isSignedIn': PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(SignInPage));