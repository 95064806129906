import merge from 'deepmerge'; //much smaller than lodash, and this needs to be in main bundle

import base from "./base";
const { getTransactionDetailsComponent } = require("@carbonclick/carbon-api-client/src/api/cmsApi");
import * as link from "../../components/common/pages";

import retailGlobal from "./merchants/retailGlobal";
import sapphireTraveller from "./merchants/sapphireTraveller";
import carbonclick from "./merchants/carbonclick";
import whiteLabelingTest from "./merchants/whiteLabelingTest";
import forestCarbon from "./merchants/forestCarbon";
import aasmartfuel from "./merchants/aasmartfuel";
import earthShare from "./merchants/earthShare";
import foreverForests from "./merchants/foreverForests";
import childFundNZ from "./merchants/childFundNZ";
import uicca from "./merchants/uicca";
import carbonVisibility from "./merchants/carbonVisibility";
import amadeus from "./merchants/amadeus";
import metlife from "./merchants/metlife";
import rnzys from "./merchants/rnzys";
import traveller from "./merchants/traveller";
import carbonclickEvents from "./merchants/carbonclickEvents";
import carbonclickWaf2022 from "./merchants/carbonclickWaf2022";
import carbonclickWaf2023 from "./merchants/carbonclickWaf2023";
import carbonclickAfa2023 from "./merchants/carbonclickAFA2023.js";
import carbonclickAfAmericas2023 from "./merchants/carbonclickAFAmericas2023.js";
import misio from "./merchants/misio";
import themeTesting from "./merchants/themeTesting";
import magnitGlobal from "./merchants/magnitGlobal";
import northropGrumman from "./merchants/northropGrumman";
import expoCityDubai from "./merchants/expoCityDubai";
import changi from "./merchants/changi";
import {getMyCCComponent} from "@carbonclick/carbon-api-client/src/api/cmsApi";

const overwriteMerge = (destinationArray, sourceArray) => {
    return sourceArray;
}

const merchantConfigs = [
    retailGlobal,
    sapphireTraveller,
    carbonclick,
    aasmartfuel,
    whiteLabelingTest,
    forestCarbon,
    earthShare,
    foreverForests,
    childFundNZ,
    carbonVisibility,
    amadeus,
    metlife,
    rnzys,
    traveller,
    carbonclickEvents,
    carbonclickWaf2022,
    carbonclickWaf2023,
    carbonclickAfa2023,
    carbonclickAfAmericas2023,
    misio,
    themeTesting,
    magnitGlobal,
    expoCityDubai,
    changi,
    uicca,
    northropGrumman
];

export const uiConfigsMerchantCodes = merchantConfigs.map(uiConfig => uiConfig.code);

export const allowThemingWithoutConfigurationPages = [
    link.TRANSACTION_PAGE_BASE,
    link.OFFSET_PURCHASE,
    link.DRIVING_CALCULATOR,
    link.TRACK_AND_TRACE_PAGE,
    link.TRACK_AND_TRACE_PAGE_FULL_WITH_PROJ_PARAM,
    link.PROJECT_DETAILS_PAGE
]

export function checkAllowThemingWithoutConfigurationLocation(currentPath) {
    return allowThemingWithoutConfigurationPages.filter(page => currentPath.includes(page)).length > 0;
}

export const getUIConfigByMerchantCode = async (merchantCode, programmeCode, componentConfigMerchantCode) => {
    let merchantConfig = merchantConfigs.find(c=>c.code===merchantCode);
    merchantConfig = merchantConfig ? merchantConfig : carbonclick;
    merchantConfig = merge(base, merchantConfig, { arrayMerge: overwriteMerge });
    return updateUIConfigWithThemeAndConfigFromDb(merchantConfig, merchantCode, programmeCode, componentConfigMerchantCode);
}

const updateUIConfigWithThemeAndConfigFromDb = async (merchantConfig, merchantCode, programmeCode, componentConfigMerchantCode) => {
    const result = await Promise.all([getTransactionDetailsComponent(merchantCode), getMyCCComponent(componentConfigMerchantCode, programmeCode)].map(p => p.catch(e => e)));
    const themeFromApi = result[0]?.theme;

    if(themeFromApi) {
        merchantConfig.theme.palette.primary = themeFromApi.palette.primary;
        merchantConfig.theme.palette.secondary = themeFromApi.palette.secondary;
        merchantConfig.theme.palette.accent = themeFromApi.palette.accent;
        merchantConfig.theme.palette.callToAction.background = themeFromApi.palette.accent?.main;
        merchantConfig.content.logo.dark = themeFromApi.logo.dark;
        merchantConfig.content.logo.light = themeFromApi.logo.light;
        merchantConfig.content.mobileLogo.dark = themeFromApi.logo.dark;
        merchantConfig.content.mobileLogo.light = themeFromApi.logo.light;
        merchantConfig.hasWlConfig = uiConfigsMerchantCodes.includes(merchantCode);
    }

    if(result[1]?.type !== 'error' && result[1]?.configuration) {
        merchantConfig.api = result[1].configuration;
    }

    return merchantConfig;
}
