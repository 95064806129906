import React, {useState, useEffect} from "react";
import { Row, Col, Button } from "antd";
import PropTypes from "prop-types";
import "../payment/guestPaymentForm.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import TermsAndConditions from "../common/TermsAndConditions";
import CCButton from "../common/CCButtonV3";
import eye_regular from "../../images/eye-regular.svg";
import eye_slash_solid from "../../images/eye-slash-solid.svg";
import * as link from '../common/pages'
import "./style.css";
import ResetPassword from "./reset-password";
import { connect } from "react-redux";
import SocialLoginView from "./SocialLoginView";
import * as ccAnalytics from "../../util/analytics/ccAnalytics";
import OrView from './OrView';
import { signIn } from "./authentication";
import {useRouteMatch} from "react-router-dom";

const SignIn = ({ trackPageViews, handleGoBack,showGoBackButton, user, googleAnalyticsParentPath, emailAddr}) => {

  const match = useRouteMatch();

  const [password, setPassword] = useState("");
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [errorPassword, setPasswordError] = useState({
    message: "NA",
    error: false
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(trackPageViews){
      ccAnalytics.pageview(`${googleAnalyticsParentPath}/sign-in`);
    }
  }, []);

  const goToHome = () => {
    window.location = match.url+link.DASHBOARD;
  }

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    await appSignIn(emailAddr, password);

  };

  async function appSignIn(username, password) {
    if (username.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      await signIn(username, password)
        .then(() => goToHome())
        .catch((err) => {
          setPasswordError({
            message: "Incorrect password",
            error: true
          });
          console.error("Auth Error: %o" , err);
        });
    }
    setLoading(false);
  }

  const hasFacebookLogin = (user && user.loginMethods && (user.loginMethods.find(method => method.loginMethodCode==='COGNITO_FACEBOOK'))) ? true : false;
  const hasGoogleLogin = (user && user.loginMethods && (user.loginMethods.find(method => method.loginMethodCode==='COGNITO_GOOGLE'))) ? true : false;
  const hasPasswordLogin = (user && user.loginMethods && (user.loginMethods.find(method => method.loginMethodCode==='COGNITO_PASSWORD'))) ? true : false;

  const SignInButton = (
    <CCButton
      buttonName="Sign me in"
      onClickButton={handleSignIn}
      isDisabled={password.length >= 8 ? false : true}
      size="large"
      type="primary"
      loading={loading}
    />
  );

  return (
    <div className="signFormWrapper">
      <form onSubmit={handleSignIn}>
        <div className='w-100 d-inline-grid'>
          
            <div className="text-left" style={INPUT_WRAPPER_STYLE}>
              {emailAddr ? emailAddr : "Your email"}
            </div>
         
        </div>
        
        <Row className="div-spacing w-100">
            <SocialLoginView showFacebook={hasFacebookLogin} showGoogle={hasGoogleLogin}/>
        </Row>

        { (hasFacebookLogin || hasGoogleLogin) && hasPasswordLogin &&
          <Row className="w-100" gutter={8} style={{ paddingTop: 20 }}>
            <OrView />
          </Row>
        }

        {hasPasswordLogin &&
        <>
        <Row className="div-spacing w-100">
          <div className="inputWrapper">
            <Row className="w-100">
              <Col span={22}>
                <input
                  type={!isPasswordVisible ? "password" : "text"}
                  value={password}
                  size="large"
                  onChange={handlePasswordChange}
                  placeholder="Enter password"
                  style={{
                    ...INPUT_STYLE
                  }}
                />
              </Col>
              <Col span={2}>
                <img
                  onClick={() => setPasswordVisible(!isPasswordVisible)}
                  style={{ width: 20 }}
                  src={isPasswordVisible ? eye_slash_solid : eye_regular}
                />
              </Col>
            </Row>
          </div>
        </Row>
        
        <Row className="w-100">
          <span style={{ fontSize: 12, color: "red" }}>
            {errorPassword.error ? errorPassword.message : null}
          </span>
        </Row>
        <Row className="div-spacing w-100">
        <a className="forgot-password-link"
          onClick={ () => setModalVisible(true)}
        >
          Forgot Password?
        </a>
        </Row>
        </>
      }
        <Row className="div-spacing w-100">
          <TermsAndConditions />
        </Row>
        <Row className="div-spacing w-100">
          <Col span={13} style={{ ...MOBILE_STYLE }}>
            {showGoBackButton === false ? null:<Button
              size="large"
              onClick={handleGoBack}
              type="link"
            >
              <FontAwesomeIcon icon={faArrowLeft} />
              <span style={{ paddingLeft: 5 }}>{`Go Back`}</span>
            </Button>}
          </Col>
          {hasPasswordLogin &&
          <Col span={11} style={{...MOBILE_STYLE, textAlign: "right"}}>
            {SignInButton}
          </Col>
          }
        </Row>
      </form>
      {modalVisible && <ResetPassword showModal={true} 
                        setModalVisible={setModalVisible} 
                        email={emailAddr}/>}
    </div>
  );
};

const INPUT_WRAPPER_STYLE = {
  padding: "14px 12px 12px 12px",
  borderRadius: 4,
  backgroundColor: "#D0D3D5",
  border: "1px solid lightgrey",
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};

const INPUT_STYLE = {
  height: "auto",
  padding: 0,
  fontSize: "20px",
  lineHeight: "1.3em",
  width: "100%",
  color: "black",
  "::placeholder": {
    color: "#aab7c4"
  },
  border: 0,
  outline: 0
};

const MOBILE_STYLE = { paddingTop: 10 };

SignIn.propTypes = {
  emailAddr: PropTypes.string,
  password: PropTypes.string,
  isMobileDevice: PropTypes.bool,
  user: PropTypes.object
};

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(SignIn);