import merge from "deepmerge";
import aviationBase from "./aviationBase";

const overwriteMerge = (destinationArray, sourceArray) => {
    return sourceArray;
}

const overrides = {
    code:'BUS_NZ_THEME_TESTING',
}

export default merge(aviationBase,overrides, { arrayMerge: overwriteMerge });
