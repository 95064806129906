import * as types from "./actionTypes";
import * as config from "../../../config";
const {getImpactTransactionApi} = require("@carbonclick/carbon-api-client/src/api/impactApi");
import { extractApiError } from '../../util/errorExtractor'
import { beginApiCall } from "./apiStatusActions";
import { errorHandlerActions } from './errorHandlerActions';

export function getImpactTransactionSuccess(impactTransactStats) {
  return { type: types.TRANSACTION_IMPACT_SUCCESS, impactTransactStats };
}

export function getImpactTransactionFailed(error) {
  return { type: types.TRANSACTION_IMPACT_FAILED, error };
}

export function getImpactTransaction(id, locale) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return getImpactTransactionApi(id, locale)
      .then(impact => {
        dispatch(getImpactTransactionSuccess(impact));
      })
      .catch(error => {
        const status = extractApiError(error).status;
        const sentryMessage = `${config.deployment_env} impactTransactionActions.js (transactionId: ${id ? id : 'Not found'}): impact api call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(getImpactTransactionFailed(error));
      });
  };
}
