export default {
    code: 'BES_NZ_CHILDFUND',
    baskets: {
        includePublicBaskets: false,
        businessCodes: ['CHILDFUND_NZ_DEFAULT'],
        individualCodes: ['CHILDFUND_NZ_DEFAULT'],
    },
    content: {
        logo: {
            link: 'https://childfund.org.nz/'
        },
        menu: {
            individual: {
                items: ['dashboard', 'myFootprint', 'myAccount', 'projects']
            },
            url: 'https://forest.carbon.click/',
            signOutUrl: null
        },
        dashboard: {
            individual: {
                items: ['defaultHeader', 'badges', 'calculateFootprintBanner', 'recentOffsets', 'calculateMyFootprint', 'subscribePanel', 'groupsImpact', 'climateActions', 'reduceActions', 'supportedProjectsPanel'],
            }
        },
        signIn: {
            individual: {
                backgroundImage: 'url("https://res.cloudinary.com/carbonclick/image/upload/w_1200/e_colorize:50,co_rgb:007C47/v1644442203/White%20Label/ChildFund%20NZ/shore_gotta7.jpg")',
            }
        },
    },
    theme: {
        images: {
            hero: {
                main: 'url(\'https://res.cloudinary.com/carbonclick/image/upload/w_1200/e_colorize:50,co_rgb:007C47/v1644442203/White%20Label/ChildFund%20NZ/shore_gotta7.jpg\')',
            }
        }

    }

}