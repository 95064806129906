export default {
    code: 'BUS_AE_UICCA_AXIYBTAO',
    theme: {
        images: {
            hero: {
                main: 'url(\'https://res.cloudinary.com/carbonclick/image/upload/v1701358778/Enterprise/UICCA/uicca_header_qibgkq.jpg\')',
            },
        }
    },
    hideMentionsOfCarbonClick: true
}