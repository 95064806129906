import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function impactByProjectReducer(
  state = initialState.impactByProjectStats
    ? initialState.impactByProjectStats
    : null,
  action
) {
  switch (action.type) {
    case types.IMPACT_BY_PROJECT_CODE_SUCCESS:
      return action.impactByProjectStats;
    case types.IMPACT_BY_PROJECT_CODE_FAILED:
      return action.error;
    default:
      return state;
  }
}
