import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function customerReducer(
  state = initialState.customer ? initialState.customer : [],
  action
) {
  switch (action.type) {
    case types.GET_CUSTOMER_SUCCESS:
      return action.customer;
    case types.GET_CUSTOMER_FAILED:
      return action.error;
    case types.GET_CUSTOMER_USER_SUCCESS:
      return action.customerExists;
    case types.GET_CUSTOMER_USER_FAILED:
      return action.error;
    default:
      return state;
  }
}
