import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function userReducer(
  state = initialState.user ,
  action
) {
  switch (action.type) {
    case types.GET_USER_SUCCESS:
      return action.user;
    case types.GET_USER_FAILED:
      return action.error;
    case types.RESET_USER:
      return "";
    default:
      return state;
  }
}
