
export default
{
    code:'BES_US_NORTHROP_GRUMMAN',
    currencies: ['USD'],
    emailDomains: ['northropgrumman.com'],
    content:{
        logo: {
            link: 'https://www.northropgrumman.com/'
        },
        menu: {
            menuIconColor: true,
            url: 'https://www.northropgrumman.com/',
            business: {
                items: ['dashboard', 'myCalculator', 'projects', 'FAQ', 'myAccount']
            },
            individual: {
                items: ['dashboard', 'myCalculator', 'projects', 'FAQ', 'myAccount']
            },
        },
        myMovementPanel: {
            hideMovementInvitation: true,
            hideMovementCreation: true,
        },
        myTeamPanel: {
            hideOffsettingForPanel: true,
            items: ["teamStats"],
            hideUnnalocatedSeats: true,
            teamMemberListVisibility: 'ADMIN', // PUBLIC, TEAM, ADMIN
            defaultUnitType: "t",
            content: {
                borderStyle: true,
                pointsTitle: "Points earned for Climate Friendly actions",
                offsetTitle: "Offset equivalent in trees",
                treesTitle: "Tree growth years",
                totalOffset: true,
            }
        },
        signIn: {
            individual: {
                hideSocialMedia: true,
            }
        },
    },
}