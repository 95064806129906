//A TEST MERCHANT FOR EXPERIMENTING/DEMO-ING THEMES

export default
    {
        code:'MYC_NZ_WHITE_LABELING',
        baskets: {
            includePublicBaskets: false,
            businessCodes: ['GB_BUNDLED'],
            individualCodes: ['GB_BUNDLED']
        },
        content: {
            menu: {
                business: {
                    items: ['dashboard', 'merchantOffsets', 'businessOffsetPlans', 'businessFootprint', 'myTeam', 'Pledge', 'myTravel', 'myAccount', 'FAQ', 'projects']
                },
                individual: {
                    items: ['dashboard', 'myFootprint', 'myTravel', 'myClimateMovements', 'myAccount', 'projects']
                },
                url: 'https://www.carbonclick.com/',
                signOutUrl: null
            },
            dashboard: {
                business: {
                    items: ['content', 'calculateFootprintBanner', 'actions', 'cumulativeImpactPanel', 'supportedProjectsPanel']
                },
                individual: {
                    headerText: "Custom Header Text",
                    headerDescription: true,
                    items: ['calculateFootprintBanner', 'defaultHeader', 'recentOffsets', 'cumulativeImpactPanel', 'climateActions', 'subscribePanel', 'calculateMyFootprint', 'supportedProjectsPanel', 'impactPanel']
                }
            },
            transactionsPage: {
                headerText: 'Transactions',
            },
            businessOffsetPlans: {
                headerText: 'Offset your business emissions',
                headerDescription: 'Offsetting is a powerful tool that neutralises your carbon footprint with high quality carbon offset projects. Select a plan below to start making a difference.',
                headerImage: 'https://res.cloudinary.com/carbonclick/image/upload/c_scale,e_brightness:-48,h_787/v1608003033/Projects/DoeMountain/doe_mountain_3_ipptni.jpg',
            },
            projects: {
                headerText: 'Carbon Offsetting Projects TEST TEXT',
                descriptionHeaderText: 'CarbonClick\'s blended basket approach TEST TEXT',
                descriptionContent: `
                    <p>Test Paragraph</p>
                    <p>Test Paragraph</p>
                    <p>Test Paragraph</p>
                `,
            },
            signIn: {
                business: {
                    headerText: "Grow your sustainability journey with the Carbon Club",
                    formHeaderText: "Sign up to the Carbon Club for free",
                    backgroundImage: 'url("https://res.cloudinary.com/carbonclick/image/upload/v1603051574/New_Zealand_epzpoj.png")',
                    items: [],
                },
                individual: {
                    backgroundImage: "https://res.cloudinary.com/carbonclick/image/upload/v1603051574/New_Zealand_epzpoj.png",
                }
            },
            individualSubscribePlans: {
                header: {
                    text: 'Make a difference in fighting climate change with Forest Carbon',
                    description: 'Your Forest Carbon subscription will make a real, traceable difference. We\'ll show you how your impacts grow every month.',
                    image: "https://res.cloudinary.com/carbonclick/image/upload/v1603051574/New_Zealand_epzpoj.png",
                },
                payment: {
                    headerText: 'Some payment description',
                    headerImage: "https://res.cloudinary.com/carbonclick/image/upload/v1603051574/New_Zealand_epzpoj.png",
                },
                paymentSuccess: {
                    headerText: 'wl-test-payment-success-header-name',
                    headerImage: "https://res.cloudinary.com/carbonclick/image/upload/v1603051574/New_Zealand_epzpoj.png",
                    items: ['socialMediaPanel', 'impactPanel'],
                }
            },
            subscriptionPlans: {
                business: {
                    planTemplate: 'forest-carbon-plan-template',
                    items: [
                        {
                            numberOfTonnes: 25,
                            descriptionData: {
                                numberOfEmployees: 10,
                                numberOfTrees: 100,
                                numberOfMiles: '20,000',
                                businessExpenditure: '€25k',
                                src: 'https://res.cloudinary.com/carbonclick/image/upload/v1638311894/White%20Label/Forest%20Carbon/plan-25_dlorny.png',
                            },
                            order: ['header', 'image', 'description', 'button']
                        },
                        {
                            numberOfTonnes: 50,
                            descriptionData: {
                                numberOfEmployees: 20,
                                numberOfTrees: 200,
                                numberOfMiles: '40,000',
                                businessExpenditure: '€50k',
                                src: 'https://res.cloudinary.com/carbonclick/image/upload/v1638311894/White%20Label/Forest%20Carbon/plan-50_ixe7e1.png',
                            },
                            order: ['header', 'image', 'description', 'button']
                        },
                        {
                            numberOfTonnes: 100,
                            descriptionData: {
                                numberOfEmployees: 40,
                                numberOfTrees: 400,
                                numberOfMiles: '100,000',
                                businessExpenditure: '€75k',
                                src: 'https://res.cloudinary.com/carbonclick/image/upload/v1638311894/White%20Label/Forest%20Carbon/plan-100_ilcqqb.png',
                            },
                            order: ['header', 'image', 'description', 'button']
                        },
                    ]
                }
            },
            logo: {
                dark: 'https://res.cloudinary.com/carbonclick/image/upload/c_scale,h_90/v1638311906/White%20Label/Forest%20Carbon/Logo_viynlk.png',
                light: 'https://res.cloudinary.com/carbonclick/image/upload/c_scale,h_90/v1638311906/White%20Label/Forest%20Carbon/Logo_viynlk.png'
            },
            mobileLogo: {
                dark: 'https://res.cloudinary.com/carbonclick/image/upload/c_scale,h_70/v1638311906/White%20Label/Forest%20Carbon/Logo_viynlk.png',
                light: 'https://res.cloudinary.com/carbonclick/image/upload/c_scale,h_70/v1638311906/White%20Label/Forest%20Carbon/Logo_viynlk.png'
            },
        },
        theme: {
            palette: {
                primary: {
                    main: '#80B05C',
                    light: '#C5D676',
                    contrastText: '#f8f8f8'
                },
                secondary: {
                    main: '#6c8240',
                    light: '#447437',
                    contrastText: 'rgba(0,107,0,0.1)'
                },
                header: {
                    dark: '#000',
                    light: '#FFF',
                },
                callToAction: {
                    background: '#80B05C'
                },
                simpleImage: {
                    background: "#80B05C",
                    color1: "#C5D676",
                    color2: "#81af34",
                    color3: "#9ace41",
                    color4: "#DDF89D",
                    color5: "#82AF32",
                },
            },
            images: {
                hero: {
                    main: 'url(\'https://res.cloudinary.com/carbonclick/image/upload/c_scale,e_brightness:-48,h_787/v1608003033/Projects/DoeMountain/doe_mountain_3_ipptni.jpg\')',
                }
            }
        }
    }