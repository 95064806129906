import initialState from "./initialState";
import * as types from "../actions/actionTypes";

export default function basketsReducer(state =  initialState.baskets , action) {
    switch (action.type) {
        case types.GET_BASKETS_SUCCESS:
            return action.baskets;
        case types.GET_BASKETS_FAILED:
            return action.error;
        default:
            return state;
    }
}
