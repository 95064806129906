import React, { useContext } from 'react';
import UIContext from '../../util/UIContext';

const ProgressCircle = ({ value }) => {
  const uiContext = useContext(UIContext);

  const circleRadius = 120 * 0.47;
  const circumference = circleRadius * Math.PI;
  const percent = Math.min(Math.max(value, 0), 100) / 100;

  const fx = 0.5 + Math.cos(percent * Math.PI * 2 - Math.PI * 2) / 2;
  const fy = 0.5 + Math.sin(percent * Math.PI * 2 - Math.PI * 2) / 2;

  return (
    <div
      style={{ backgroundColor: value > 0 ? '#ffffff' : '', border: 'none' }}
    >
      <svg id={'progress-ring-impact'} height='58px' width='58px' viewBox='0 0 58 58'>
        <defs>
          <radialGradient id={'gradient-progress-ring-impact'} cx='0.5' cy='0.5' r='0.7' fx={fx} fy={fy}>
            <stop offset='0%' stopColor={'#74635B'} />
            <stop offset='100%' stopColor={uiContext.theme.palette.accent.dark} />
          </radialGradient>
        </defs>
        <circle
          strokeWidth='1px'
          stroke={'#d4d3d2'}
          fill={uiContext.theme.palette.accent.light}
          strokeOpacity={'100%'}
          r='49%'
          cx='50%'
          cy='50%'
        />
        <circle
          style={{
            transform: 'rotate(-90deg)',
            transformOrigin: '50% 50%',
            transition: 'stroke-dashoffset 2s',
          }}
          strokeWidth='1px'
          stroke={uiContext.theme.palette.accent.dark}
          strokeDasharray={circumference + ' ' + circumference}
          strokeDashoffset={circumference - percent * circumference}
          strokeLinecap='round'
          fill='transparent'
          r='49%'
          cx='50%'
          cy='50%'
        />
        <text
          x='50%'
          y='60%'
          textAnchor='middle'
          strokeWidth='2px'
          fontSize='16'
          fill={value > 0 ? uiContext.theme.palette.accent.dark : '#74635B'}
          className='progress-circle-value'
        >
          {value}%
        </text>
      </svg>
    </div>
  );
};

export default ProgressCircle;
