import { Row, Col } from "antd";
import React from "react";

const OrView = () => {
    return (
      <>
        <Row gutter={16}>
          <Col span={11}>
            <hr />
          </Col>
          <Col span={2}>
            <span
              style={{
                fontWeight: "bold",
                fontSize: 14,
                lineHeight: 0.5,
                height: 20,
                display: "inline-block",
                verticalAlign: "middle"
              }}
            >
              OR
            </span>
          </Col>
          <Col span={11}>
            <hr />
          </Col>
        </Row>
      </>
    );
  };

  export default OrView;