import { combineReducers } from "redux";
import apiCallsInProgress from "./apiStatusReducer";
import groupApiCallInProgress from "./groupApiStatusReducer";
import payment from "./paymentReducer";
import makePayment from "./makePaymentReducer";
import impactAllStats from "./impactAllReducer";
import impactFlightStats from "./impactFlightsReducer";
import impactMerchantStats from "./impactMerchantReducer";
import impactTransactStats from "./impactTransactionReducer";
import impactUserStats from "./impactUserReducer";
import impactByProject from "./impactByProjectReducer";
import impactGroups from "./impactGroupsReducer";
import groupReduceStats from "./groupReduceStatsReducer";
import groupFootprintsStats from "./groupFootprintsStatsReducer";
import customer from "./customerReducer";
import customers from "./customersReducer";
import plan_list from "./planReducer";
import quote from "./quoteReducer";
import subs from "./subscriptionReducer";
import subsList from "./subscriptionListReducer"
import hasSubscription from "./hasSubscriptionReducer";
import projects from "./projectsReducer"
import project from "./projectReducer"
import geolocation from "./geoLocationReducer";
import currentUserCognito from "./cognitoReducer"; //actual person user
import cognitoGuest from "./cognitoGuestReducer";  //guest 'user'
import groupCreated from "./createGroupReducer";
import groupJoined from "./joinGroupReducer";
import group from "./getGroupReducer";
import groupMemberships from "./groupMembershipsReducer";
import impactGroupUsers from "./impactGroupUsersReducer";
import inventory from "./inventoryReducer"
import projectInventorySales from "./projectInventorySalesReducer"
import baskets from "./basketsReducer"
import basket from "./basketReducer"
import paymentDetails from "./paymentDetailsReducer"
import merchantsPage from "./merchantPagesReducer";
import merchant from "./merchantsReducer"
import merchantsCount from "./merchantsCountReducer"
import flightQuote from "./flightQuoteReducer"
import flights from "./flightsReducer"
import flightsStats from "./flightsStatsReducer"
import impactRoadTrip from "./impactRoadTripReducer"
import merchantUser from "./merchantUserReducer";
import groupSubscriptionPurchases from "./purchasesByGroupSubscriptionReducer";
import user from "./userReducer";
import reduceFacts from "./reduceFactsReducer";
import reduceActions from "./reduceActionsReducer";
import personalFootprintData from "./personalFootprintReducer"
import contributionsPage from "./contributionsPagesReducer"
import transactionsPage from "./transactionsByMerchantCodeReducer"

const rootReducer = combineReducers({
  apiCallsInProgress,
  groupApiCallInProgress,
  payment,
  paymentDetails,
  makePayment,
  impactAllStats,
  impactMerchantStats,
  impactTransactStats,
  impactUserStats,
  impactGroups,
  user,
  customer,
  customers,
  quote,
  plan_list,
  subs,
  subsList,
  hasSubscription,
  project,
  projects,
  countryResult: geolocation,
  groupCreated,
  groupJoined,
  group,
  groupMemberships,
  currentUserCognito,
  cognitoGuest,
  impactGroupUsers,
  inventory,
  baskets,
  basket,
  flights,
  projectInventorySales,
  impactByProject,
  merchantsPage,
  merchantsCount,
  flightQuote,
  flightsStats,
  impactFlightStats,
  impactRoadTrip,
  merchantUser,
  merchant,
  groupSubscriptionPurchases,
  reduceFacts,
  reduceActions,
  groupReduceStats,
  groupFootprintsStats,
  personalFootprintData,
  contributionsPage,
  transactionsPage,
});

export default rootReducer;
