import * as types from "./actionTypes";
import * as config from "../../../config";
const {
  getSubscriptionApi,
  getSubscriptionByEmailApi,
  createSubscriptionApi,
  deleteSubscriptionApi,
  hasSubscriptionApi,
  deleteFootPrintSubs,
  deleteGroupSubs,
  createGuestSubscriptionApi
} = require("@carbonclick/carbon-api-client/src/api/subscriptionApi");
import { extractApiError } from '../../util/errorExtractor'
import { beginApiCall } from "./apiStatusActions";
import { errorHandlerActions } from './errorHandlerActions';

export function getSubsSuccess(subs) {
  return { type: types.GET_SUBS_SUCCESS, subs };
}

export function hasSubsFailed(error) {
  return { type: types.HAS_SUBS_FAILED, error };
}

export function hasSubsSuccess(hasSubscription) {
  return { type: types.HAS_SUBS_SUCCESS, hasSubscription };
}

export function getSubsFailed(error) {
  return { type: types.GET_SUBS_FAILED, error };
}

export function createSubsSuccess(create_subs) {
  return { type: types.CREATE_SUBS_SUCCESS, create_subs };
}

export function createSubsFailed(error) {
  return { type: types.CREATE_SUBS_FAILED, error };
}
export function deleteSubsSuccess(remove_subs) {
  return { type: types.DELETE_SUBS_SUCCESS, remove_subs };
}

export function deleteSubsFailed(error) {
  return { type: types.DELETE_SUBS_FAILED, error };
}

export function resetSubs() {
  return { type: types.RESET_SUBS };
}

export function getSubscriptions(cusId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return getSubscriptionApi(cusId)
      .then(subs => {
        dispatch(getSubsSuccess(subs.sort((a, b) => b.createDate - a.createDate)));
      })
      .catch(error => {
        const status = extractApiError(error).status;
        const sentryMessage = `${config.deployment_env} subscriptionActions.js (cusId: ${cusId ? cusId : 'Not found'}): get subs api call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(getSubsFailed(error));
      });
  };
}

export function getSubscriptionsByEmail(email) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return getSubscriptionByEmailApi(email)
      .then(subs => {
        dispatch(getSubsSuccess(subs.sort((a, b) => b.createDate - a.createDate)));
      })
      .catch(error => {
        const status = extractApiError(error).status;
        const sentryMessage = `${config.deployment_env} subscriptionActions.js (email: ${email ? email : 'Not found'}): get subs api call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(getSubsFailed(error));
      });
  };
}

export function hasSubscription(email) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return hasSubscriptionApi(email)
      .then(hasSubs => {
        dispatch(hasSubsSuccess(hasSubs));
      })
      .catch(error => {
        const status = extractApiError(error).status;
        if (status !== 404){
          const sentryMessage = `${config.deployment_env} subscriptionActions.js : has subs api call error ${JSON.stringify(extractApiError(error))}`;
          dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        }
        dispatch(hasSubsFailed(error));
      });
  };
}

export function createSubscription(cusId, paymentMethodId, planId, merchantCode) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return createSubscriptionApi(cusId, paymentMethodId, planId, merchantCode)
      .then(response => {
        dispatch(createSubsSuccess(response));
      })
      .catch(error => {
        const status = extractApiError(error).status;
        const sentryMessage = `${config.deployment_env} subscriptionActions.js : post subs api call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(createSubsFailed(error));
      });
  };
}

export function createGuestSubscription(email, paymentMethodId, planId, merchantCode, preferredBasketCode) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return createGuestSubscriptionApi(email, paymentMethodId, planId, merchantCode, preferredBasketCode)
      .then(response => {
        dispatch(createSubsSuccess(response));
      })
      .catch(error => {
        const status = extractApiError(error).status;
        const sentryMessage = `${config.deployment_env} subscriptionActions.js : post subs api call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(createSubsFailed(error));
      });
  };
}

export function deleteSubscription(subId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return deleteSubscriptionApi(subId)
      .then(response => {
        dispatch(deleteSubsSuccess(response));
      })
      .catch(error => {
        const status = extractApiError(error).status;
        const sentryMessage = `subscriptionActions.js : delete subs api call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(deleteSubsFailed(error));
      });
  };
}

export function deleteFootPrintSubScription(Id) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return deleteFootPrintSubs(Id)
      .then(response => {
        dispatch(deleteSubsSuccess(response));
      })
      .catch(error => {
        const status = extractApiError(error).status;
        const sentryMessage = `subscriptionActions.js : delete subs api call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(deleteSubsFailed(error));
      });
  };
}


export function deleteGroupSubScription(Id) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return deleteGroupSubs(Id)
      .then(response => {
        dispatch(deleteSubsSuccess(response));
      })
      .catch(error => {
        const status = extractApiError(error).status;
        const sentryMessage = `subscriptionActions.js : delete subs api call error ${JSON.stringify(extractApiError(error))}`;
        dispatch(errorHandlerActions(status, sentryMessage, "fatal"));
        dispatch(deleteSubsFailed(error));
      });
  };
}

export function resetSubscriptionResponse() {
  return function (dispatch) {
    dispatch(resetSubs())
  }
}
