import ReactGA from "react-ga4";

export const set = (data) => {
    ReactGA.set(data);
}


export const event = (data) => {
    ReactGA.event(data);
}

export const pageview = (path) => {
    ReactGA.send({ hitType: "pageview", page: path });
}

