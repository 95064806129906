export default {
    code:'AASMARTFUEL',
    currencies:['NZD'],
    theme:{
        components:{
            OneOffOffset:{
                topLevelClassNames:'aasmartfuel-style',
            }
        }
    }
}