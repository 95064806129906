import * as types from "../actions/actionTypes";
import initialState from "./initialState";

function actionTypeEndsInSuccess(type) {
  return type.substring(type.length - 8) === "_SUCCESS";
}

function actionTypeEndsInFailure(type) {
  return type.substring(type.length - 7) === "_FAILED";
}

//There seems to be an occasional bug where this can get out of sync and be -1 what it should be; uncomment the console.log to fault find
export default function apiStatusReducer(
  state = initialState.apiCallsInProgress,
  action
) {

  if (action.type == types.BEGIN_API_CALL) {
    return state + 1;
  } else if (actionTypeEndsInSuccess(action.type)) {
    return state - 1;
  } else if (actionTypeEndsInFailure(action.type)) {
    return state - 1;
  }
  return state;
}
