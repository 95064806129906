/* eslint-disable no-alert */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
export default function withOAuth(Comp) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(props) {
            var _this = _super.call(this, props) || this;
            _this.signIn = _this.signIn.bind(_this);
            return _this;
        }
        class_1.prototype.signIn = async function (_e, provider) {
            const { Auth } = await import("aws-amplify");
            Auth.federatedSignIn({ provider: provider });
        };
        const WithOAuth = function () {
            return React.createElement(Comp, __assign({}, this.props, { OAuthSignIn: this.signIn }));
        };
        class_1.prototype.render = WithOAuth;
        return class_1;
    }(React.Component));
}