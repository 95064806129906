import React from 'react';
import { useLocation } from 'react-router-dom';

import themedImpactSVGs from '../../../images/impact/ThemedSVGs';
import './impact-metric.css';

const Impact = ({ itemKey, impact, className, icon, label }) => {
  const definedData = impact?.[itemKey];

  return (
    <div className={className}>
      <div className='impact-data'>
        {icon}
        <p className='impact-value m-0'>
          {definedData?.value && Math.round(definedData?.value)?.toLocaleString() || 0}{' '}
          {definedData?.unit?.toLowerCase()}
        </p>
      </div>
      <p className='impact-label m-0'>{label}</p>
    </div>
  );
};

const ImpactMetric = ({ impact, style, isMoneyProject }) => {
  const location = useLocation();
  const isProjectsPage = location.pathname.includes('/projects');

  return (
    <div className='impact-metric' style={{ ...style }}>
      <Impact
        itemKey={'numberOfContributions'}
        impact={impact}
        className='secondary no-translate'
        icon={themedImpactSVGs.smile(`var(--cc-pink)`, { width: 48, height: 26 }, '')}
        label={`${isProjectsPage ? 'Project' : 'Total'} contributions`}
      />

      {!isMoneyProject && <Impact
        itemKey={'carbonOffsetImpact'}
        impact={impact}
        className='primary'
        icon={themedImpactSVGs.co2(`var(--cc-dark-green)`, {width: 66, height: 32}, '')}
        label='Amount of carbon mitigated'
      />}
    </div>
  );
};

export default ImpactMetric;
