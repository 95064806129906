import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function projectInventorySalesReducer(state = initialState.projectInventorySales, action) {
    switch (action.type) {
        case types.GET_PROJECT_INVENTORY_SALES_SUCCESS:
            return action.projectInventorySales;
        case types.GET_PROJECT_INVENTORY_SALES_FAILED:
            return action.error;
        case types.RESET_PROJECT_INVENTORY_SALES_FAILED:
            return initialState.projectInventorySales;
        default:
            return state;
    }
}