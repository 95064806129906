/*
These are mostly pulled from the .gitlab-ci.yml or other GitLab environment variables. 
WebPack will replace these at build time.
In local build, the defaults and .env files will be used.
*/

module.exports = {
  port: process.env.PORT || 4001,
  stripe_api_key: process.env.STRIPE_CLIENT_ID,
  google_api_key: process.env.GOOGLE_API_KEY,
  node_environment: process.env.NODE_ENV,
  semver: "1.0." + process.env.CI_PIPELINE_ID || "0.0.0", // Auto Env var coming from GitLab
  deployment_env: process.env.CI_ENVIRONMENT_NAME || "local",
  sentry_dsn: process.env.SENTRY_DSN,
  mobile_device_width: "(max-width: 800px)",
  mycc_base_url: process.env.MYCC_BASE_URL,
  merchant_code: process.env.X_MERCHANT_CODE || "CARBONCLICK",
  flightCalc_base_url: process.env.FLIGHT_CALCULATOR_BASE_URL,
  businessCalc_base_url: process.env.BUSINESS_CALCULATOR_BASE_URL,
  impact_api_base_url: process.env.BASE_IMPACT_URL,
  portal_url: process.env.PORTAL_URL,
  google_analytics_measurement_id: process.env.GOOGLE_ANALYTICS_MEASUREMENT_ID
};
