import React, {useState, useEffect, useContext} from "react";
import { Row } from "antd";
import SocialLoginView from "./SocialLoginView";
import * as userActions from "../../redux/actions/userActions";
import { withFederated } from "../security/oAuth/index";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import CCButton from "../common/CCButtonV3";
import OrView from './OrView';
import EmailDomainsHint from "./EmailDomainsHint";

import TermsAndConditions from "../common/TermsAndConditions";
import UIContext from "../../util/UIContext";

const SignInUpStart = ({ isClimateFriendlyTeamFlow, onNext, ...props }) => {
  let history = useHistory();

  const uiContext = useContext(UIContext);
  const emailDomains = uiContext?.emailDomains;
  const { hideSocialMedia } = uiContext?.content?.signIn?.individual;

  const [errorEmail, setEmailError] = useState(false);
  const [emailAddr, setEmailAddr] = useState("");
  const [isNextClicked, setOnNextClicked] = useState(false);

  useEffect(() => { if (!emailAddr) setEmailAddr(props.defaultEmail); }, [props.defaultEmail]);

  useEffect(() => {
    setEmailAddr(
      history.location &&
        history.location.state &&
        history.location.state.emailAddr
        ? history.location.state.emailAddr
        : (props.defaultEmail || "")
    );
  }, []);

  const domainValidation = (email, domains) => {
    const emailDomain = email?.slice(email.indexOf('@') + 1);

    if (domains.includes(emailDomain)) {
      return true;
    } else {
      return false;
    }
  }

  const validateEmail = (email) => {
    const emailRegex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;

    if (emailDomains?.length > 0) {
      if (domainValidation(email, emailDomains) && email.match(emailRegex)) {
        setEmailError(false);
        return true;
      }
    } else {
      if (email.match(emailRegex)) {
        setEmailError(false);
        return true;
      }
    }

    setEmailError(true);
    return false;
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    validateEmail(value);
    setEmailAddr(value);
  };

  const handleOnNextClick = () => { onNext(emailAddr); };

  const SignInButton = (
    <CCButton
      buttonName="Next"
      onClickButton={(e) => doNextClick(e)}
      isDisabled={errorEmail || !emailAddr }
      size="large"
      type="primary"
      loading={isNextClicked}
    />
  );

  const EmailValidation = errorEmail ? (
    <Row>
      <span style={{ color: "red", fontSize: 14 }}>Email is not valid</span>
    </Row>
  ) : null;

  const doNextClick = (e) => {
    e.preventDefault();
    if(!errorEmail && emailAddr) {
      props.actions.resetUser("");
      setOnNextClicked(true);
      if (validateEmail(emailAddr)) {
        handleOnNextClick();
      }
    }
  };

  return (
    <>
      <div className="signFormWrapper">
        <form onSubmit={doNextClick}>
          <>
            {!hideSocialMedia && <><Row>
            <SocialLoginView />
          </Row>
          <Row gutter={8} style={{ paddingTop: 20 }}>
            <OrView />
          </Row></>
        }
          <EmailDomainsHint emailDomains={emailDomains} />
        </>
          <Row style={{ paddingTop: hideSocialMedia ? 0 : 20 }}>
            <div className="inputWrapper text-left">
              <input
                value={emailAddr}
                type='email'
                size="large"
                name="email"
                onChange={handleEmailChange}
                placeholder="Enter email address"
                style={{
                  ...INPUT_STYLE
                }}
              />
            </div>
          </Row>
          {EmailValidation}
          <Row style={{ paddingTop: 20 }}>
            <TermsAndConditions />
          </Row>
          <div style={{ width: '100%', paddingTop: 30 }}>
            <div style={{ float: "right" }}>{SignInButton}</div>
            <br /> {/* this br clears float ^ */}
          </div>
        </form>
      </div>
    </>
  );
};

const INPUT_STYLE = {
  height: "auto",
  padding: 0,
  fontSize: "20px",
  lineHeight: "1.3em",
  width: "100%",
  color: "black",
  "::placeholder": {
    color: "#aab7c4"
  },
  border: 0,
  outline: 0
};

SignInUpStart.propTypes = {
  emailAddr: PropTypes.string,
  errorEmail: PropTypes.bool,
  handleEmailChange: PropTypes.func,
  handleOnNextClick: PropTypes.func,
  termsConditions: PropTypes.string,
  isMobileDevice: PropTypes.bool,
  loading: PropTypes.bool,
  actions: PropTypes.object,
  user:PropTypes.object,
  goToStep: PropTypes.func,
  nextStep: PropTypes.func,
  currentStep: PropTypes.string,
  defaultEmail: PropTypes.string,
  onNext: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  loading: !state.user
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      resetUser: bindActionCreators(
        userActions.resetUser,
        dispatch
      )
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFederated(SignInUpStart));
