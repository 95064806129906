import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function merchantsCountReducer(
  state = initialState.merchantsCount,
  action
) {
  switch (action.type) {
    case types.GET_MERCHANTS_COUNT:
      return initialState.merchantsCount;
    case types.GET_MERCHANTS_COUNT_FAILED:
      return action.error;
    case types.GET_MERCHANTS_COUNT_SUCCESS:
      return action.count;
    default:
      return state;
  }
}
