import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function projectsReducer(state =  initialState.projects , action) {
  switch (action.type) {
    case types.GET_PROJECTS_SUCCESS:
      return action.projects;
    case types.GET_PROJECTS_FAILED:
      return action.error;
    default:
      return state;
  }
}
