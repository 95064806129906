export default {
    code:'BUS_AE_EXPO_CITY_DUBAI',
    content: {
        transaction: {
            headerText: 'Track your impact'
        },
        logo: {
            link: 'https://www.expocitydubai.com/'
        },
        signIn: {
            individual: {
                backgroundImage: 'url("https://res.cloudinary.com/carbonclick/image/upload/v1695685283/White%20Label/ExpoCityDubai/Banner_1400x600_hy4u7v.png")',
            }
        },
    },
    theme: {
        images: {
            hero: {
                main: 'url(\'https://res.cloudinary.com/carbonclick/image/upload/v1695685283/White%20Label/ExpoCityDubai/Banner_1400x600_hy4u7v.png\')',
            },
        }
    }
}
