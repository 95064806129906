import auth_config from './auth_config';

const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

const oauth = {
  domain: auth_config.cognito_domain,
  redirectSignIn: auth_config.cognito_signInUrl,
  redirectSignOut: auth_config.cognito_signOutUrl,
  responseType: "token" // or 'token', note that REFRESH token will only be generated when the responseType is code
};

const checkSocialLoginWasUsed = () => ['access_token', 'token_type', 'expires_in'].every(e => window.location.hash.includes(e));

// reads authenticated user from local storage. Does not read guest user.
const getCurrentUser = () => {
  return getUserPool().getCurrentUser();
}

const getUserPool = (isGuest = false) => {
  return new AmazonCognitoIdentity.CognitoUserPool({
    UserPoolId: isGuest ? auth_config.cognito_guestUserPoolId : auth_config.cognito_userPoolId,
    ClientId: isGuest ? auth_config.cognito_guestUserPoolWebClientId : auth_config.cognito_userPoolWebClientId,
  });
}

const signOut = async () => {
  const currentUser = getCurrentUser();
  if (currentUser) currentUser.signOut();
}

const getCognitoUserForUsername = (username, isGuest = false) => {
  return new AmazonCognitoIdentity.CognitoUser({
    Username: username.toLowerCase(),
    Pool: getUserPool(isGuest)
  });
}

const signIn = (username, password) => {
  const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
    Username: username.toLowerCase(),
    Password: password
  });
  const cognitoUser = getCognitoUserForUsername(username);
  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: resolve,
      onFailure: reject
    });
  }) ;
}

const signInGuest = () => {
  const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
    Username: auth_config.cognito_guestUsername,
    Password: auth_config.cognito_guestPassword
  });
  const cognitoGuestUser = getCognitoUserForUsername(auth_config.cognito_guestUsername, true);
  return new Promise((resolve, reject) => {
    cognitoGuestUser.authenticateUser(authenticationDetails, {
      onSuccess: resolve,
      onFailure: reject
    });
  }) ;
}

const forgotPassword = (username) => {
  const cognitoUser = getCognitoUserForUsername(username);
  return new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: resolve,
      onFailure: reject
    })
  })
}

const forgotPasswordSubmit = (username, resetCode, newPassword) => {
  const cognitoUser = getCognitoUserForUsername(username);
  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(resetCode, newPassword, {
      onSuccess: resolve,
      onFailure: reject
    })
  })
}

// Back-compatibility to deprecated library. It works the same way as getUserPool(),
// but also handles oAuth (social logins), but also downloads a big library.
const getConfiguredAmplify = async () => {
  const Amplify = await import("aws-amplify")
  await Amplify.default.configure({ Auth: {
      region: auth_config.cognito_region,
      userPoolId: auth_config.cognito_userPoolId,
      userPoolWebClientId: auth_config.cognito_userPoolWebClientId,
      authenticationFlowType: "USER_SRP_AUTH",
      oauth: oauth
    }});
  await Amplify.Auth.configure({ oauth });
  return Amplify;
}


export {
  checkSocialLoginWasUsed,
  getUserPool,
  getCurrentUser,
  signOut,
  signIn,
  signInGuest,
  forgotPassword,
  forgotPasswordSubmit,
  getConfiguredAmplify
}
