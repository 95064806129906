import initialState from "./initialState";
import * as types from "../actions/actionTypes";

export default function flightQuoteReducer(state =  initialState.flightQuote , action) {
    switch (action.type) {
        case types.GET_FLIGHT_QUOTE_SUCCESS:
            return action.flightQuote;
        case types.GET_FLIGHT_QUOTE_FAILED:
            return action.error;
        case types.GET_FLIGHT_QUOTE_RESET:
            return initialState.flightQuote;
        default:
            return state;
    }
}