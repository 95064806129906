export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const BEGIN_GROUPS_API_CALL = "BEGIN_GROUPS_API_CALL";

export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const PAYMENT_FAILED = "PAYMENT_FAILED";
export const RESET_PAYMENT = "RESET_PAYMENT";

export const UPDATE_PAYMENT_EMAIL_SUCCESS = "UPDATE_PAYMENT_EMAIL_SUCCESS";
export const UPDATE_PAYMENT_EMAIL_FAILED = "UPDATE_PAYMENT_EMAIL_FAILED";

export const GET_MERCHANT_IMPACT = "GET_MERCHANT_IMPACT";
export const MERCHANT_IMPACT_SUCCESS = "MERCHANT_IMPACT_SUCCESS";
export const MERCHANT_IMPACT_FAILED = "MERCHANT_IMPACT_FAILED";

export const IMPACT_ALL_SUCCESS = "IMPACT_ALL_SUCCESS";
export const IMPACT_ALL_FAILED = "IMPACT_ALL_FAILED";

export const IMPACT_FLIGHTS_SUCCESS = "IMPACT_FLIGHTS_SUCCESS";
export const IMPACT_FLIGHTS_FAILED = "IMPACT_FLIGHTS_FAILED";

export const IMPACT_BY_PROJECT_CODE_SUCCESS = "IMPACT_BY_PROJECT_CODE_SUCCESS";
export const IMPACT_BY_PROJECT_CODE_FAILED = "IMPACT_BY_PROJECT_CODE_FAILED";

export const GET_TRANSACTION_IMPACT = "GET_TRANSACTION_IMPACT";
export const TRANSACTION_IMPACT_SUCCESS = "TRANSACTION_IMPACT_SUCCESS";
export const TRANSACTION_IMPACT_FAILED = "TRANSACTION_IMPACT_FAILED";

export const GET_QUOTE = "GET_QUOTE";
export const QUOTE_SUCCESS = "QUOTE_SUCCESS";
export const QUOTE_FAILED = "QUOTE_FAILED";

export const GET_USER_IMPACT = "GET_USER_IMPACT";
export const USER_IMPACT_SUCCESS = "USER_IMPACT_SUCCESS";
export const USER_IMPACT_FAILED = "USER_IMPACT_FAILED";

export const GET_PLANS = "GET_PLANS";
export const GET_PLANS_SUCCESS = "GET_PLANS_SUCCESS";
export const GET_PLANS_FAILED = "GET_PLANS_FAILED";

export const GET_GROUP_MEMBERSHIPS = "GET_GROUP_MEMBERSHIPS";
export const GET_GROUP_MEMBERSHIPS_SUCCESS = "GET_GROUP_MEMBERSHIPS_SUCCESS";
export const GET_GROUP_MEMBERSHIPS_FAILED = "GET_GROUP_MEMBERSHIPS_FAILED";

export const GET_GROUP = "GET_GROUP";
export const GET_GROUP_SUCCESS = "GET_GROUP_SUCCESS";
export const GET_GROUP_FAILED = "GET_GROUP_FAILED";

export const JOIN_GROUP = "JOIN_GROUP";
export const JOIN_GROUP_SUCCESS = "JOIN_GROUP_SUCCESS";
export const JOIN_GROUP_FAILED = "JOIN_GROUP_FAILED";

export const CREATE_GROUP = "CREATE_GROUP";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_FAILED = "CREATE_GROUP_FAILED";

export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_FAILED = "GET_CUSTOMER_FAILED";

export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILED = "GET_USER_FAILED";

export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_FAILED = "GET_CUSTOMERS_FAILED";

export const GET_CUSTOMER_USER = "GET_CUSTOMER_USER";
export const GET_CUSTOMER_USER_SUCCESS = "GET_CUSTOMER_USER_SUCCESS";
export const GET_CUSTOMER_USER_FAILED = "GET_CUSTOMER_USER_FAILED";
export const RESET_USER = "RESET_USER";

export const GET_SUBS = "GET_SUBS";
export const GET_SUBS_SUCCESS = "GET_SUBS_SUCCESS";
export const GET_SUBS_FAILED = "GET_SUBS_FAILED";

export const CREATE_SUBS = "CREATE_SUBS";
export const CREATE_SUBS_SUCCESS = "CREATE_SUBS_SUCCESS";
export const CREATE_SUBS_FAILED = "CREATE_SUBS_FAILED";

export const DELETE_SUBS = "DELETE_SUBS";
export const DELETE_SUBS_SUCCESS = "DELETE_SUBS_SUCCESS";
export const DELETE_SUBS_FAILED = "DELETE_SUBS_FAILED";

export const RESET_SUBS = "RESET_SUBS";

export const HAS_SUBS = "HAS_SUBS";
export const HAS_SUBS_SUCCESS = "HAS_SUBS_SUCCESS";
export const HAS_SUBS_FAILED = "HAS_SUBS_FAILED";

export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_FAILED = "GET_PROJECTS_FAILED";

export const GET_PERSONAL_FOOTPRINT = "GET_PERSONAL_FOOTPRINT";
export const GET_PERSONAL_FOOTPRINT_SUCCESS = "GET_PERSONAL_FOOTPRINT_SUCCESS";
export const GET_PERSONAL_FOOTPRINT_FAILED = "GET_PERSONAL_FOOTPRINT_FAILED";

export const GET_BASKET = "GET_BASKET";
export const GET_BASKET_SUCCESS = "GET_BASKET_SUCCESS";
export const GET_BASKET_FAILED = "GET_BASKET_FAILED";

export const GET_BASKETS = "GET_BASKETS";
export const GET_BASKETS_SUCCESS = "GET_BASKETS_SUCCESS";
export const GET_BASKETS_FAILED = "GET_BASKETS_FAILED";

export const GET_FLIGHTS = "GET_FLIGHTS";
export const GET_FLIGHTS_SUCCESS = "GET_FLIGHTS_SUCCESS";
export const GET_FLIGHTS_FAILED = "GET_FLIGHTS_FAILED";
export const GET_FLIGHTS_BY_MERCHANT_CODE_PAGE = "GET_FLIGHTS_BY_MERCHANT_CODE_PAGE";

export const GET_FLIGHTS_STATS = "GET_FLIGHTS_STATS";
export const GET_FLIGHTS_STATS_SUCCESS = "GET_FLIGHTS_STATS_SUCCESS";
export const GET_FLIGHTS_STATS_FAILED = "GET_FLIGHTS_STATS_FAILED";

export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS";
export const GET_PROJECT_FAILED = "GET_PROJECT_FAILED";

export const GET_PROJECT_INVENTORY_SUCCESS = "GET_PROJECT_INVENTORY_SUCCESS";
export const GET_PROJECT_INVENTORY_FAILED = "GET_PROJECT_INVENTORY_FAILED";

export const GET_PROJECT_INVENTORY_SALES_SUCCESS = "GET_PROJECT_INVENTORY_SALES_SUCCESS";
export const GET_PROJECT_INVENTORY_SALES_FAILED = "GET_PROJECT_INVENTORY_SALES_FAILED";
export const RESET_PROJECT_INVENTORY_SALES_FAILED = "RESET_PROJECT_INVENTORY_SALES_FAILED";

export const GET_GEO_LOCATION = "GET_GEO_LOCATION";
export const GEO_LOCATION_SUCCESS = "GEO_LOCATION_SUCCESS";
export const GEO_LOCATION_FAILED = "GEO_LOCATION_FAILED";

export const GET_MERCHANTS_PAGE = "GET_MERCHANTS_PAGE";
export const GET_MERCHANTS_PAGE_SUCCESS = "GET_MERCHANTS_PAGE_SUCCESS";
export const GET_MERCHANTS_PAGE_FAILED = "GET_MERCHANTS_PAGE_FAILED";

export const GET_MERCHANT_USER = "GET_MERCHANT_USER";
export const GET_MERCHANT_USER_SUCCESS = "GET_MERCHANT_USER_SUCCESS";
export const GET_MERCHANT_USER_FAILED = "GET_MERCHANT_USER_FAILED";

export const GET_MERCHANTS_COUNT = "GET_MERCHANTS_COUNT";
export const GET_MERCHANTS_COUNT_SUCCESS = "GET_MERCHANTS_COUNT_SUCCESS";
export const GET_MERCHANTS_COUNT_FAILED = "GET_MERCHANTS_COUNT_FAILED";

export const GET_MERCHANT_DETAILS_SUCCESS = "GET_MERCHANT_DETAILS_SUCCESS";
export const GET_MERCHANT_DETAILS_FAILED = "GET_MERCHANT_DETAILS_FAILED";

export const GET_PURCHASES_BY_USER = "GET_PURCHASES_BY_USER";
export const GET_PURCHASES_BY_USER_SUCCESS = "PURCHASES_BY_USER_SUCCESS";
export const GET_PURCHASES_BY_USER_FAILED = "PURCHASES_BY_USER_FAILED";

export const GET_GROUP_PURCHASES_BY_MERCHANT_CODE = "GET_GROUP_PURCHASES_BY_MERCHANT_CODE";
export const GET_GROUP_PURCHASES_BY_MERCHANT_CODE_SUCCESS = "GET_GROUP_PURCHASES_BY_MERCHANT_CODE_SUCCESS";
export const GET_GROUP_PURCHASES_BY_MERCHANT_CODE_FAILED = "GET_GROUP_PURCHASES_BY_MERCHANT_CODE_FAILED";

export const GET_PURCHASE_BY_ID = "GET_PURCHASE_BY_ID";
export const GET_PURCHASE_BY_ID_SUCCESS = "PURCHASE_BY_ID_SUCCESS";
export const GET_PURCHASE_BY_ID_FAILED = "PURCHASE_BY_ID_FAILED";

export const GET_AUTHENTICATED_USER = "GET_AUTHENTICATED_USER";
export const AUTHENTICATED_USER_SUCCESS = "AUTHENTICATED_USER_SUCCESS";
export const AUTHENTICATED_USER_FAILED = "AUTHENTICATED_USER_FAILED";
export const AUTHENTICATED_USER_RESET = "AUTHENTICATED_USER_RESET";
export const AUTHENTICATED_USER_PROFILE_PICTURE = "AUTHENTICATED_USER_PROFILE_PICTURE";

export const LOGIN_GUEST = "LOGIN_GUEST";
export const AUTHENTICATED_GUEST_SUCCESS = "AUTHENTICATED_GUEST_SUCCESS";
export const AUTHENTICATED_GUEST_RESET = "AUTHENTICATED_GUEST_RESET";
export const AUTHENTICATED_GUEST_FAILED = "AUTHENTICATED_GUEST_FAILED";

export const GET_GROUPS_IMPACT = "GET_GROUPS_IMPACT";
export const GROUP_IMPACT_SUCCESS = "GROUP_IMPACT_SUCCESS";
export const GROUP_IMPACT_FAILED = "GROUP_IMPACT_FAILED";

export const GET_GROUP_USERS_IMPACT = "GET_GROUP_USERS_IMPACT";
export const GROUP_USERS_IMPACT_SUCCESS = "GROUP_USERS_IMPACT_SUCCESS";
export const GROUP_USERS_IMPACT_FAILED = "GROUP_USERS_IMPACT_FAILED";

export const GET_GROUP_REDUCE_STATS = "GET_GROUP_REDUCE_STATS";
export const GROUP_REDUCE_STATS_SUCCESS = "GROUP_REDUCE_STATS_SUCCESS";
export const GROUP_REDUCE_STATS_FAILED = "GROUP_REDUCE_STATS_FAILED";

export const GET_GROUP_FOOTPRINTS_STATS = "GET_GROUP_FOOTPRINTS_STATS";
export const GROUP_FOOTPRINTS_STATS_SUCCESS = "GROUP_FOOTPRINTS_STATS_SUCCESS";
export const GROUP_FOOTPRINTS_STATS_FAILED = "GROUP_FOOTPRINTS_STATS_FAILED";

export const GET_FLIGHT_QUOTE = "GET_FLIGHT_QUOTE";
export const GET_FLIGHT_QUOTE_RESET = "GET_FLIGHT_QUOTE_RESET";
export const GET_FLIGHT_QUOTE_SUCCESS = "GET_FLIGHT_QUOTE_SUCCESS";
export const GET_FLIGHT_QUOTE_FAILED = "GET_FLIGHT_QUOTE_FAILED";

export const GET_ROAD_TRIP_IMPACT = "GET_ROAD_TRIP_IMPACT";
export const ROAD_TRIP_IMPACT_SUCCESS = "ROAD_TRIP_IMPACT_SUCCESS";
export const ROAD_TRIP_IMPACT_FAILED = "ROAD_TRIP_IMPACT_FAILED";

export const GET_REDUCE_FACTS = "GET_REDUCE_FACTS";
export const REDUCE_FACTS_SUCCESS = "REDUCE_FACTS_SUCCESS";
export const REDUCE_FACTS_FAILED = "REDUCE_FACTS_FAILED";

export const GET_REDUCE_ACTIONS = "GET_REDUCE_ACTIONS";
export const GET_REDUCE_ACTIONS_SUCCESS = "GET_REDUCE_ACTIONS_SUCCESS";
export const GET_REDUCE_ACTIONS_FAILED = "GET_REDUCE_ACTIONS_FAILED";

export const CREATE_REDUCE_ACTION_USER_ENGAGEMENT = "CREATE_REDUCE_ACTION_USER_ENGAGEMENT";
export const CREATE_REDUCE_ACTION_USER_ENGAGEMENT_SUCCESS = "CREATE_REDUCE_ACTION_USER_ENGAGEMENT_SUCCESS";
export const CREATE_REDUCE_ACTION_USER_ENGAGEMENT_FAILED = "CREATE_REDUCE_ACTION_USER_ENGAGEMENT_FAILED";

export const GET_CONTRIBUTIONS_PAGE = "GET_CONTRIBUTIONS_PAGE";
export const GET_CONTRIBUTIONS_PAGE_SUCCESS = "GET_CONTRIBUTIONS_PAGE_SUCCESS";
export const GET_CONTRIBUTIONS_PAGE_FAILED = "GET_CONTRIBUTIONS_PAGE_FAILED";

export const GET_TRANSACTIONS_BY_MERCHANT_CODE_PAGE = "GET_TRANSACTIONS_BY_MERCHANT_CODE_PAGE";
export const GET_TRANSACTIONS_BY_MERCHANT_CODE_PAGE_SUCCESS = "GET_TRANSACTIONS_BY_MERCHANT_CODE_PAGE_SUCCESS";
export const GET_TRANSACTIONS_BY_MERCHANT_CODE_PAGE_FAILED = "GET_TRANSACTIONS_BY_MERCHANT_CODE_PAGE_FAILED";