export default {
    code: 'BUS_SG_CHANGI_AIRPORT',
    content:{
        transaction: {
            headerText: 'Your flight offset',
            items: []
        },        
    },
    theme: {
        images: {
            hero: {
                main: 'url(\'https://res.cloudinary.com/carbonclick/image/upload/v1699232444/cms-prodeu/MERCHANT/BUS_SG_CHANGI_AIRPORT/Jewel_Facade_Pt2-03_y66hhr.jpg\')',
            },
        }
    }
}
